import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Icon } from 'react-icons-kit'
import { trashO, plus } from 'react-icons-kit/fa'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'
import { Accordion, Card, Button, Modal } from 'react-bootstrap'
import Memory from './Memory';
import API from '../Components/Global_API'
import NoInternet from '../Components/NoInternet';

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    }
}


export default class MemoryFunctionality extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            question_id: this.props.data.question_id,
            lesson_id: this.props.data.lesson_id,
            title: this.props.data.title,
            description: this.props.data.description,
            prompt: this.props.data.prompt,
            done_text: this.props.data.done_text,
            Loaderimg: '',
            time: this.props.data.time_limit,
            word: this.props.data.memory_data,
            course_image: this.props.course_image,
            done_button_state: true,
            done_button_name: this.props.data.prompt,
            leftStatementValidation: false,
            rightStatementValidation: false,
            changeSectionStatus: '',
            PublishedSectionPopup: false,
            prev_review: true,
            countdown: '3',
            score: ''
        };
        this.state.Loaderimg = require('../../assets/Loader.gif');
        this.Add = this.Add.bind(this);
        this.Remove = this.Remove.bind(this);
    }

    componentDidMount() {
        this.interval = setInterval(
            () => this.setState((prevState) => ({ countdown: prevState.countdown - 1 })),
            1000
        );
    }

    componentDidUpdate() {
        if (this.state.countdown === -1) {
            clearInterval(this.interval);
        };

    }


    Add() {
        let add = this.state.word;


        if (this.state.word.length < 4) {
            let newu = { left: 'product', right: 'product' }

            add.push(newu)
            this.setState({
                word: add,
            })
        }
    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }

    handleChangeTime = (e) => {
        this.setState({ time: e.target.value })
    }

    handleSectionStatusPopupOpen(item) {
        this.setState({
            PublishedSectionPopup: true,
            changeSectionStatus: item
        });
    }

    handlePublishedSectionPopup = () => {
        this.setState({ PublishedSectionPopup: false });
    }

    Remove = async () => {
        var i = this.state.changeSectionStatus;
        let words = this.state.word;
        if (this.state.word.length > 2) {
            words.splice(i, 1);
            await this.setState({ word: words, PublishedSectionPopup: false });
        }
    }

    handleChangeLeftContent = async (i, e) => {

        let value = e.target.value;
        let words = this.state.word;
        words.map((item, index) => {
            if (index === i) {
                item.left_content = value;

            }
        })
        await this.setState({ word: words });

    }

    handleChangeRightContent = async (i, e) => {

        let value = e.target.value;
        let words = this.state.word;
        words.map((item, index) => {
            if (index === i) {
                item.right_content = value;

            }
        })
        await this.setState({ word: words });

    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 250) {
            document.getElementById("title").focus();
        } else if (this.state.leftStatementValidation || this.state.rightStatementValidation) {

        } else if (this.state.time.trim().length < 2) {
            document.getElementById("timer").focus();
        } else if (this.state.prompt.trim().length < 2 || this.state.prompt.trim().length > 50) {
            document.getElementById("prompt").focus();
        } else if (this.state.done_text.trim().length < 2 || this.state.done_text.trim().length > 30) {
            document.getElementById("done_text").focus();
        } else {

            let memory = JSON.stringify(
                {
                    lesson_id: this.state.lesson_id,
                    title: this.state.title,
                    description: this.state.description,
                    prompt: this.state.prompt,
                    done_text: this.state.done_text,
                    memory_id: this.state.question_id,
                    memory_data: this.state.word,
                    time_limit: this.state.time
                });
            console.log("memory", memory);
            fetch(API + 'edit_memory.php',
                {
                    method: 'POST',

                    body: memory
                }).then((response) => response.json()).then((responseJsonFromServer) => {

                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        setTimeout(() => {
                            this.props.handleUpdateQuetionData();
                        }, 2000);
                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }


    }

    handleKeyDown = (e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    number_press = (event) => {
        if (!(event.ctrlKey || event.altKey
            || (47 < event.keyCode && event.keyCode < 58 && event.shiftKey === false)
            || (95 < event.keyCode && event.keyCode < 106)
            || (event.keyCode === 8) || (event.keyCode === 9)
            || (event.keyCode > 34 && event.keyCode < 40)
            || (event.keyCode === 46))) {

            event.preventDefault();

        }

    }


    titleValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length === 0) {
            document.getElementsByClassName('error_title')[0].innerHTML = "Title is required.";
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 2 to 250 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }

    leftStatementValidation = (i, e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length === 0) {
            document.getElementsByClassName('error_left_statement' + i)[0].innerHTML = "Left tile is required.";
            document.getElementsByClassName('error_left_statement' + i)[0].style.display = 'block';
            this.setState({ leftStatementValidation: true })
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_left_statement' + i)[0].innerHTML = 'Left tile must be between 2 to 30 character.';
            document.getElementsByClassName('error_left_statement' + i)[0].style.display = 'block';
            this.setState({ leftStatementValidation: true })
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_left_statement' + i)[0].innerHTML = "";
            document.getElementsByClassName('error_left_statement' + i)[0].style.display = 'none';
            this.setState({ leftStatementValidation: false })
        }
    }
    rightStatementValidation = (i, e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length === 0) {
            document.getElementsByClassName('error_right_statement' + i)[0].innerHTML = "Right tile is required.";
            document.getElementsByClassName('error_right_statement' + i)[0].style.display = 'block';
            this.setState({ rightStatementValidation: true })
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_right_statement' + i)[0].innerHTML = 'Right tile must be between 2 to 30 character.';
            document.getElementsByClassName('error_right_statement' + i)[0].style.display = 'block';
            this.setState({ rightStatementValidation: true })
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_right_statement' + i)[0].innerHTML = "";
            document.getElementsByClassName('error_right_statement' + i)[0].style.display = 'none';
            this.setState({ rightStatementValidation: false })
        }
    }

    timerValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        let targetValue = parseInt(value);
        let round_number = Math.round(targetValue);
        if (value.trim().length === 0) {
            document.getElementsByClassName('error_timer')[0].innerHTML = "Timer is required.";
            document.getElementsByClassName('error_timer')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (round_number === 0) {
            document.getElementsByClassName('error_timer')[0].innerHTML = "Please Enter valid time";
            document.getElementsByClassName('error_timer')[0].style.display = 'block';
            document.getElementById(targetId).focus();
            e.target.classList.add('error');
        } else if (value.trim().length < 5 || value.trim().length > 6) {
            document.getElementsByClassName('error_timer')[0].innerHTML = 'Timer must be between 5 to 6 digits.';
            document.getElementsByClassName('error_timer')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_timer')[0].innerHTML = "";
            document.getElementsByClassName('error_timer')[0].style.display = 'none';
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length === 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    done_textValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length === 0) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }



    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value });
    }

    // handleChangeDescription = (e) => {
    //     this.setState({ description: e.target.value });
    // }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleChangeDoneText = (e) => {
        this.setState({ done_text: e.target.value });
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handleDisableButton = (done_button_state) => {
        this.setState({ done_button_state: done_button_state, countdown: '3' })
    }

    handleCountDown = () => {
        this.setState({ done_button_state: true })
        this.interval = setInterval(
            () => this.setState((prevState) => ({ countdown: prevState.countdown - 1 })),
            1000
        )
    }
    handleScore = (score) => {
        this.setState({ score: score })
    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            if (!this.state.isFetching) {
                return (

                    <div className="row text-center">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="card card-sm">
                                <div className="card-content block-xl"  >
                                    <div className="body-container" style={styles.body}>
                                        <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }} >
                                            {this.state.done_button_state ? <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                                <div className="row text-center">
                                                    <div style={{ width: '10%', paddingLeft: 5 }}>
                                                        <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                                    </div>
                                                    <div style={{ paddingTop: 15, width: '60%' }}>
                                                        <hr color='#fff' />
                                                    </div>
                                                    <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                        <div style={styles.dot_btn}>
                                                            <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                            <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ height: 380, fontSize: 15, color: '#fff', overflowX: 'auto' }}>
                                                    <Memory time_limit={this.state.time} title={this.state.title} memory_array={this.state.word} nextQuestion={this.props.nextQuestion} current_index={this.props.current_index} handleDisableButton={this.handleDisableButton} handleScore={this.handleScore} />
                                                </div>
                                                <div style={{ height: 550, fontSize: 15, color: '#fff', position: 'absolute', top: -30, left: -30, margin: 30, zIndex: 15, width: '100%', visibility: this.state.countdown == '-1' ? "hidden" : "visible" }}>
                                                    <div style={{ height: 500, paddingTop: 35, display: 'flex', justifyContent: 'center', flexDirection: 'column', }}>
                                                        <div className="text-center" style={{ justifyContent: 'center', display: 'flex' }}>
                                                            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.48)', marginBottom: 30, color: '#fff', justifyContent: 'center', display: 'flex', flexDirection: 'column', borderWidth: 1, borderColor: '#fff', fontWeight: 'bold', height: 100, width: 100, padding: "10px 30px", fontSize: 50, borderRadius: 50 }}>
                                                                <h4>{this.state.countdown == '0' ? 'GO' : this.state.countdown}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <hr color='#fff' />
                                                    <div className="text-center">
                                                        <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                                                            {this.state.done_button_state ? this.state.prompt : this.state.done_text}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                                :
                                                <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                                    <div className="row text-center">
                                                        <div style={{ width: '10%', paddingLeft: 5 }}>
                                                            <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                                        </div>
                                                        <div style={{ paddingTop: 15, width: '60%' }}>
                                                            <hr color='#fff' />
                                                        </div>
                                                        <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                            <div style={styles.dot_btn}>
                                                                <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                                <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ height: 380, fontSize: 15, color: '#fff', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                                        <h5>The memory game</h5>
                                                        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.35)', padding: 5, margin: '10px 0', borderRadius: 3 }}>
                                                            <h6>YOUR SCORE</h6>
                                                            <h6>{this.state.score}</h6>
                                                        </div>
                                                        <button className="btn" onClick={() => { this.handleCountDown() }} style={{ color: '#000000', backgroundColor: '#fff', fontWeight: '600' }}>
                                                            Play again
                                                </button>
                                                    </div>

                                                    <div>
                                                        <hr color='#fff' />
                                                        <div className="text-center">
                                                            <button className="btn" onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} style={styles.done_btn}>
                                                                {this.state.done_text}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>}









                                            <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                                <div>
                                                    <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                        <div className="text-center">
                                                            <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                                Review last slide
                                                </button>
                                                        </div>
                                                        <div className="text-center">
                                                            <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                                Restart lesson
                                                </button>
                                                        </div>
                                                        {/* <div className="text-center">
                                                <button className="btn" style={styles.previewbutton}>
                                                    Exit lesson
                                                </button>
                                            </div> */}
                                                        <div className="text-center">
                                                            <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                                Cancel
                                                </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                                    <div className="col-4">
                                        <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                                    </div>
                                    <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                        <span >Slide {this.props.current_index + 1}</span>
                                    </div>
                                    <div className="col-4">
                                        <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <div className="card-sm" >
                                <form onSubmit={this.handleSubmit}>
                                    <div className="card-content block-xl" style={{ height: 550, overflowY: 'auto', overflowX: 'hidden' }}>
                                        <h5 className="text-left" style={{ paddingBottom: 10 }}>Memory</h5>

                                        <div className="text-left">
                                            <div className="form-group">
                                                <div className="input-group-prepend" style={styles.textbox}>
                                                    <span className="input-group-text" style={styles.text} >Title*</span>
                                                </div>
                                                <input type="text" className="form-control" id="title" value={this.state.title} minLength={2} maxLength={250} onChange={this.handleChangeTitle} onBlur={this.titleValidation} />
                                                <label className="input-error error_title"></label>
                                            </div>
                                            {/* <div className="form-group">
                                            <div className="input-group-prepend" style={styles.textbox}>
                                                <text className="input-group-text" style={styles.text}>Description</text>
                                            </div>
                                            <textarea data-default="" value={this.state.description} onKeyDown={this.handleKeyDown} minLength={2} maxLength={100} onChange={this.handleChangeDescription} rows="1" className="form-control" ></textarea>
                                        </div> */}
                                            <div className="form-group">
                                                <Accordion style={{ textAlign: 'left' }} defaultActiveKey="0">
                                                    <div style={{ marginTop: -5 }}>
                                                        <div as={Card.Body} eventkey="0">
                                                            <h5>Tiles</h5>
                                                        </div>
                                                        <Accordion.Collapse eventKey="0">
                                                            <div>
                                                                {this.state.word.map((item, i) => (
                                                                    <div key={i} style={{ paddingBottom: 10, marginTop: 5 }}>
                                                                        <div className="form-group">
                                                                            <div className="input-group-prepend" style={styles.textbox}>
                                                                                <span className="input-group-text" style={styles.text} >Left*</span>
                                                                            </div>
                                                                            <input type="text" className="form-control" id={"left_statement" + i} minLength={2} maxLength={30} value={item.left_content} onChange={this.handleChangeLeftContent.bind(this, i)} onBlur={this.leftStatementValidation.bind(this, i)} />
                                                                            <label className={"input-error error_left_statement" + i}></label>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <div className="input-group-prepend" style={styles.textbox}>
                                                                                <span className="input-group-text" style={styles.text} >Right*</span>
                                                                            </div>
                                                                            <input type="text" className="form-control" id={"right_statement" + i} value={item.right_content} minLength={2} maxLength={30} onChange={this.handleChangeRightContent.bind(this, i)} onBlur={this.rightStatementValidation.bind(this, i)} />
                                                                            <label className={"input-error error_right_statement" + i}></label>
                                                                        </div>
                                                                        <div >
                                                                            <Button variant="default" disabled={this.state.word.length > 2 ? false : true} style={{ backgroundColor: '#E6EBF0', cursor: this.state.word.length > 2 ? "pointer" : "no-drop" }} block onClick={this.handleSectionStatusPopupOpen.bind(this, i)}>
                                                                                <Icon size={20} icon={trashO} style={{ paddingRight: 10 }} />
                                                                            Remove this section
                                                                        </Button>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                                <Modal size="sm" show={this.state.PublishedSectionPopup} onHide={this.handlePublishedSectionPopup}>
                                                                    <Modal.Body>Are you sure you want to remove this content?</Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="secondary" onClick={this.handlePublishedSectionPopup}>No</Button>
                                                                        <Button style={styles.confirmButton} onClick={() => this.Remove()}>Yes</Button>
                                                                    </Modal.Footer>
                                                                </Modal>

                                                                <div style={{ marginTop: 10, color: '#fff' }}>
                                                                    <Button variant="default" disabled={this.state.word.length < 4 ? false : true} onClick={this.Add.bind(this)} style={{ backgroundColor: '#223042', color: '#fff', cursor: this.state.word.length < 4 ? "pointer" : "no-drop" }} block>
                                                                        <Icon size={20} icon={plus} style={{ paddingRight: 10, color: '#fff' }} />
                                                                    Add a section
                                                                </Button>
                                                                </div>
                                                            </div>
                                                        </Accordion.Collapse>
                                                    </div>
                                                </Accordion>
                                            </div>

                                            <div className="form-group">
                                                <Accordion style={{ textAlign: 'left' }} defaultActiveKey="1">
                                                    <div style={{ marginTop: -5 }}>
                                                        <div as={Card.Body} eventkey="1">
                                                            <h5>Timer</h5>
                                                        </div>
                                                        <Accordion.Collapse eventKey="1">
                                                            <div>
                                                                <div style={{ paddingBottom: 10, marginTop: 5 }}>
                                                                    <div className="form-group">
                                                                        <div className="input-group-prepend" style={styles.textbox}>
                                                                            <span className="input-group-text" style={styles.text} >Time Limit (ms)*</span>
                                                                        </div>
                                                                        <input type="text" className="form-control" id="timer" minLength={5} maxLength={6} value={this.state.time} onChange={this.handleChangeTime} onBlur={this.timerValidation} onKeyDown={this.number_press} />
                                                                        <label className="input-error error_timer"></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Collapse>
                                                    </div>
                                                </Accordion>
                                            </div>

                                            <div className="form-group">
                                                <div className="input-group-prepend" style={styles.textbox}>
                                                    <span className="input-group-text" style={styles.text}>Prompt*</span>
                                                </div>
                                                <input type="text" value={this.state.prompt} id="prompt" minLength={2} maxLength={50} onChange={this.handleChangePrompt} className="form-control" onBlur={this.promptValidation} />
                                                <label className="input-error error_prompt"></label>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group-prepend" style={styles.textbox}>
                                                    <span className="input-group-text" style={styles.text}>Done text*</span>
                                                </div>
                                                <input type="text" value={this.state.done_text} id="done_text" minLength={2} maxLength={30} onChange={this.handleChangeDoneText} className="form-control" onBlur={this.done_textValidation} />
                                                <label className="input-error error_done_text"></label>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        <button className="btn btn-dark" style={{ width: 150 }}>Save</button>
                                        <div id="snackbar"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                )
            } else {
                return (
                    <p>{this.state.isFetching ? this.state.Loaderimg : ''}</p>
                )
            }
        }
    }
}