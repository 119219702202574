import React from 'react'
import { Icon } from 'react-icons-kit'
import { smallDown, smallRight, smallLeft } from 'react-icons-kit/entypo'
import { pencil } from 'react-icons-kit/fa/pencil'
import { Accordion, Card, Button, Media } from 'react-bootstrap'

export default class Start_lesson extends React.Component {
    constructor() {
        super();
        this.state = {
            content: 'scrollable'
        }
    }


    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    render() {
        return (

            <div>
                <div style={{ height: 340, display: 'flex', flexDirection: 'column',  justifyContent:'center', alignItems:'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column',  justifyContent:'center', alignItems:'center', overflowY: 'auto' }}>
                        <h5 className="text-center" style={{ color: "#fff", overflowWrap: 'anywhere' }}>{this.props.data.title}</h5>
                        <p className="text-center" style={{ color: "#fff", overflowWrap: 'anywhere' }}>{this.props.data.sub_title}</p>
                        <div className="text-center">
                            <button className="btn" onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={styles.done_btn}>
                                {this.props.data.button_text}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <hr color='#fff' />
                </div>
            </div>
        )
    }
}

const styles = {
    done_btn: {
        backgroundColor: '#fff',
        marginBottom: 30,
        color: '#000000',
        fontWeight: 'bold'
    },

}