import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Icon } from 'react-icons-kit'
import { trashO, plus } from 'react-icons-kit/fa'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'
import { Button } from 'react-bootstrap'
import API from '../Components/Global_API'
import NoInternet from '../Components/NoInternet';


const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: '#fff',
        marginBottom: 30,
        color: '#000000',
        fontWeight: 'bold'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    }
}

export default class Exit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            title: this.props.data.title,
            content: this.props.data.sub_title,
            done_text: this.props.data.button_text,
            delete_flag: this.props.data.delete_flag,
            lesson_id: this.props.data.lesson_id,
            question_id: this.props.data.question_id,
            question_type: this.props.data.question_type,
            course_image: this.props.course_image,
            prev_review: true
        };

    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 250) {
            document.getElementById("title").focus();
        } else if (this.state.done_text.trim().length < 2 || this.state.done_text.trim().length > 30) {
            document.getElementById("done_text").focus();
        } else {
            let Exit = JSON.stringify({
                lesson_id: this.state.lesson_id,
                title: this.state.title,
                sub_title: this.state.content,
                button_text: this.state.done_text,
                exit_id: this.state.question_id
            });

            fetch(API + 'edit_exit_lesson.php',
                {
                    method: 'POST',

                    body: Exit
                }).then((response) => response.json()).then((responseJsonFromServer) => {

                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        setTimeout(() => {
                            this.props.handleUpdateQuetionData();
                        }, 2000);
                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }
    }

    titleValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_title')[0].innerHTML = "Title is required.";
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 2 to 250 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }

    contentValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;

        if (value.trim().length > 250) {
            document.getElementsByClassName('error_content')[0].innerHTML = 'Content must be between 0 to 250 character.';
            document.getElementsByClassName('error_content')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_content')[0].innerHTML = "";
            document.getElementsByClassName('error_content')[0].style.display = 'none';
        }
    }

    done_textValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }

    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value });
    }

    handleChangeContent = (e) => {
        this.setState({ content: e.target.value });
    }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleChangeDoneText = (e) => {
        this.setState({ done_text: e.target.value });
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            if (!this.state.isFetching) {
                return (

                    <div className="row text-center">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="card card-sm">
                                <div className="card-content block-xl"  >
                                    <div className="body-container" style={styles.body}>
                                        <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }} >
                                            <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                                <div className="row text-center">
                                                    <div style={{ width: '10%', paddingLeft: 5 }}>
                                                        <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                                    </div>
                                                    <div style={{ paddingTop: 15, width: '60%' }}>
                                                        {/* <text style={{color: '#fff',fontSize:20}}>Scrollable</text> */}
                                                        <hr color='#fff' />
                                                    </div>
                                                    <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                        <div style={styles.dot_btn}>
                                                            <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                            <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ height: 380, fontSize: 15, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: '#fff', overflowY: 'auto' }}>
                                                    <h5 style={{ color: '#fff', paddingBottom: 20, overflowWrap: 'anywhere' }}>{this.state.title}</h5>
                                                    <p className="text-center" style={{ overflowWrap: 'anywhere' }} >
                                                        {this.state.content}
                                                    </p>
                                                    <div className="text-center">
                                                        <button className="btn" style={styles.done_btn}>
                                                            {this.state.done_text}
                                                        </button>
                                                    </div>
                                                </div>

                                                <div>
                                                    <hr color="#fff" />
                                                </div>
                                            </div>
                                            <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                                <div>
                                                    <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                        <div className="text-center">
                                                            <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                                Review last slide
                                                </button>
                                                        </div>
                                                        <div className="text-center">
                                                            <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                                Restart lesson
                                                </button>
                                                        </div>
                                                        {/* <div className="text-center">
                                                <button className="btn" style={styles.previewbutton}>
                                                    Exit lesson
                                                </button>
                                            </div> */}
                                                        <div className="text-center">
                                                            <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                                Cancel
                                                </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                                    <div className="col-4">
                                        <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                                    </div>
                                    <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                        <span >Slide {this.props.current_index + 1}</span>
                                    </div>
                                    <div className="col-4">
                                        <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <div className="card-sm" >
                                <form onSubmit={this.handleSubmit}>
                                    <div className="card-content block-xl" style={{ height: 550, }}>
                                        <h5 className="text-left" style={{ paddingBottom: 10 }}>Exit Lesson</h5>

                                        <div className="text-left">
                                            <div className="form-group">
                                                <div className="input-group-prepend" style={styles.textbox}>
                                                    <span className="input-group-text" style={styles.text}>Title*</span>
                                                </div>
                                                <input type="text" className="form-control" id="title" value={this.state.title} minLength={2} maxLength={250} onChange={this.handleChangeTitle} onBlur={this.titleValidation} />
                                                <label className="input-error error_title"></label>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group-prepend" style={styles.textbox}>
                                                    <span className="input-group-text" style={styles.text}>Content</span>
                                                </div>
                                                <input type="text" id="content" value={this.state.content} maxLength={250} onChange={this.handleChangeContent} className="form-control" onBlur={this.contentValidation} />
                                                <label className="input-error error_content"></label>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group-prepend" style={styles.textbox}>
                                                    <span className="input-group-text" style={styles.text}>Done text*</span>
                                                </div>
                                                <input type="text" value={this.state.done_text} id="done_text" minLength={2} maxLength={30} onChange={this.handleChangeDoneText} className="form-control" onBlur={this.done_textValidation} />
                                                <label className="input-error error_done_text"></label>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        <button className="btn btn-dark" style={{ width: 150 }}>Save</button>
                                        <div id="snackbar"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>{this.state.isFetching ? <div style={{ display: 'flex', justifyContent: 'center' }}><img src={require('../../assets/Loader.gif')} /></div> : ''}</div>
                )
            }
        }
    }
}