import React from 'react'
import './Memory.css'


export default class Memory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frameworks: [],
      randomizedFrameworks: [],
      finalizedFrameworks: [],
      openedFrameworks: [],
      Timer: Math.floor(parseInt(this.props.time_limit) / 1000),
      title: this.props.title,
      score: 0
    }

    this.start()
  }
  handleClick(name, index) {
    if (this.state.Timer > 0) {
      if (this.state.openedFrameworks.length == 2) {
        setTimeout(() => {
          this.check()
        }, 750)
      } else {
        let framework = {
          name,
          index
        }
        let finalizedFrameworks = this.state.finalizedFrameworks
        console.log("finalizedFrameworks", finalizedFrameworks);
        let frameworks = this.state.openedFrameworks
        finalizedFrameworks[index].close = false
        frameworks.push(framework)
        this.setState({
          openedFrameworks: frameworks,
          finalizedFrameworks: finalizedFrameworks
        })
        if (this.state.openedFrameworks.length == 2) {
          setTimeout(() => {
            this.check()
          }, 750)
        }
      }
    }
  }
  check() {
    let finalizedFrameworks = this.state.finalizedFrameworks;
    if (this.state.openedFrameworks[0] && this.state.openedFrameworks[1]) {
      if ((this.state.openedFrameworks[0].name == this.state.openedFrameworks[1].name) && (this.state.openedFrameworks[0].index != this.state.openedFrameworks[1].index)) {
        finalizedFrameworks[this.state.openedFrameworks[0].index].complete = true
        finalizedFrameworks[this.state.openedFrameworks[1].index].complete = true
        this.setState({ score: this.state.score + 1000 })
      } else {
        finalizedFrameworks[this.state.openedFrameworks[0].index].close = true
        finalizedFrameworks[this.state.openedFrameworks[1].index].close = true
      }

      const result = finalizedFrameworks.filter(data_result => data_result.complete !== true);
      if (result.length == 0) {
        this.props.handleDisableButton(false)
        this.props.handleScore(this.state.score)
      }

      this.setState({
        finalizedFrameworks,
        openedFrameworks: []
      })
    }

  }
  start() {
    let finalizedFrameworks = [];
    let data = [];
    // this.state.duplicatedFrameworks = this.state.frameworks.concat(this.state.frameworks)
    this.props.memory_array.map((m) => {
      data.push({ title: m.left_content, name: m.left_content });
      data.push({ title: m.right_content, name: m.left_content });
    })

    this.state.frameworks = data;

    this.state.randomizedFrameworks = this.shuffle(this.state.frameworks)

    this.state.randomizedFrameworks.map((memdat, index) => {

      finalizedFrameworks.push({
        name: memdat.name,
        title: memdat.title,
        index: index,
        close: true,
        complete: false,
        fail: false
      })
    })
    this.state.finalizedFrameworks = finalizedFrameworks

    // this.setState({finalizedFrameworks})
  }
  shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array

  }

  handleDoneButton = (current_index) => {
    this.props.nextQuestion(current_index)
  }

  componentDidMount() {

    setTimeout(() => {
      this.interval = setInterval(
        () => this.setState((prevState) => ({ Timer: prevState.Timer - 1 })),
        1000
      );
    }, 4000);

  }
  componentDidUpdate() {
    if (this.state.Timer === 0) {
      clearInterval(this.interval);
      this.props.handleDisableButton(false)
      this.props.handleScore(this.state.score)
    };

  }

  render() {

    return (
      <div>
        <div style={{ flexDirection: 'row', display: 'flex', padding: '0px 35px', justifyContent: 'space-between' }}>
          <div>
            <p style={{ textAlign: 'center' }}>Timer: &nbsp;{this.state.Timer}</p>
          </div>

          <div>
            <p style={{ textAlign: 'center' }}>Score: &nbsp;{this.state.score}</p>
          </div>
        </div>
        <div style={{padding: '0px 35px'}}>
          <h6 style={{ overflowWrap: 'anywhere', padding: '0px 5px' }}>{this.state.title}</h6>
        </div>
        <div className="playground">

          {
            this.state.finalizedFrameworks.map((framework, index, name) => {
              return <Card framework={framework.title} click={() => { this.handleClick(framework.name, index) }} close={framework.close} complete={framework.complete} />
            })
          }
        </div>
      </div>
    )
  }
}

class Card extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  clicked(framework) {
    this.props.click(framework)
  }
  render() {
    return (


      <div className={"card" + (!this.props.close ? ' opened' : '') + (this.props.complete ? ' matched' : '')} onClick={() => this.clicked(this.props.framework)}>
        <div className="front">
          ?
        </div>
        <div className="back">
          <p style={{ color: '#000000', lineHeight: '15px' }}>{this.props.framework}</p>
        </div>
      </div>


    )
  }
}

