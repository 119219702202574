import React from 'react'
import { Icon } from 'react-icons-kit'
import { smallDown, smallRight, smallLeft } from 'react-icons-kit/entypo'
import { pencil } from 'react-icons-kit/fa/pencil'
import { Accordion, Card, Button, Media } from 'react-bootstrap'

export default class Multiple_choice_image_detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            done_button_name: this.props.data.prompt,
            done_text: this.props.data.done_text,
            title: this.props.data.title,
            done_button_state: true,
            answer_popup: true,
            selected_value: '',
            answer_reinforcement: ''
        }
    }

    DisableButtonFunction = (e) => {
        this.setState({
            done_button_state: false,
            done_button_name: this.state.done_text,
            selected_value: e.target.value
        })
    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    handleAnswerpopup = () => {
        this.setState({ answer_popup: false })

        let arr1 = this.props.data.multiple_choice_image_options
        let arr2 = this.state.selected_value

        arr1.filter(item=>{
            if(item.multiple_choice_image_id == arr2){
                if(item.answer == 'true'){
                    this.setState({ answer_reinforcement: this.props.data.correct_reinforcement })
                } else{
                    this.setState({ answer_reinforcement: this.props.data.incorrect_reinforcement })
                }
            }
        })
    }


    render() {
        return (
            <div>
                <div style={{ height: 340, overflowY: 'auto' }}>
                    <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 30 }}>
                        <h5 className="text-center" style={{ color: '#fff', paddingBottom: 10, overflowWrap: 'anywhere' }}> {this.state.title}</h5>
                        <div className="d-flex flex-wrap">
                            {this.props.data.multiple_choice_image_options.map((item, i) => (
                                <div style={styles.radio} className="text-left" >
                                    <label style={{ width: '100%', height: 120 }}>
                                        <img src={item.options} style={{ maxHeight: '90%', maxWidth: '100%' }} />
                                        <div className="text-right">
                                            <input type="radio" name="option" onClick={this.DisableButtonFunction} value={item.multiple_choice_image_id} disabled={!this.state.answer_popup} />&nbsp;
                            </div>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <hr color='#fff' />
                    <div className="text-center">
                        {/* <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}> */}
                        <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleAnswerpopup() }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                            {this.state.done_button_name}
                        </button>
                    </div>
                    <div style={{ backgroundColor: '#fff', visibility: this.state.answer_popup ? "hidden" : "visible", marginTop: -200, position: 'relative', marginLeft: -15, marginRight: -16, padding: 15, borderTop: 'solid', borderTopColor: 'rgba(0,0,0,0.4)', borderTopWidth: 5 }}>
                        <div style={{ height: 85, overflowY: 'auto' }}>
                            <p><strong>{this.state.answer_reinforcement}</strong></p>
                            <div>
                                <p>{this.props.data.answer_text}</p>
                            </div>
                        </div>
                        <hr />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div className="col-6">
                                <Button style={styles.continue_button} onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} block>Continue</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    question_content: {
        backgroundColor: '#34baeb',
        height: 500,
        margin: 30
    },
    edit_btn: {
        backgroundColor: '#223042',
        color: '#fff',
        marginTop: 30
    },
    slider_card: {
        backgroundColor: '#fff',
        paddingBottom: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    radio: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        margin: 5,
        paddingLeft: 20,
        borderRadius: 5,
        fontSize: 15,
        paddingTop: 5,
        width: '45%',
        height: 140
    },
    continue_button: {
        borderWidth: 2,
        borderColor: 'rgba(0,0,0,0.2)',
        padding: 10,
        backgroundColor: '#fff',
        color: '#000000'
    }
}