import React from 'react'
import { Icon } from 'react-icons-kit'
import { edit } from 'react-icons-kit/ionicons/edit'
// import { pencil } from 'react-icons-kit/fa/pencil'
// import { Accordion, Card, Button, Media } from 'react-bootstrap'
// import API from '../Components/Global_API'

// const API = 'http://pta-academy.tk/admin/'
export default class Free_text_detail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.data.title,
        }
    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    handleFreeText = (e) => {
        this.setState({ free_text: e.target.value })
    }

    handleKeyDown = (e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    render() {
        return (
            <div>
                <div style={{ height: 340, display: 'flex', flexDirection: 'column', justifyContent: 'center', overflowY: 'auto' }}>
                    <h5 className="text-center" style={{ color: '#fff', paddingBottom: 10, overflowWrap: 'anywhere' }}>{this.state.title}</h5>
                    <textarea onChange={this.handleFreeText} onKeyDown={this.handleKeyDown} style={{ borderRadius: 3, minHeight: 150, backgroundColor: '#ffffff7a', color: '#fff' }} value={this.state.free_text}></textarea>
                    <Icon icon={edit} style={{ position: 'relative', backgroundColor: 'rgba(0,0,0,0.20)', top: -45, width: 40, height: 40, padding: 10, alignSelf: 'flex-end', color: '#fff', borderRadius: 3, right: 5 }} size={20} />
                </div>
                <div>
                    <hr color='#fff' />
                    <div className="text-center">
                        <button className="btn" onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={styles.done_btn}>
                            {this.props.data.prompt}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    question_content: {
        backgroundColor: '#34baeb',
        height: 500,
        margin: 30
    },
    edit_btn: {
        backgroundColor: '#223042',
        color: '#fff',
        marginTop: 30
    },
    slider_card: {
        backgroundColor: '#fff',
        paddingBottom: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    }
}