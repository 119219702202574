import React from 'react';
import MultipleChoiceAssessment from './Assessment_functionality/Multiple_choice_assessment';
import Free_text_assessment from './Assessment_functionality/Free_text_assessment';
import TitleScreen_assessment from './Assessment_functionality/TitleScreen_assessnemt';
import ExitScreen_assessment from './Assessment_functionality/ExitScreen_assessment';
import QuestionPool_assessment from './Assessment_functionality/QuestionPool';
import Expandable_assessment from './Assessment_functionality/Expandable_assessment';

export default class Assessment_Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        } 
    } 

    render() {
        if(this.props.component_type == 'start_lesson'){
            return(<TitleScreen_assessment unit={this.props.unit} nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'free_text'){
            return(<Free_text_assessment unit={this.props.unit} nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'ass_multiple_choice'){
            return(<MultipleChoiceAssessment unit={this.props.unit} nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'exit_lesson'){
            return(<ExitScreen_assessment unit={this.props.unit} nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'ass_expandable'){
            return(<Expandable_assessment unit={this.props.unit} nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} />)
        } else if(this.props.component_type == 'question_pool'){
            return(<QuestionPool_assessment unit={this.props.unit} nextQuestion={this.props.nextQuestion} handleUpdateQuetionData={this.props.handleUpdateQuetionData} course_image={this.props.course_image} que_count={this.props.que_count} current_index={this.props.current_index} data={this.props.component_data} handleQuestionPoolData={this.props.handleQuestionPoolData} question_pool_current_index={this.props.question_pool_current_index} />)
        } else {
            return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><img src={require('../assets/Loader.gif')} /></div>)
        }
    }
}