import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Icon } from 'react-icons-kit'
import { trashO, plus } from 'react-icons-kit/fa'
import { Accordion, Card, Button, Modal } from 'react-bootstrap'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'
import Crossword from './Crossword';
import API from '../Components/Global_API'
import NoInternet from '../Components/NoInternet';

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    text: {
        fontWeight: 'bold',
        fontSize: 13
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    }
}

export default class CrosswordFunctionality extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            question_id: this.props.data.question_id,
            lesson_id: this.props.data.lesson_id,
            title: this.props.data.title,
            course_image: this.props.course_image,
            prompt: this.props.data.prompt,
            done_text: this.props.data.done_text,
            Loaderimg: '',
            crossword_data: this.props.data.crossword_data,
            correctAns: this.props.data.correct_reinforcement,
            incorrectAns: this.props.data.incorrect_reinforcement,
            word: this.props.data.crossword_data,
            time: this.props.data.time_limit,
            wordValidation: false,
            changeSectionStatus: '',
            PublishedSectionPopup: false,
            prev_review: true,
            done_button_state: true
        };

        this.Add = this.Add.bind(this);
        this.Remove = this.Remove.bind(this);
        this.state.Loaderimg = require('../../assets/Loader.gif');
    }

    words_press = (e) => {
        if ((e.keyCode == 32)) {
            e.preventDefault();
        }
    }

    number_press = (event) => {
        if (!(event.ctrlKey || event.altKey
            || (47 < event.keyCode && event.keyCode < 58 && event.shiftKey == false)
            || (95 < event.keyCode && event.keyCode < 106)
            || (event.keyCode == 8) || (event.keyCode == 9)
            || (event.keyCode > 34 && event.keyCode < 40)
            || (event.keyCode == 46))) {
            event.preventDefault();
        }
    }

    Add() {
        let add = this.state.word;
        if (this.state.word.length < 10) {
            let newu = { words: 'product' }

            add.push(newu)
            this.setState({
                word: add,
            })
        }
    }

    Remove = async (i, e) => {
        var i = this.state.changeSectionStatus;
        let words = this.state.word;
        if (this.state.word.length > 1) {
            words.splice(i, 1);
            await this.setState({ word: words, PublishedSectionPopup: false });
        }
    }

    handleDisableButton = (done_button_state) => {
        this.setState({ done_button_state: done_button_state })
    }

    handleSectionStatusPopupOpen(item) {
        this.setState({
            PublishedSectionPopup: true,
            changeSectionStatus: item
        });
    }

    handlePublishedSectionPopup = () => {
        this.setState({ PublishedSectionPopup: false });
    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }

    handleSubmit = (e) => {
        e.preventDefault();


        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 250) {
            document.getElementById("title").focus();
        }
        else if (this.state.wordValidation) {

        }
        else if (this.state.correctAns.trim().length < 2 || this.state.correctAns.trim().length > 50) {
            document.getElementById("correct_reinforcement").focus();
        } else if (this.state.incorrectAns.trim().length < 2 || this.state.incorrectAns.trim().length > 50) {
            document.getElementById("incorrect_reinforcement").focus();
        } else if (this.state.time.trim().length < 2) {
            document.getElementById("timer").focus();
        } else if (this.state.prompt.trim().length < 2 || this.state.prompt.trim().length > 50) {
            document.getElementById("prompt").focus();
        } else if (this.state.done_text.trim().length < 2 || this.state.done_text.trim().length > 50) {
            document.getElementById("done_text").focus();
        } else {

            let words_data = [];
            this.state.word.map(item => (
                words_data.push({ word: item.words })
            ))

            let Crossword = JSON.stringify(
                {
                    lesson_id: this.state.lesson_id,
                    crossword_data: words_data,
                    title: this.state.title,
                    prompt: this.state.prompt,
                    done_text: this.state.done_text,
                    crossword_id: this.state.question_id,
                    correct_reinforcement: this.state.correctAns,
                    incorrect_reinforcement: this.state.incorrectAns,
                    time_limit: this.state.time,
                });
            fetch(API + 'edit_crossword.php',
                {
                    method: 'POST',

                    body: Crossword
                }).then((response) => response.json()).then((responseJsonFromServer) => {

                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        setTimeout(() => {
                            this.props.handleUpdateQuetionData();
                        }, 2000);
                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }
    }

    titleValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_title')[0].innerHTML = "Title is required.";
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 2 to 250 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }

    wordValidation = (i, e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_word' + i)[0].innerHTML = "Word is required.";
            document.getElementsByClassName('error_word' + i)[0].style.display = 'block';
            this.setState({ wordValidation: true })
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 12) {
            document.getElementsByClassName('error_word' + i)[0].innerHTML = 'Word must be between 2 to 12 character.';
            document.getElementsByClassName('error_word' + i)[0].style.display = 'block';
            this.setState({ wordValidation: true })
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            document.getElementsByClassName('error_word' + i)[0].innerHTML = "";
            document.getElementsByClassName('error_word' + i)[0].style.display = 'none';
            this.setState({ wordValidation: false })
            e.target.classList.remove('error');
        }
    }

    timerValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        let targetValue = parseInt(value);
        let round_number = Math.round(targetValue);
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_timer')[0].innerHTML = "Timer is required.";
            document.getElementsByClassName('error_timer')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (round_number === 0) {
            document.getElementsByClassName('error_timer')[0].innerHTML = "Please Enter valid time";
            document.getElementsByClassName('error_timer')[0].style.display = 'block';
            document.getElementById(targetId).focus();
            e.target.classList.add('error');
        } else if (value.trim().length < 5 || value.trim().length > 6) {
            document.getElementsByClassName('error_timer')[0].innerHTML = 'Timer must be between 5 to 6 digits.';
            document.getElementsByClassName('error_timer')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_timer')[0].innerHTML = "";
            document.getElementsByClassName('error_timer')[0].style.display = 'none';
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    done_textValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }

    correct_reinforcementValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = "Correct reinforcement is required.";
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = 'Correct reinforcement must be between 2 to 50 character.';
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_correct_reinforcement')[0].innerHTML = "";
            document.getElementsByClassName('error_correct_reinforcement')[0].style.display = 'none';
        }
    }

    incorrect_reinforcementValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = "Incorrect reinforcement is required.";
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = 'Incorrect reinforcement must be between 2 to 50 character.';
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_incorrect_reinforcement')[0].innerHTML = "";
            document.getElementsByClassName('error_incorrect_reinforcement')[0].style.display = 'none';
        }
    }

    handleChangeCorrectAns = (e) => {
        this.setState({ correctAns: e.target.value });
    }

    handleChangeIncorrectAns = (e) => {
        this.setState({ incorrectAns: e.target.value });
    }

    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value });
    }

    handleChangeTime = (e) => {
        this.setState({ time: e.target.value })
    }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleChangeDoneText = (e) => {
        this.setState({ done_text: e.target.value });
    }

    handleChangeListContent = async (i, e) => {

        let value = e.target.value;
        let words = this.state.word;
        words.map((item, index) => {
            if (index == i) {
                item.words = value;

            }
        })
        await this.setState({ word: words });

    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            if (!this.state.isFetching) {
                return (

                    <div className="row text-center" >
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="card card-sm">
                                <div className="card-content block-xl"  >
                                    <div className="body-container" style={styles.body}>
                                        <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }} >
                                            <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                                <div className="row text-center">
                                                    <div style={{ width: '10%', paddingLeft: 5 }}>
                                                        <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                                    </div>
                                                    <div style={{ paddingTop: 15, width: '60%' }}>
                                                        {/* <text style={{color: '#fff',fontSize:20}}>Scrollable</text> */}
                                                        <hr color='#fff' />
                                                    </div>
                                                    <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                        <div style={styles.dot_btn}>
                                                            <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                            <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ height: 380, fontSize: 15, color: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', overflowY: 'auto' }}>
                                                    <Crossword title={this.state.title} crossword_data={this.state.crossword_data} handleDisableButton={this.handleDisableButton} />
                                                </div>

                                                <div>
                                                    <hr color='#fff' />
                                                    <div className="text-center">
                                                        <button className="btn" disabled={this.state.done_button_state} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                                                            {this.state.done_button_state ? this.state.prompt : this.state.done_text}
                                                            {/* Ok, I'm done */}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                                <div>
                                                    <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                        <div className="text-center">
                                                            <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                                Review last slide
                                                </button>
                                                        </div>
                                                        <div className="text-center">
                                                            <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                                Restart lesson
                                                </button>
                                                        </div>
                                                        {/* <div className="text-center">
                                                <button className="btn" style={styles.previewbutton}>
                                                    Exit lesson
                                                </button>
                                            </div> */}
                                                        <div className="text-center">
                                                            <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                                Cancel
                                                </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-12" style={{ paddingTop: 20, backgroundColor: '#fff', marginLeft: 0.1 }}>
                                    <div className="col-4">
                                        <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                                    </div>
                                    <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                        <span>Slide {this.props.current_index + 1}</span>
                                    </div>
                                    <div className="col-4">
                                        <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <div className="card-sm" >
                                <form onSubmit={this.handleSubmit}>
                                    <div className="card-content block-xl" style={{ height: 550, overflowY: 'auto', overflowX: 'hidden' }}>
                                        <h5 className="text-left" style={{ paddingBottom: 10 }}>Crossword</h5>

                                        <div className="text-left">
                                            <div className="form-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" style={styles.text} >Title*</span>
                                                </div>
                                                <input type="text" className="form-control" id="title" minLength={2} maxLength={250} value={this.state.title} onChange={this.handleChangeTitle} onBlur={this.titleValidation} />
                                                <label className="input-error error_title"></label>
                                            </div>
                                            <div className="form-group">
                                                <Accordion style={{ textAlign: 'left' }} defaultActiveKey="0">
                                                    <div style={{ marginTop: -5 }}>
                                                        <div as={Card.Body} eventkey="0">
                                                            <h5>Words*</h5>
                                                        </div>
                                                        <Accordion.Collapse eventKey="0">
                                                            <div>
                                                                {this.state.word.map((item, i) => (
                                                                    <div key={i} style={{ padding: 10, marginTop: 5, backgroundColor: '#fff', borderRadius: 3 }}>
                                                                        <div className="form-group">
                                                                            {/* <div className="input-group-prepend">
                                                                            <text className="input-group-text" style={styles.text} >Content</text>
                                                                        </div> */}
                                                                            <input type="text" className="form-control" id={"word" + i} minLength={2} maxLength={12} value={item.words} onChange={this.handleChangeListContent.bind(this, i)} onBlur={this.wordValidation.bind(this, i)} onKeyDown={this.words_press} />
                                                                            <label className={"input-error error_word" + i}></label>
                                                                        </div>
                                                                        <div >
                                                                            <Button variant="default" style={{ backgroundColor: '#E6EBF0', cursor: this.state.word.length == 1 ? 'no-drop' : 'pointer' }} disabled={this.state.word.length == 1} block onClick={this.handleSectionStatusPopupOpen.bind(this, i)}>
                                                                                <Icon size={20} icon={trashO} style={{ paddingRight: 10 }} />
                                                                            Remove this section
                                                                        </Button>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                                <Modal size="sm" show={this.state.PublishedSectionPopup} onHide={this.handlePublishedSectionPopup}>
                                                                    <Modal.Body>Are you sure you want to remove this it?</Modal.Body>
                                                                    <Modal.Footer>
                                                                        <Button variant="secondary" onClick={this.handlePublishedSectionPopup}>No</Button>
                                                                        <Button style={styles.confirmButton} onClick={() => this.Remove()}>Yes</Button>
                                                                    </Modal.Footer>
                                                                </Modal>

                                                                <div style={{ marginTop: 10, color: '#fff' }}>
                                                                    <Button variant="default" disabled={this.state.word.length == 10} onClick={this.Add.bind(this)} style={{ backgroundColor: '#223042', color: '#fff', cursor: this.state.word.length == 10 ? 'no-drop' : 'pointer' }} block>
                                                                        <Icon size={20} icon={plus} style={{ paddingRight: 10, color: '#fff' }} />
                                                                    Add a section
                                                                </Button>
                                                                </div>
                                                            </div>
                                                        </Accordion.Collapse>
                                                    </div>
                                                </Accordion>
                                            </div>

                                            <Accordion style={{ textAlign: 'left' }} defaultActiveKey="1">
                                                <div>
                                                    <div as={Card.Body} eventkey="1">
                                                        <h5>Answer</h5>
                                                    </div>
                                                    <Accordion.Collapse eventKey="1">
                                                        <div>
                                                            {/* <div className="form-group">
                                                            <div className="input-group-prepend">
                                                                <text className="input-group-text" >Answer Text</text>
                                                            </div>
                                                            <textarea type="text" className="form-control" value={this.state.answerText} onChange={this.handleChangeAnswerText} />
                                                        </div> */}
                                                            <div className="form-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={styles.text} >Correct Reinforcement*</span>
                                                                </div>
                                                                <input type="text" className="form-control" id="correct_reinforcement" maxLength={50} minLength={2} value={this.state.correctAns} onChange={this.handleChangeCorrectAns} onBlur={this.correct_reinforcementValidation} />
                                                                <label className="input-error error_correct_reinforcement"></label>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text" style={styles.text} >Incorrect Reinforcement*</span>
                                                                </div>
                                                                <input type="text" className="form-control" id="incorrect_reinforcement" maxLength={50} minLength={2} value={this.state.incorrectAns} onChange={this.handleChangeIncorrectAns} onBlur={this.incorrect_reinforcementValidation} />
                                                                <label className="input-error error_incorrect_reinforcement"></label>
                                                            </div>
                                                        </div>
                                                    </Accordion.Collapse>
                                                </div>
                                            </Accordion>
                                            {/* <div className="form-group">
                                            <Accordion style={{ textAlign: 'left' }} defaultActiveKey="1">
                                                <div style={{ marginTop: -5 }}>
                                                    <div as={Card.Body} eventKey="1">
                                                        <h5>Timer</h5>
                                                    </div>
                                                    <Accordion.Collapse eventKey="1">
                                                        <div>
                                                            <div style={{ paddingBottom: 10, marginTop: 5 }}>
                                                                <div className="form-group">
                                                                    <div className="input-group-prepend" style={styles.textbox}>
                                                                        <text className="input-group-text" style={styles.text} >Time Limit (ms)*</text>
                                                                    </div>
                                                                    <input type="text" className="form-control" id="timer" min="5" max="6" value={this.state.time} onChange={this.handleChangeTime} onBlur={this.timerValidation} onKeyDown={this.number_press} />
                                                                    <label className="input-error error_timer"></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Collapse>
                                                </div>
                                            </Accordion>
                                        </div> */}

                                            <div className="form-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" style={styles.text} >Prompt*</span>
                                                </div>
                                                <input type="text" id="prompt" maxLength={50} minLength={2} value={this.state.prompt} onChange={this.handleChangePrompt} className="form-control" onBlur={this.promptValidation} />
                                                <label className="input-error error_prompt"></label>
                                            </div>
                                            <div className="form-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" style={styles.text} >Done text*</span>
                                                </div>
                                                <input type="text" id="done_text" maxLength={30} minLength={2} value={this.state.done_text} onChange={this.handleChangeDoneText} className="form-control" onBlur={this.done_textValidation} />
                                                <label className="input-error error_done_text"></label>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        <button className="btn btn-dark" style={{ width: 150 }}>Save</button>
                                        <div id="snackbar"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                )
            } else {
                return (
                    <div>{this.state.isFetching ? <div style={{ display: 'flex', justifyContent: 'center' }}><img src={require('../../assets/Loader.gif')} /></div> : ''}</div>
                )
            }
        }
    }
}