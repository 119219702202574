import React from 'react';
import '../Css/Styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import API from './Components/Global_API'
import { Icon } from 'react-icons-kit'
import { eye, withLine } from 'react-icons-kit/entypo'
import NoInternet from './Components/NoInternet';

class LoginScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHidePassword: "password",
            frmLogin: {
                email: {
                    index: 4,
                    valid: null,
                    id: 'email',
                    value: '',
                    minLength: 5,
                    required: true,
                    maxLength: 50,
                    validateEmail: '',
                    pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    required_error_msg: 'Email is required.',
                    length_error_msg: 'Email must be between 5 to 50 character.',
                    pattern_error_msg: 'Please Enter valid Email ID.'
                },
                password: {
                    index: 5,
                    valid: null,
                    id: 'password',
                    required: true,
                    value: '',
                    minLength: 5,
                    maxLength: 30,
                    required_error_msg: 'Password is required.',
                    length_error_msg: 'Password must be between 5 to 30 character.'
                },
            },
            current_date: ''
        }
    }

    componentDidMount() {
        var that = this;

        var date = new Date().getDate();
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();
        var hours = new Date().getHours();
        var min = new Date().getMinutes();
        var sec = new Date().getSeconds();

        that.setState({
            current_date:
                year + '-' + month + '-' + date + ' ' + hours + ':' + min + ':' + sec,
        });
    }

    handleShowHide = () => {
        this.setState({
            showHidePassword: this.state.showHidePassword === 'password' ? 'input' : 'password'
        })
    }

    validateField = (elm) => {

        let targetId = elm.target.id;
        let targetValue = elm.target.value;
        let frmLearner = this.state.frmLogin;
        if (frmLearner[targetId].required) {
            if (targetValue.trim().length == 0) {
                document.getElementsByClassName('error_' + targetId)[0].innerHTML = frmLearner[targetId].required_error_msg;
                document.getElementsByClassName('error_' + targetId)[0].style.display = 'block';

                document.getElementById(targetId).focus();
            } else if (targetValue.trim().length < frmLearner[targetId].minLength || targetValue.trim().length > frmLearner[targetId].maxLength) {
                document.getElementsByClassName('error_' + targetId)[0].innerHTML = frmLearner[targetId].length_error_msg;
                document.getElementsByClassName('error_' + targetId)[0].style.display = 'block';

                document.getElementById(targetId).focus();

            } else if (targetId == 'email') {

                if (targetValue.trim().match(frmLearner['email'].pattern)) {

                    document.getElementsByClassName('error_email')[0].innerHTML = "";
                    document.getElementsByClassName('error_email')[0].style.display = 'none';
                } else {
                    document.getElementsByClassName('error_email')[0].innerHTML = frmLearner['email'].pattern_error_msg;
                    document.getElementsByClassName('error_email')[0].style.display = 'block';
                    document.getElementById('email').focus();
                }

            } else {
                document.getElementsByClassName('error_' + targetId)[0].innerHTML = "";
                document.getElementsByClassName('error_' + targetId)[0].style.display = 'none';

            }
        } else {
            document.getElementsByClassName('error_' + targetId)[0].innerHTML = "";
            document.getElementsByClassName('error_' + targetId)[0].style.display = 'none';

        }
    }


    validateForm = (elm) => {
        let frmLearner = this.state.frmLogin;
        let isvalid = false;
        let firstInvalidItem = '';
        Object.keys(frmLearner).forEach(function (item) {
            var id = frmLearner[item].id;
            var val = document.getElementById(id).value;
            if (!frmLearner[item].valid) {
                if (frmLearner[item].required) {
                    if (val.trim().length == 0) {
                        if (!firstInvalidItem) firstInvalidItem = item;
                        document.getElementsByClassName('error_' + id)[0].innerHTML = frmLearner[item].required_error_msg;
                        document.getElementsByClassName('error_' + id)[0].style.display = 'block';
                        isvalid = false;
                        frmLearner[item].valid = false;
                    } else if (val.trim().length < frmLearner[item].minLength || val.trim().length > frmLearner[item].maxLength) {
                        document.getElementsByClassName('error_' + id)[0].innerHTML = frmLearner[item].length_error_msg;
                        document.getElementsByClassName('error_' + id)[0].style.display = 'block';
                        isvalid = false;
                        if (!firstInvalidItem) firstInvalidItem = item;
                        frmLearner[item].valid = false;

                    } else {
                        document.getElementsByClassName('error_' + id)[0].innerHTML = "";
                        document.getElementsByClassName('error_' + id)[0].style.display = 'none';
                        frmLearner[item].valid = true;
                        isvalid = true;

                    }
                } else {
                    document.getElementsByClassName('error_' + id)[0].innerHTML = "";
                    document.getElementsByClassName('error_' + id)[0].style.display = 'none';
                    frmLearner[item].valid = true;
                    isvalid = true;
                }
            }
        });
        if (firstInvalidItem) document.getElementById(firstInvalidItem).focus();
        this.setState({ frmLogin: frmLearner });
        return isvalid;

    }



    handleChangeEmail = (e) => {
        let fl = this.state.frmLogin;
        fl['email'].value = e.target.value;
        this.setState({ frmLogin: fl });
    }

    handleChangePassword = (e) => {
        let fl = this.state.frmLogin;
        fl['password'].value = e.target.value;
        this.setState({ frmLogin: fl });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let Login = JSON.stringify(
            {
                email_id: this.state.frmLogin.email.value,
                password: this.state.frmLogin.password.value,
                date: this.state.current_date
            });
        fetch(API + 'admin_login.php',
            {
                method: 'POST',
                body: Login
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                if (responseJsonFromServer.result === 'success') {
                    localStorage.setItem("loginUser", JSON.stringify({ 'userId': responseJsonFromServer.user_id, 'email': responseJsonFromServer.email_id, 'name': responseJsonFromServer.user_name, 'mobile_number': responseJsonFromServer.mobile_number, 'first_name': responseJsonFromServer.first_name, 'last_name': responseJsonFromServer.last_name }))
                    this.props.history.push('/CoursesScreen');

                } else {
                    let x = document.getElementById("login");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    x.innerHTML = responseJsonFromServer.msg;
                }
            }).catch((error) => {
                if (error.message == 'Failed to fetch') {
                    this.setState({ networkError: true })
                }
            });
    }

    componentWillMount() {
        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            this.props.history.push("/");
        } else {
            this.props.history.push('/CoursesScreen');
        }
    }

    handleforgot() {
        this.props.history.push('/ForgotScreen')
    }

    handleregistration() {
        this.props.history.push('/New_registration')
    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            return (
                <div className="background-image">
                    <div className="container" style={{ paddingTop: 90 }}>
                        <div className="row " style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: 30 }}>
                            <img src={require('../assets/pta-logo2.png')} style={{ width: 80, height: 80 }} />
                        </div>
                        <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 0 }}>
                            <div className="row col-lg-6 col-xl-6 col-md-8 col-sm-10 divcontainer">
                                <div className="col-lg-6 col-xl-6 col-md-6 col-sm-10 left">
                                    <form onSubmit={this.handleSubmit}>
                                        <div>
                                            <input type="email" autoCorrect="off" autoComplete="off" id="email" maxLength={50} className="form-control" value={this.state.frmLogin.email.value} onChange={this.handleChangeEmail} placeholder="Email" onBlur={this.validateField} />
                                            <label className="input-error error_email text-left" ></label>
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <input type={this.state.showHidePassword} id="password" maxLength={30} minlength={5} className="form-control" value={this.state.frmLogin.password.value} onChange={this.handleChangePassword} placeholder="Password" onBlur={this.validateField} style={{ paddingRight: 35 }} />
                                            <Icon icon={this.state.showHidePassword === "password" ? withLine : eye} onClick={this.handleShowHide} size={20} style={{ position: 'relative', top: -30, right: '-40%', cursor: 'pointer', color: 'rgb(82, 81, 81)' }} />
                                            <label className="input-error error_password text-left" style={{ marginTop: -20 }}></label>
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <button type="submit" className="btn btn-block" disabled={this.state.frmLogin.email.value == '' || this.state.frmLogin.password.value == '' ? true : false} style={{ backgroundColor: '#14b4f0', cursor: this.state.frmLogin.email.value == '' || this.state.frmLogin.password.value == '' ? 'no-drop' : 'pointer' }}><text style={{ color: '#fff', fontWeight: 'bold' }}>Sign in</text></button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-6 col-xl-6 col-md-6 col-sm-10 right" style={{ paddingLeft: 20, paddingRight: 20 }}>
                                    <h3 className="text-left">Welcome to PT Academy</h3>
                                    <p className="text-left" style={{ fontSize: 18 }}>Sign in to your account for access to your dashboard, content and courses.</p>
                                    {/* <p className="text-left font-weight-bold" onClick={() => this.handleregistration()} style={{ cursor: 'pointer' }}>Register today for free.</p> */}
                                </div>
                                <div className="col-12 bottom pt-3">
                                    <text onClick={() => this.handleforgot()} style={{ cursor: 'pointer', fontWeight: '600' }} className="text-center">Forgot your password?</text>
                                </div>
                            </div>

                        </div>
                        <div id="login"></div>
                    </div>
                </div>
            )
        }
    }
}

export default LoginScreen;
