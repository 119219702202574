import React from 'react'
import { Icon } from 'react-icons-kit'
import { smallDown, smallRight, smallLeft } from 'react-icons-kit/entypo'
import { pencil } from 'react-icons-kit/fa/pencil'
import { Accordion, Card, Button, Media } from 'react-bootstrap'
import API from '../Components/Global_API'

// const API = 'http://pta-academy.tk/admin/'
export default class Vimeo_video_detail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.data.title,
            videoURL: this.props.data.video
        }
    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    render() {
        return (
            <div>
                    <div style={{ height: 340, display: 'flex', flexDirection: 'column', justifyContent: 'center', overflowY:'auto' }}>
                        <h5 style={{textAlign: 'center', paddingBottom: 20, color: '#fff', overflowWrap: 'anywhere'}}>{this.state.title}</h5>
                        {/* <video id="background-video" autoPlay controls style={{ width: '100%', height: '50%' }}>
                            <source src={this.state.videoURL+this.props.data.video} type="video/mp4" />
                        </video> */}
                        <iframe src={this.state.videoURL} style={{border: 'none'}} width="100%" height="50%" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                    </div>
                    <div>
                        <hr color= '#fff' />
                        <div className="text-center">
                            <button className="btn" onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={styles.done_btn}>
                                Skip video
                            </button>
                        </div>
                    </div>
                </div>
        )
    }
}

const styles = {
    question_content: {
        backgroundColor: '#34baeb',
        height: 500,
        margin: 30
    },
    edit_btn: {
        backgroundColor: '#223042',
        color: '#fff',
        marginTop: 30
    },
    slider_card: {
        backgroundColor: '#fff',
        paddingBottom: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    }
}