import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap'
import API from '../Components/Global_API'
import { Icon } from 'react-icons-kit'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'
import NoInternet from '../Components/NoInternet';

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    }
}

export default class VimeoVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lesson_id: this.props.data.lesson_id,
            title: this.props.data.title,
            videoURL: this.props.data.video,
            video: '',
            question_id: this.props.data.question_id,
            course_image: this.props.data.course_image,
            prev_review: true
        }
        let video = this.props.data.video.split('/')
        this.state.video = video[4]
    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }

    handleSubmit = () => {

        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 250) {
            document.getElementById("title").focus();
        } else if (this.state.video.trim().length < 2 || this.state.video.trim().length > 10) {
            document.getElementById("video_id").focus();

        } else {
            let VimeoVideo = JSON.stringify({
                lesson_id: this.state.lesson_id,
                title: this.state.title,
                video_id: this.state.video,
                vimeo_id: this.state.question_id
            })

            fetch(API + 'edit_vimeo_video.php',
                {
                    method: 'POST',
                    body: VimeoVideo
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        setTimeout(() => {
                            this.props.handleUpdateQuetionData();
                        }, 2000);

                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }
    }

    videoValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;

        if (value.trim().length == 0) {
            document.getElementsByClassName('error_video')[0].innerHTML = "Video id is required.";
            document.getElementsByClassName('error_video')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 10) {
            document.getElementsByClassName('error_video')[0].innerHTML = 'Video id must be between 2 to 10 character.';
            document.getElementsByClassName('error_video')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_video')[0].innerHTML = "";
            document.getElementsByClassName('error_video')[0].style.display = 'none';
        }
    }

    titleValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;

        if (value.trim().length > 250) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 0 to 250 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }

    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value });
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handleChangeVideo = (e) => {
        this.setState({
            video: e.target.value
        })
    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            return (
                <div className="row text-center">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="card card-sm">
                            <div className="card-content block-xl"  >
                                <div className="body-container" style={styles.body}>
                                    <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }}>
                                        <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                            <div className="row text-center">
                                                <div style={{ width: '10%', paddingLeft: 5 }}>
                                                    <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                                </div>
                                                <div style={{ paddingTop: 15, width: '60%' }}>
                                                    <hr color='#fff' />
                                                </div>
                                                <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                    <div style={styles.dot_btn}>
                                                        <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                        <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ height: 380, paddingTop: 35, overflowY: 'auto' }}>
                                                <h5 className="text-center" style={{ color: '#fff', paddingBottom: 10 }}>{this.state.title}</h5>
                                                <iframe src={this.state.videoURL} style={{ border: 'none' }} width="100%" height="50%" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen></iframe>
                                            </div>

                                            <div>
                                                <hr color='#fff' />
                                                <div className="text-center">
                                                    <button className="btn" onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} style={styles.done_btn}>
                                                        Skip video
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                            <div>
                                                <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                    <div className="text-center">
                                                        <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                            Review last slide
                                                    </button>
                                                    </div>
                                                    <div className="text-center">
                                                        <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                            Restart lesson
                                                    </button>
                                                    </div>
                                                    <div className="text-center">
                                                        <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                            Cancel
                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                                <div className="col-4">
                                    <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                                </div>
                                <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                    <span >Slide {this.props.current_index + 1}</span>
                                </div>
                                <div className="col-4">
                                    <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <div className="card-sm" >
                            <div className="card-content block-xl" style={{ height: 550, overflowY: 'auto', overflowX: 'hidden' }}>
                                <h5 className="text-left" style={{ paddingBottom: 10 }}>Video</h5>
                                <div className="text-left">
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <span className="input-group-text" style={styles.text}>Title</span>
                                        </div>
                                        <input type="text" className="form-control" id="title" maxLength={250} value={this.state.title} onChange={this.handleChangeTitle} onBlur={this.titleValidation} />
                                        <label className={"input-error error_title"}></label>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <span className="input-group-text" style={styles.text}>Video ID*</span>
                                        </div>
                                        <input type="text" className="form-control" id="video_id" maxLength={10} minLength={2} value={this.state.video} onChange={this.handleChangeVideo} onBlur={this.videoValidation} />
                                        <label className={"input-error error_video"}></label>
                                        <small style={{ color: '#848F9D', fontSize: '88%' }}>The ID of your video can be found by selecting the "share" option on Vimeo. <br /> eg. https://vimeo.com/87701971 is "87701971"</small>
                                    </div>
                                </div>
                            </div>
                            <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                                <button onClick={this.handleSubmit} className="btn btn-dark" style={{ width: 150 }}>Save</button>
                                <div id="snackbar"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}