import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Icon } from 'react-icons-kit'
import { trashO, plus } from 'react-icons-kit/fa'
import { smallRight, smallLeft } from 'react-icons-kit/entypo'
import { Accordion, Card, Button, Modal } from 'react-bootstrap'
import API from '../Components/Global_API'
import NoInternet from '../Components/NoInternet';

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    },
    textinput: {
        resize: "none",
        // resize: "auto",
        marginBottom: 5,
        fontSize: 20,
        overflow: 'auto'
        // border: 'none'
    },
}

export default class Expandable_assessment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            question_id: this.props.data.question_id,
            title: this.props.data.title,
            unit: this.props.unit,
            titleContent: '',
            content: '',
            prompt: this.props.data.prompt,
            done_text: this.props.data.done_text,
            user: this.props.data.expandable_options,
            course_image: this.props.course_image,
            done_button_state: true,
            done_button_name: this.props.data.prompt,
            prev_review: true,
            current_index: '',
            expandable_title_validation: false,
            expandable_content_validation: false,
            changeSectionStatus: '',
            PublishedSectionPopup: false
        }


        this.Add = this.Add.bind(this);
        this.Remove = this.Remove.bind(this);
    }

    DisableButtonFunction = param => e => {

        let data_result = this.state.user;
        data_result.map((val) => {
            if (val.expandable_list_id === param) {
                val.selected = true;

            }
        })
        const result = data_result.filter(data_result => data_result.selected !== true);
        if (result.length == 0) {
            this.setState({ done_button_state: false })
        }

    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {
        this.handlePrevButton(this.props.current_index - 1)
        this.state.prevbutton = true
    }

    handleRestartLesson = () => {
        this.handlePrevButton(0)
        this.state.prevbutton = true
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }

    handleSubmit = (e) => {
        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 250) {
            document.getElementById("title").focus();
        } else if (this.state.expandable_title_validation || this.state.expandable_content_validation) {
            console.log("not valid");
        }
        else if (this.state.prompt.trim().length < 2 || this.state.prompt.trim().length > 50) {
            document.getElementById("prompt").focus();
        } else if (this.state.done_text.trim().length < 2 || this.state.done_text.trim().length > 30) {
            document.getElementById("done_text").focus();
        } else {
            let Expandable_list = JSON.stringify(
                {
                    unit_id: this.state.unit.unit_id,
                    assessment_expandable_id: this.state.question_id,
                    title: this.state.title,
                    list: this.state.user,
                    prompt: this.state.prompt,
                    done_text: this.state.done_text,
                });

            fetch(API + 'edit_assessment_expandable.php',
                {
                    method: 'POST',

                    body: Expandable_list
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        setTimeout(() => {
                            this.props.handleUpdateQuetionData();
                        }, 2000);
                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }
    }



    titleValidation = (e) => {

        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_title')[0].innerHTML = "Title is required.";
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 2 to 250 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }

    expandable_titleValidation = (i, e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_expandable_title' + i)[0].innerHTML = "Title is required.";
            document.getElementsByClassName('error_expandable_title' + i)[0].style.display = 'block';
            this.setState({ expandable_title_validation: true })
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_expandable_title' + i)[0].innerHTML = 'Title must be between 2 to 250 character.';
            document.getElementsByClassName('error_expandable_title' + i)[0].style.display = 'block';
            this.setState({ expandable_title_validation: true })
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_expandable_title' + i)[0].innerHTML = "";
            document.getElementsByClassName('error_expandable_title' + i)[0].style.display = 'none';
            this.setState({ expandable_title_validation: false })
        }

    }


    expandable_contentValidation = (i, e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_expandable_content' + i)[0].innerHTML = "Content is required.";
            document.getElementsByClassName('error_expandable_content' + i)[0].style.display = 'block';
            this.setState({ expandable_content_validation: true })
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 3000) {
            document.getElementsByClassName('error_expandable_content' + i)[0].innerHTML = "Content must be between 2 to 3000 character.";
            document.getElementsByClassName('error_expandable_content' + i)[0].style.display = 'block';
            this.setState({ expandable_content_validation: true })
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            document.getElementsByClassName('error_expandable_content' + i)[0].innerHTML = "";
            this.setState({ expandable_content_validation: false })
            e.target.classList.remove('error');
            document.getElementsByClassName('error_expandable_content' + i)[0].style.display = 'none';
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    done_textValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }

    handleChangeTitle1 = async (i, e) => {

        let value = e.target.value;
        let users = this.state.user;
        users.map((item, index) => {
            if (index == i) {
                item.title = value;

            }
        })
        await this.setState({ user: users });

    }
    handleChangeListContent = async (i, e) => {

        let value = e.target.value;
        let users = this.state.user;
        users.map((item, index) => {
            if (index == i) {
                item.content = value;
            }
        })
        await this.setState({ user: users });

    }
    handleChangeTitle = (e) => {

        this.setState({ title: e.target.value })
    }

    handleChangeTitleContent1 = (e) => {
        this.setState({ titleContent: e.target.value })
    }

    handleChangeTitleContent2 = (e) => {
        this.setState({ titleContent2: e.target.value })
    }

    handleChangeTitleContent3 = (e) => {
        this.setState({ titleContent3: e.target.value })
    }

    handleChangeContent1 = (e) => {
        this.setState({ content: e.target.value })
    }

    handleChangeContent2 = (e) => {
        this.setState({ content2: e.target.value })
    }

    handleChangeContent3 = (e) => {
        this.setState({ content3: e.target.value })
    }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value })
    }

    handleChangeDoneText = (e) => {
        this.setState({ done_text: e.target.value })
    }

    handleDoneButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }

    handlePrevButton = (current_index) => {
        this.props.nextQuestion(current_index)
    }


    Add() {
        let add = this.state.user;
        if (this.state.user.length < 6) {
            let newu = { title: 'product', content: 'Our brilliant new sleek design allows the user to have unparalleled comfort when using the device.' }

            add.push(newu)
            this.setState({
                user: add,
            })
        }
    }

    handleSectionStatusPopupOpen(item) {
        this.setState({
            PublishedSectionPopup: true,
            changeSectionStatus: item
        });
    }

    handlePublishedSectionPopup = () => {
        this.setState({ PublishedSectionPopup: false });
    }

    Remove = async () => {
        var i = this.state.changeSectionStatus;
        let users = this.state.user;
        if (this.state.user.length > 1) {
            users.splice(i, 1);
            await this.setState({ user: users, PublishedSectionPopup: false });
        }
    }



    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            return (
                <div className="row text-center">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="card-content block-xl"  >
                            <div className="body-container" style={styles.body}>
                                <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }}>
                                    <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                        <div className="row text-center">
                                            <div style={{ width: '10%', paddingLeft: 5 }}>
                                                <img src={require('../../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                            </div>
                                            <div style={{ paddingTop: 15, width: '60%' }}>
                                                {/* <text style={{color: '#fff',fontSize:20}}>Scrollable</text> */}
                                                <hr color='#fff' />
                                            </div>
                                            <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                <div style={styles.dot_btn}>
                                                    <label style={{ color: '#fff', fontSize: '80%', padding: 4 }}>{this.props.current_index + 1} / {this.props.que_count}</label>
                                                    <img onClick={this.handlePreview} src={require('../../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ height: 380, fontSize: 15, paddingTop: 50, overflowY: 'auto' }}>
                                            <h5 style={{ color: '#fff', paddingBottom: 20 }}>{this.state.title}</h5>
                                            <Accordion style={{ textAlign: 'left' }}>
                                                {this.state.user.map((item, i) => (
                                                    <Card key={i}>
                                                        <Accordion.Toggle as={Card.Header} eventKey={i} onClick={this.DisableButtonFunction(item.expandable_list_id)}>
                                                            {item.title}
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey={i}>
                                                            <Card.Body style={{ whiteSpace: 'pre-line' }}>{item.content}</Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                ))}
                                            </Accordion>
                                        </div>

                                        <div>
                                            <hr color='#fff' />
                                            <div className="text-center">
                                                <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                                                    {/* {this.state.done_button_name} */}
                                                    {this.state.done_button_state ? this.state.prompt : this.state.done_text}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, top: -30, left: -15, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                                        <div>
                                            <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                <div className="text-center">
                                                    <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>
                                                        Review last slide
                                                </button>
                                                </div>
                                                <div className="text-center">
                                                    <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>
                                                        Restart lesson
                                                </button>
                                                </div>
                                                <div className="text-center">
                                                    <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                        Cancel
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                                <div className="col-4">
                                    <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                                </div>
                                <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                                    <span >Slide {this.props.current_index + 1}</span>
                                </div>
                                <div className="col-4">
                                    <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <div className="card-sm" >
                            <div className="card-content block-xl" style={{ height: 550, overflowY: 'auto', overflowX: 'hidden' }}>

                                <h5 className="text-left" style={{ paddingBottom: 10 }}>Expandable List</h5>
                                <div className="text-left">
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <span className="input-group-text" style={styles.text}>Title*</span>
                                        </div>
                                        <input type="text" className="form-control" id="title" minLength={2} maxLength={250} value={this.state.title} onChange={this.handleChangeTitle.bind(this)} onBlur={this.titleValidation} />
                                        <label className="input-error error_title"></label>
                                    </div>
                                    <hr />
                                    <div>
                                        <Accordion style={{ textAlign: 'left' }} defaultActiveKey="0">
                                            <div style={{ backgroundColor: '#EEF2F6', marginTop: -5 }}>
                                                <div style={{ height: 40 }} eventkey="0">
                                                    <h5>List</h5>
                                                </div>
                                                <Accordion.Collapse eventKey="0">
                                                    <div>
                                                        {this.state.user.map((item, i) => (
                                                            <Card style={{ padding: 5, marginTop: 10 }} key={i}>
                                                                <div className="form-group">
                                                                    <div className="input-group-prepend" style={styles.textbox}>
                                                                        <span className="input-group-text" style={styles.text} >Title*</span>
                                                                    </div>
                                                                    <input type="text" className="form-control" id={"expandable_title" + i} value={item.title} minLength={2} maxLength={250} onChange={this.handleChangeTitle1.bind(this, i)} onBlur={this.expandable_titleValidation.bind(this, i)} />
                                                                    <label className={"input-error error_expandable_title" + i}></label>
                                                                </div>
                                                                <div className="form-group">
                                                                    <div className="input-group-prepend" style={styles.textbox}>
                                                                        <span className="input-group-text" style={styles.text} >Content*</span>
                                                                    </div>
                                                                    <textarea type="text" className="form-control" style={styles.textinput} rows={5} id={"expandable_content" + i} value={item.content} maxLength={3000} minLength={2} onChange={this.handleChangeListContent.bind(this, i)} onBlur={this.expandable_contentValidation.bind(this, i)} />
                                                                    <label className={"input-error error_expandable_content" + i}></label>
                                                                </div>
                                                                <div >
                                                                    <Button variant="default" style={{ backgroundColor: '#E6EBF0', cursor: this.state.user.length == 1 ? 'no-drop' : 'pointer' }} disabled={this.state.user.length == 1 ? true : false} block onClick={this.handleSectionStatusPopupOpen.bind(this, i)}>
                                                                        <Icon size={20} icon={trashO} style={{ paddingRight: 10 }} />
                                                                    Remove this section
                                                                </Button>
                                                                </div>
                                                            </Card>
                                                        ))}
                                                        <Modal size="sm" show={this.state.PublishedSectionPopup} onHide={this.handlePublishedSectionPopup}>
                                                            <Modal.Body>Are you sure you want to remove this?</Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={this.handlePublishedSectionPopup}>No</Button>
                                                                <Button style={styles.confirmButton} onClick={() => this.Remove()}>Yes</Button>
                                                            </Modal.Footer>
                                                        </Modal>

                                                        <div style={{ marginTop: 10, color: '#fff' }}>
                                                            <Button variant="default" disabled={this.state.user.length == 6 ? true : false} onClick={this.Add.bind(this)} style={{ backgroundColor: '#223042', color: '#fff', cursor: this.state.user.length == 6 ? 'no-drop' : 'pointer' }} block>
                                                                <Icon size={20} icon={plus} style={{ paddingRight: 10, color: '#fff' }} />
                                                            Add a section
                                                        </Button>
                                                        </div>
                                                    </div>
                                                </Accordion.Collapse>
                                            </div>
                                        </Accordion>
                                    </div>
                                    <hr />

                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <span className="input-group-text" style={styles.text} >Prompt*</span>
                                        </div>
                                        <input type="text" className="form-control" id="prompt" value={this.state.prompt} minLength={2} maxLength={50} onChange={this.handleChangePrompt} onBlur={this.promptValidation} />
                                        <label className="input-error error_prompt"></label>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group-prepend" style={styles.textbox}>
                                            <span className="input-group-text" style={styles.text} >Done text*</span>
                                        </div>
                                        <input type="text" className="form-control" id="done_text" value={this.state.done_text} minLength={2} maxLength={30} onChange={this.handleChangeDoneText} onBlur={this.done_textValidation} />
                                        <label className="input-error error_done_text"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                                <button onClick={this.handleSubmit} className="btn btn-dark" style={{ width: 150 }}>Save</button>
                                <div id="snackbar"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}