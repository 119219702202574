import React from 'react'

export default class Peer_authoring_detail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.data.title,
            no_uploaded_message: this.props.data.no_media_updated_message,
            upload_button_label: this.props.data.upload_button_label,
        }
    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    handleFreeText = (e) => {
        this.setState({ free_text: e.target.value })
    }

    handleKeyDown = (e) => {
        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    render() {
        return (
            <div>
                <div style={{ height: 340, display: 'flex', flexDirection: 'column', justifyContent: 'center', overflowY: 'auto' }}>
                    <h3 className="text-center" style={{ color: '#fff', paddingBottom: 10, overflowWrap: 'anywhere' }}>{this.state.title}</h3>
                    <p className="text-center" style={{ color: '#fff', overflowWrap: 'anywhere' }}>{this.state.no_uploaded_message}</p>
                    <div className="text-center">
                        <button className="btn" style={styles.upload_button_label}>
                            {this.state.upload_button_label}
                        </button>
                    </div>
                </div>
                <div>
                    <hr color='#fff' />
                    <div className="text-center">
                        <button className="btn" onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={styles.done_btn}>
                            {this.props.data.prompt}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    question_content: {
        backgroundColor: '#34baeb',
        height: 500,
        margin: 30
    },
    edit_btn: {
        backgroundColor: '#223042',
        color: '#fff',
        marginTop: 30
    },
    slider_card: {
        backgroundColor: '#fff',
        paddingBottom: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    upload_button_label: {
        backgroundColor: '#fff',
        marginBottom: -150,
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
}