import React from 'react';
import ReactHtmlParser from 'react-html-parser';
// import './Crossword_CSS.css';

class Crossword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      wordList: [],
      startSquare: '',
      selectedSquares: [],
      curOrientation: '',
      selctedClass: '',
      wordFoundClass: '',
      completeClass: '',
      sqaureText: '',
      curWord: '',
      puzzleOutput: '',
      wordsOutput: '',
      puzzle: [],
      title: this.props.title,
      words: [], 
      letters: 'abcdefghijklmnoprstuvwy',
      allOrientations: ['horizontal', 'horizontalBack', 'vertical', 'verticalUp',
        'diagonal', 'diagonalUp', 'diagonalBack', 'diagonalUpBack'],
      orientations: {
        horizontal: function (x, y, i) { return { x: x + i, y: y }; },
        horizontalBack: function (x, y, i) { return { x: x - i, y: y }; },
        vertical: function (x, y, i) { return { x: x, y: y + i }; },
        verticalUp: function (x, y, i) { return { x: x, y: y - i }; },
        diagonal: function (x, y, i) { return { x: x + i, y: y + i }; },
        diagonalBack: function (x, y, i) { return { x: x - i, y: y + i }; },
        diagonalUp: function (x, y, i) { return { x: x + i, y: y - i }; },
        diagonalUpBack: function (x, y, i) { return { x: x - i, y: y - i }; }
      },
      checkOrientations: {
        horizontal: function (x, y, h, w, l) { return w >= x + l; },
        horizontalBack: function (x, y, h, w, l) { return x + 1 >= l; },
        vertical: function (x, y, h, w, l) { return h >= y + l; },
        verticalUp: function (x, y, h, w, l) { return y + 1 >= l; },
        diagonal: function (x, y, h, w, l) { return (w >= x + l) && (h >= y + l); },
        diagonalBack: function (x, y, h, w, l) { return (x + 1 >= l) && (h >= y + l); },
        diagonalUp: function (x, y, h, w, l) { return (w >= x + l) && (y + 1 >= l); },
        diagonalUpBack: function (x, y, h, w, l) { return (x + 1 >= l) && (y + 1 >= l); }
      },
      skipOrientations: {
        horizontal: function (x, y, l) { return { x: 0, y: y + 1 }; },
        horizontalBack: function (x, y, l) { return { x: l - 1, y: y }; },
        vertical: function (x, y, l) { return { x: 0, y: y + 100 }; },
        verticalUp: function (x, y, l) { return { x: 0, y: l - 1 }; },
        diagonal: function (x, y, l) { return { x: 0, y: y + 1 }; },
        diagonalBack: function (x, y, l) { return { x: l - 1, y: x >= l - 1 ? y + 1 : y }; },
        diagonalUp: function (x, y, l) { return { x: 0, y: y < l - 1 ? l - 1 : y + 1 }; },
        diagonalUpBack: function (x, y, l) { return { x: l - 1, y: x >= l - 1 ? y + 1 : y }; }
      }
    }
    let data=[];
    this.props.crossword_data.map(item=>(
      
      data.push(item.words)
    ))
    
    this.state.words = data;

    this.startTurn = this.startTurn.bind(this);
    this.endTurn = this.endTurn.bind(this);
    this.select = this.select.bind(this);


  }

  fillPuzzle(words, options) {

    var puzzle = [], i, j, len;

    // initialize the puzzle with blanks
    for (i = 0; i < options.height; i++) {
      puzzle.push([]);
      for (j = 0; j < options.width; j++) {
        puzzle[i].push('');
      }
    }

    // add each word into the puzzle one at a time
    for (i = 0, len = words.length; i < len; i++) {
      if (!this.placeWordInPuzzle(puzzle, options, words[i])) {
        // if a word didn't fit in the puzzle, give up
        return null;
      }
    }

    // return the puzzle
    return puzzle;
  }
  placeWordInPuzzle(puzzle, options, word) {
    // find all of the best locations where this word would fit
    var locations = this.findBestLocations(puzzle, options, word);

    if (locations.length === 0) {
      return false;
    }

    // select a location at random and place the word there
    var sel = locations[Math.floor(Math.random() * locations.length)];
    this.placeWord(puzzle, word, sel.x, sel.y, this.state.orientations[sel.orientation]);

    return true;
  }
  findBestLocations(puzzle, options, word) {

    var locations = [],
      height = options.height,
      width = options.width,
      wordLength = word.length,
      maxOverlap = 0; // we'll start looking at overlap = 0
    // loop through all of the possible orientations at this position
    for (var k = 0, len = options.orientations.length; k < len; k++) {

      var orientation = options.orientations[k],
        check = this.state.checkOrientations[orientation],
        next = this.state.orientations[orientation],
        skipTo = this.state.skipOrientations[orientation],
        x = 0, y = 0;

      // loop through every position on the board
      while (y < height) {

        // see if this orientation is even possible at this location
        if (check(x, y, height, width, wordLength)) {

          // determine if the word fits at the current position
          var overlap = this.calcOverlap(word, puzzle, x, y, next);

          // if the overlap was bigger than previous overlaps that we've seen
          if (overlap >= maxOverlap || (!options.preferOverlap && overlap > -1)) {
            maxOverlap = overlap;
            locations.push({ x: x, y: y, orientation: orientation, overlap: overlap });
          }

          x++;
          if (x >= width) {
            x = 0;
            y++;
          }
        }
        else {
          // if current cell is invalid, then skip to the next cell where
          // this orientation is possible. this greatly reduces the number
          // of checks that we have to do overall
          var nextPossible = skipTo(x, y, wordLength);
          x = nextPossible.x;
          y = nextPossible.y;
        }

      }
    }

    // finally prune down all of the possible locations we found by
    // only using the ones with the maximum overlap that we calculated
    return options.preferOverlap ?
      this.pruneLocations(locations, maxOverlap) :
      locations;
  }
  calcOverlap(word, puzzle, x, y, fnGetSquare) {
    var overlap = 0;

    // traverse the squares to determine if the word fits
    for (var i = 0, len = word.length; i < len; i++) {

      var next = fnGetSquare(x, y, i),
        square = puzzle[next.y][next.x];

      // if the puzzle square already contains the letter we
      // are looking for, then count it as an overlap square
      if (square === word[i]) {
        overlap++;
      }
      // if it contains a different letter, than our word doesn't fit
      // here, return -1
      else if (square !== '') {
        return -1;
      }
    }

    // if the entire word is overlapping, skip it to ensure words aren't
    // hidden in other words
    return overlap;
  }
  pruneLocations(locations, overlap) {

    var pruned = [];
    for (var i = 0, len = locations.length; i < len; i++) {
      if (locations[i].overlap >= overlap) {
        pruned.push(locations[i]);
      }
    }

    return pruned;
  }
  placeWord(puzzle, word, x, y, fnGetSquare) {
    for (var i = 0, len = word.length; i < len; i++) {
      var next = fnGetSquare(x, y, i);
      puzzle[next.y][next.x] = word[i];
    }
  }
  startTurn(e) {
    e.target.classList.add("selected");
    this.setState({ startSquare: e.target, selectedSquares: [...this.state.selectedSquares, e.target], curWord: e.target.innerHTML });

  };
  select(e) {

    // if the user hasn't started a word yet, just return
    if (!this.state.startSquare) {
      return;
    }
    // if the new square is actually the previous square, just return
    var lastSquare = this.state.selectedSquares[this.state.selectedSquares.length - 1];
    if (lastSquare === e.target) {
      return;
    }

    // see if the user backed up and correct the selectedSquares state if
    // they did
    var backTo;

    for (var i = 0, len = this.state.selectedSquares.length; i < len; i++) {

      if (this.state.selectedSquares[i] === e.target) {

        backTo = i + 1;
        break;
      }
    }

    while (backTo < this.state.selectedSquares.length) {

      // $(selectedSquares[selectedSquares.length-1]).removeClass('selected');
      this.state.selectedSquares[this.state.selectedSquares.length - 1].classList.remove("selected")
      this.setState({ selectedSquares: this.state.selectedSquares.splice(backTo, 1), curWord: this.state.curWord.substr(0, this.state.curWord.length - 1) });

    }
    // see if this is just a new orientation from the first square
    // this is needed to make selecting diagonal words easier

    var newOrientation = this.calcOrientation(
      this.state.startSquare.getAttribute('x') - 0,
      this.state.startSquare.getAttribute('y') - 0,
      e.target.getAttribute('x') - 0,
      e.target.getAttribute('y') - 0
    );
    if (newOrientation) {
      this.setState({ selectedSquares: [this.state.startSquare], curWord: this.state.startSquare.innetHTML, curOrientation: newOrientation });

      if (lastSquare !== this.state.startSquare) {
        lastSquare.classList.remove("selected");

        lastSquare = this.state.startSquare;
      }

    }

    // see if the move is along the same orientation as the last move
    var orientation = this.calcOrientation(
      lastSquare.getAttribute('x') - 0,
      lastSquare.getAttribute('y') - 0,
      e.target.getAttribute('x') - 0,
      e.target.getAttribute('y') - 0
    );

    // if the new square isn't along a valid orientation, just ignore it.
    // this makes selecting diagonal words less frustrating
    if (!orientation) {
      return;
    }

    // finally, if there was no previous orientation or this move is along
    // the same orientation as the last move then play the move

    if (!this.state.curOrientation || this.state.curOrientation === orientation) {
      this.setState({ curOrientation: orientation });
      this.playTurn(e.target);
    }


  };
  drawPuzzle(el, puzzle) {
    var output = '';
    // for each row in the puzzle
    for (var i = 0, height = puzzle.length; i < height; i++) {
      // append a div to represent a row in the puzzle
      var row = puzzle[i];
      output += '<div>';
      // for each element in that row
      for (var j = 0, width = row.length; j < width; j++) {
        // append our button with the appropriate class
        output += '<button class="puzzleSquare" onPointerDown={this.startTurn} onPointerOver={this.select} onPointerUp={this.endTurn} x="' + j + '" y="' + i + '">';
        output += row[j] || '&nbsp;';
        output += '</button>';
      }
      // close our div that represents a row
      output += '</div>';
    }
    this.setState({ puzzleOutput: output });
  };

  /**
  * Draws the words by inserting an unordered list into el.
  *
  * @param {String} el: The jQuery element to write the words to
  * @param {[String]} words: The words to draw
  */
  drawWords(el, words) {

    var output = '<ul>';
    for (var i = 0, len = words.length; i < len; i++) {
      var word = words[i];
      output += '<li class="word ' + word + '">' + word;
    }
    output += '</ul>';
    this.setState({ wordsOutput: output });
    //$(el).prepend(output);
  };
  touchMove(e) {
    var xPos = e.originalEvent.touches[0].pageX;
    var yPos = e.originalEvent.touches[0].pageY;
    var targetElement = document.elementFromPoint(xPos, yPos);
    //this.select(targetElement);
  };

  mouseMove() {
    // this.select(this);
  };
  playTurn(square) {
    for (var i = 0, len = this.state.wordList.length; i < len; i++) {
      if (this.state.curWord && square.innerHTML) {
        let nword = this.state.curWord.trim() + square.innerHTML.trim();
        if (this.state.wordList[i].indexOf(nword) === 0) {
          square.classList.add('selected');
          let ss = this.state.selectedSquares;
          ss.push(square);
          this.setState({ selectedSquares: ss, curWord: nword });
          // curWord += $(square).text();
          //  this.state.curWord=this.state.sqaureText;
          break;
        }
      }
    }
  };
  calcOrientation(x1, y1, x2, y2) {

    for (var orientation in this.state.orientations) {
      var nextFn = this.state.orientations[orientation];
      var nextPos = nextFn(x1, y1, 1);

      if (nextPos.x === x2 && nextPos.y === y2) {
        return orientation;
      }
    }

    return null;
  }
  endTurn(e) {

    let len = this.state.wordList.length;
    for (var i = 0; i < len; i++) {

      if (this.state.wordList[i] === this.state.curWord) {
        var elements = document.getElementsByClassName('selected');
        for (var e = 0; e < elements.length; e++) {
          elements[e].classList.add('found');
        }
        //$('.selected').addClass('found');
        this.setState({ 'selctedClass': 'found' });
        this.state.wordList.splice(i, 1);
        var elementCWord = document.getElementsByClassName(this.state.curWord);
        for (var ec = 0; ec < elementCWord.length; ec++) {
          elementCWord[ec].classList.add('wordFound');
        }

        this.setState({ 'wordFoundClass': 'wordFound' });
        //$('.' + this.state.curWord).addClass('wordFound');
      }

      if (this.state.wordList.length === 0) {
        this.setState({ 'completeClass': 'complete' });
        var elementspuzzleSquare = document.getElementsByClassName('puzzleSquare');
        for (var ecp = 0; ecp < elementspuzzleSquare.length; ecp++) {

          elementspuzzleSquare[ecp].classList.add('complete');
        }
      }



    }

    // reset the turn
    this.setState({ 'selctedClass': '' });
    var elements = document.getElementsByClassName('selected');
    for (var e = 0; e < elements.length; e++) {
      elements[e].classList.remove('selected');
    }
    this.setState({ startSquare: null, selectedSquares: [], curWord: '', curOrientation: null });


  }
  create(words, puzzleEl, wordsEl, options) {

    let wordList = words.slice(0).sort();
    this.setState({ wordList: wordList });
    var puzzle = this.newPuzzle(words, options);

    this.setState({ puzzle: puzzle });
    // draw out all of the words
    this.drawPuzzle(puzzleEl, puzzle);
    this.drawWords(wordsEl, wordList);

    // attach events to the buttons
    // optimistically add events for windows 8 touch
    /*if (window.navigator.msPointerEnabled) {
      $('.puzzleSquare').on('MSPointerDown', startTurn);
      $('.puzzleSquare').on('MSPointerOver', select);
      $('.puzzleSquare').on('MSPointerUp', endTurn);
    }
    else {
      $('.puzzleSquare').mousedown(startTurn);
      $('.puzzleSquare').mouseenter(mouseMove);
      $('.puzzleSquare').mouseup(endTurn);
      $('.puzzleSquare').on("touchstart", startTurn);
      $('.puzzleSquare').on("touchmove", touchMove);
      $('.puzzleSquare').on("touchend", endTurn);
    }*/

    return puzzle;
  }
  newPuzzle(words, settings) {
    var wordList, puzzle, attempts = 0, opts = settings || {};


    // copy and sort the words by length, inserting words into the puzzle
    // from longest to shortest works out the best
    wordList = words.slice(0).sort(function (a, b) {
      return (a.length < b.length) ? 1 : 0;
    });

    // initialize the options
    var options = {
      height: opts.height || wordList[0].length,
      width: opts.width || wordList[0].length,
      orientations: opts.orientations || this.state.allOrientations,
      fillBlanks: opts.fillBlanks !== undefined ? opts.fillBlanks : true,
      maxAttempts: opts.maxAttempts || 3,
      preferOverlap: opts.preferOverlap !== undefined ? opts.preferOverlap : true
    };

    // add the words to the puzzle
    // since puzzles are random, attempt to create a valid one up to
    // maxAttempts and then increase the puzzle size and try again
    while (!puzzle) {
      while (!puzzle && attempts++ < options.maxAttempts) {
        puzzle = this.fillPuzzle(wordList, options);
      }

      if (!puzzle) {
        options.height++;
        options.width++;
        attempts = 0;
      }
    }

    // fill in empty spaces with random letters
    if (options.fillBlanks) {
      this.fillBlanks(puzzle, options);
    }

    return puzzle;
  }
  fillBlanks(puzzle) {
    for (var i = 0, height = puzzle.length; i < height; i++) {
      var row = puzzle[i];
      for (var j = 0, width = row.length; j < width; j++) {

        if (!puzzle[i][j]) {
          var randomLetter = Math.floor(Math.random() * this.state.letters.length);
          puzzle[i][j] = this.state.letters[randomLetter];
        }
      }
    }
  }
  solve(puzzle, words) {
    var options = {
      height: puzzle.length,
      width: puzzle[0].length,
      orientations: this.state.allOrientations,
      preferOverlap: true
    },
      found = [],
      notFound = [];

    for (var i = 0, len = words.length; i < len; i++) {
      var word = words[i],
        locations = this.findBestLocations(puzzle, options, word);

      if (locations.length > 0 && locations[0].overlap === word.length) {
        locations[0].word = word;
        found.push(locations[0]);
      }
      else {
        notFound.push(word);
      }
    }

    return { found: found, notFound: notFound };
  }
  printPuzzle(puzzle) {
    var puzzleString = '';
    for (var i = 0, height = puzzle.length; i < height; i++) {
      var row = puzzle[i];
      for (var j = 0, width = row.length; j < width; j++) {
        puzzleString += (row[j] === '' ? ' ' : row[j]) + ' ';
      }
      puzzleString += '\n';
    }


    return puzzleString;
  }

  render() {

    return (
      <div className="puzzleWrap mt-5">
        <p className="text-center" style={{color: '#fff', overflowWrap: 'anywhere'}}>{this.state.title}</p>
        <div id='puzzle' className="text-center">
          {this.state.puzzle.map((p, i) => (
            <div>
              {p.map((row, j) => (
                <button className="puzzleSquare" onMouseMove={this.select} onMouseDown={this.startTurn} onMouseUp={this.endTurn} x={j} y={i}>{row ? row : '&nbsp'} </button>

              ))}
            </div>
          ))}





        </div>
        <div id='words'>{ReactHtmlParser(this.state.wordsOutput)}
          <button id='solve'>Solve Puzzle</button>
        </div>


      </div>
    )
  }

  componentDidMount() {
    // this.interval = setInterval(
    //   () => this.setState((prevState)=> ({ Timer: prevState.Timer - 1 })),
    //   1000
    // );

    var gamePuzzle = this.create(
      this.state.words,
      '#puzzle',
      '#words',
      {
        height: 8,
        width: 15,
        fillBlanks: true
      });
    var puzzle = this.newPuzzle(this.state.words, { height: 5, width: 15, fillBlanks: true }
    );

    this.printPuzzle(puzzle);
  }

  // componentDidUpdate(){
  //   if(this.state.Timer === 0){ 
  //     clearInterval(this.interval);
  //   };
  // }

}
export default Crossword;