import React from 'react';
import Router from './Router';
import { Icon } from 'react-icons-kit'
import { smallDown, smallRight, smallLeft } from 'react-icons-kit/entypo'
import { pencil } from 'react-icons-kit/fa/pencil'
import { Button, Modal } from 'react-bootstrap'
import Footer from './Components/Footer';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import Component_Loader_Edit_Lesson from './Display_question/Component_Loader_Edit_Lesson';
import API from './Components/Global_API'
import NoInternet from './Components/NoInternet';

const BackgroundColor = require('../assets/question_bg.jpg')

const styles = {
    title: {
        textAlign: 'center'
    },
    textinput: {
        resize: "none",
        minHeight: 45,
        marginBottom: 5,
        fontSize: 20,
        overflow: 'auto'
    },
    without_image_question_content: {
        backgroundColor: 'rgba(0,0,0,0.15)',
        height: 500,
        margin: 30
    },
    edit_btn: {
        backgroundColor: '#223042',
        color: '#fff',
        marginTop: 30
    },
    slider_card: {
        backgroundColor: '#fff',
        borderRadius: 6,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff'
    },
    navigation: {
        color: '#8f9294',
        fontSize: 18,
        cursor: 'pointer',
        fontWeight: '600',
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    },
    header: {
        maxWidth: 300,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }

}

export default class Create_new_lesson extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lesson_name: '',
            description: '',
            section_id: '',
            lesson_id: '',
            img: '',
            current_question_type: '',
            current_question_index: 0,
            allquestions: [],
            dataSource: [],
            nextbutton: '',
            prevbutton: true,
            course_name: '',
            unit_name: '',
            unit_id: '',
            section: '',
            section_name: '',
            unit: '',
            course: '',
            lesson: '',
            published: false,
            status: 'Unpublished',
            image: '',
            course_id: '',
            prev_review: true,
            component_data: [],
            disable_save_button: true,
            saveButtonFlag: false,
            error_msg: '',
            errorPopup: false,
            cropImagePopup: false,
            crop_image: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 2 / 1,
            },
            lesson_images: '',
            image_error_popup: false
        };
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        let loginUser = localStorage.getItem("loginUser")


        if (loginUser == null) {
            window.location.href = '/';
        } else {
            if (this.props.location.state) {
                this.state.course = this.props.location.state.course;
                this.state.course_id = this.props.location.state.course_id;
                this.state.unit = this.props.location.state.unit;
                let section_name = this.props.location.state.section_name;
                let course_name = this.props.location.state.course_name;
                let unit_name = this.props.location.state.unit_name;
                let unit_id = this.props.location.state.unit_id;
                let section = this.props.location.state.section;
                // this.state.image = this.props.location.state.course.image;
                this.state.lesson_id = this.props.location.state.lesson_id;
                this.state.section_id = this.props.location.state.section_id;
                this.state.section_name = section_name;
                this.state.course_name = course_name;
                this.state.unit_name = unit_name;
                this.state.unit_id = unit_id;
                this.state.section = section;
            }

            if (this.props.location.state.lesson) {
                let l_id = this.props.location.state.lesson.lesson_id;
                let l_name = this.props.location.state.lesson.lesson_name;
                let l_desc = this.props.location.state.lesson.description;
                this.state.status = this.props.location.state.lesson.status;
                this.state.lesson = this.props.location.state.lesson;

                this.state.lesson_id = l_id;
                this.state.lesson_name = l_name;
                this.state.description = l_desc;
            }
            if (this.state.lesson_name === "" || this.state.lesson_name === null) {
                this.state.disable_save_button = true
            } else {
                this.state.disable_save_button = false
            }
        }
    }

    handlePreview = () => {
        this.setState({ prev_review: false })
    }

    handleReviewLastSlide = () => {

        let prevQIndex = this.state.current_question_index - 1;
        if (this.state.allquestions[prevQIndex]) {
            this.setState({ prev_review: true })
            this.setState({ 'current_question_index': prevQIndex })
            this.setState({ 'current_question_type': this.state.allquestions[prevQIndex].question_type })
            if (!this.state.allquestions[prevQIndex - 1]) {
                this.state.prevbutton = true
            }
            this.state.nextbutton = false
        }
    }

    handleRestartLesson = () => {
        let prevQIndex = 0;
        if (this.state.allquestions[prevQIndex]) {
            this.setState({ prev_review: true })
            this.setState({ 'current_question_index': prevQIndex })
            this.setState({ 'current_question_type': this.state.allquestions[prevQIndex].question_type })
            if (!this.state.allquestions[prevQIndex - 1]) {
                this.state.prevbutton = true
            }
            this.state.nextbutton = false
        }
    }

    handleCancel = () => {
        this.setState({ prev_review: true })
    }

    nextQuestion = (current_question_index) => {
        if (this.state.allquestions[current_question_index]) {
            this.setState({ current_question_index: 0 })
            this.setState({ current_question_type: 'blank' })
            setTimeout(() => {
                this.setState({ current_question_index: current_question_index })
                this.setState({ current_question_type: this.state.allquestions[current_question_index].question_type })
                this.setState({ component_data: this.state.allquestions[current_question_index] })

                if (!this.state.allquestions[current_question_index + 1]) {
                    this.state.nextbutton = true
                }
                this.state.prevbutton = false
            }, 50);
        }
    }

    handleQuestion_detail() {
        if (this.state.lesson_id) {
            let lesson_id = JSON.stringify(
                {
                    lesson_id: this.state.lesson_id,
                    section_id: this.state.section_id
                });

                console.log("lesson_id",lesson_id);

            fetch(API + 'get_lesson.php',
                {
                    method: 'POST',
                    body: lesson_id
                }).then((response) => response.json()).then((responseJsonFromServer) => {

                    if (responseJsonFromServer.result == 'success') {

                        this.setState({
                            status: responseJsonFromServer.lesson_status,
                            lesson_name: responseJsonFromServer.lesson_name,
                            description: responseJsonFromServer.lesson_description,
                            img: responseJsonFromServer.lession_image,

                        })
                        if (responseJsonFromServer.question_data) {
                            this.setState({ allquestions: responseJsonFromServer.question_data })
                            this.setState({ 'current_question_type': this.state.allquestions[0].question_type, image: this.state.allquestions[0].course_image })
                            this.nextQuestion(this.state.current_question_index)
                        }
                    } else {
                        alert(responseJsonFromServer.msg)
                    }

                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }
    }

    componentDidMount() {
        this.handleQuestion_detail();
    }

    handleNext = () => {
        let nextQIndex = this.state.current_question_index + 1;
        if (this.state.allquestions[nextQIndex]) {
            this.setState({ 'current_question_index': 0 })
            this.setState({ 'current_question_type': 'blank' })
            setTimeout(() => {
                this.setState({ 'current_question_index': nextQIndex })
                this.setState({ 'current_question_type': this.state.allquestions[nextQIndex].question_type })
            }, 200);
            if (!this.state.allquestions[nextQIndex + 1]) {
                this.state.nextbutton = true
            }
            this.state.prevbutton = false
        }
    }

    handlePrev = () => {
        let prevQIndex = this.state.current_question_index - 1;
        if (this.state.allquestions[prevQIndex]) {
            this.setState({ 'current_question_index': 0 })
            this.setState({ 'current_question_type': 'balnk' })
            setTimeout(() => {
                this.setState({ 'current_question_index': prevQIndex })
                this.setState({ 'current_question_type': this.state.allquestions[prevQIndex].question_type })
            }, 200);
            if (!this.state.allquestions[prevQIndex - 1]) {
                this.state.prevbutton = true
            }
            this.state.nextbutton = false
        }
    }

    handleSubmit = () => {

        let errorCount = 0;
        if (this.state.lesson_name.trim().length < 2 || this.state.lesson_name.trim().length > 100) {
            if (this.state.lesson_name.trim().length === 0) {
                document.getElementsByClassName('error_lesson_title')[0].innerHTML = 'New lesson number is required.';
            }
            document.getElementsByClassName('error_lesson_title')[0].style.display = 'block';
            document.getElementById('lesson_title').classList.add('error')
            errorCount++;
        }
        if (this.state.description.trim().length < 2 || this.state.description.trim().length > 150) {
            if (this.state.description.trim().length === 0) {
                document.getElementsByClassName('error_lesson_description')[0].innerHTML = 'New lesson title is required.';
            }
            document.getElementsByClassName('error_lesson_description')[0].style.display = 'block';
            document.getElementById('lesson_description').classList.add('error')
            errorCount++;
        }

        if (errorCount == 0) {
            let lesson_error = false;
            if (this.state.lesson_id != '' && this.state.lesson_id != null) {
                let data = JSON.stringify(
                    {
                        lesson_id: this.state.lesson_id,
                        type: "lesson",
                        text: this.state.status
                    });



                fetch(API + 'published_data.php',
                    {
                        method: 'POST',

                        body: data

                    }).then((response) => response.json()).then((responseJsonFromServer) => {

                        if (responseJsonFromServer.result == "success") {
                            this.handleQuestion_detail();
                        } else {
                            this.setState({ errorPopup: true, error_msg: responseJsonFromServer.msg })
                            this.handleQuestion_detail();
                            lesson_error = true
                        }

                    }).catch((error) => {
                        if (error.message == 'Failed to fetch') {
                            this.setState({ networkError: true })
                        }
                    });
            }





            let flag = 'create';
            let lesson_id = '';
            let section_id = this.state.section_id;
            let course_id = this.state.course_id;

            if (this.state.lesson_id != '' && this.state.lesson_id != null) {
                flag = 'edit';
                lesson_id = this.state.lesson_id;
                section_id = section_id;
                course_id = course_id;
            }

            let lesson = new FormData()
            lesson.append('lesson_name', this.state.lesson_name);
            lesson.append('description', this.state.description);
            lesson.append('flag', flag);
            lesson.append('lesson_id', lesson_id);
            lesson.append('section_id', section_id);
            lesson.append('course_id', this.state.course_id);
            lesson.append('lesson_images', this.state.lesson_images);


            let data = JSON.stringify(
                {
                    lesson_name: this.state.lesson_name,
                    description: this.state.description,
                    flag: flag,
                    lesson_id: lesson_id,
                    section_id: section_id,
                    course_id: this.state.course_id,
                    lesson_images: this.state.lesson_images
                });
            fetch(API + 'create_lesson.php',
                {
                    method: 'POST',
                    body: lesson
                }).then((response) => response.json()).then((responseJsonFromServer) => {



                    if (responseJsonFromServer.result === 'success') {
                        if (this.state.errorPopup == false && lesson_error == false) {
                            var x = document.getElementById("snackbar");
                            x.className = "show";
                            setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                            x.innerHTML = responseJsonFromServer.msg;
                        }

                        this.setState({ lesson_id: responseJsonFromServer.lesson_id, lesson_name: responseJsonFromServer.lesson_name, description: responseJsonFromServer.description, lesson: responseJsonFromServer, saveButtonFlag: false })
                        this.handleQuestion_detail();
                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }
    }

    handleChangeImage(e) {
        if (e.target.files[0]) {
            var fileName = e.target.files[0].name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                if (e.target.files && e.target.files.length > 0) {
                    const reader = new FileReader();
                    reader.addEventListener('load', () =>
                        this.setState({ crop_image: reader.result, cropImagePopup: true, image_path: true, saveButtonFlag: true })
                    );
                    reader.readAsDataURL(e.target.files[0]);
                }
            } else {
                this.setState({ image_error_popup: true })
            }
        }
    }

    handleImageLoaded = (image) => {
        this.imageRef = image;
    }

    handleOnCropChange = (crop) => {
        this.setState({ crop });
    }

    handleOnCropCompleted = (crop, pixelCrop) => {
        this.makeClientCrop(crop);
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImageUrl = new File([u8arr], filename, { type: mime });
        this.setState({ croppedImageUrl })

    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                this.dataURLtoFile(reader.result, 'cropped.jpg')
            }
        })

        canvas.toBlob(blob => {
            if (!blob) {
                console.error('Canvas is empty');
                return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            this.setState({ set_image_path: this.fileUrl })
        });
    }

    handleImageCrop = (e) => {
        this.setState({ lesson_images: this.state.croppedImageUrl, cropImagePopup: false, img: this.state.set_image_path })
    }

    lessonStatus = (e) => {
        this.setState({ status: e.target.value, saveButtonFlag: true })
    }

    handleChangeLesson = (e) => {

        this.setState({ lesson_name: e.target.value, saveButtonFlag: true });

        if (e.target.value.trim() == "") {
            document.getElementsByClassName('error_lesson_title')[0].innerHTML = 'New lesson number is required.';
            document.getElementsByClassName('error_lesson_title')[0].style.display = 'block';
            e.target.classList.add('error');
            this.setState({ disable_save_button: true })
        } else if (e.target.value.trim().length < 2 || e.target.value.trim().length > 100) {
            this.setState({ disable_save_button: true })
            document.getElementsByClassName('error_lesson_title')[0].innerHTML = 'New lesson number must be between 2 to 100 character.';
            document.getElementsByClassName('error_lesson_title')[0].style.display = 'block';
            e.target.classList.add('error');
        } else {
            document.getElementsByClassName('error_lesson_title')[0].innerHTML = '';
            document.getElementsByClassName('error_lesson_title')[0].style.display = 'none';
            e.target.classList.remove('error');
            this.setState({ disable_save_button: false })
        }
    }

    handleChangeDescription = (e) => {

        if (e.target.value.trim() == "") {
            document.getElementsByClassName('error_lesson_description')[0].innerHTML = 'New lesson title is required.';
            document.getElementsByClassName('error_lesson_description')[0].style.display = 'block';
            e.target.classList.add('error');
            this.setState({ disable_save_button: true })
        } else if (e.target.value.trim().length < 2 || e.target.value.trim().length > 150) {
            document.getElementsByClassName('error_lesson_description')[0].innerHTML = 'New lesson title must be between 2 to 150 character.';
            document.getElementsByClassName('error_lesson_description')[0].style.display = 'block';
            e.target.classList.add('error');
            this.setState({ disable_save_button: true })
        } else {
            document.getElementsByClassName('error_lesson_description')[0].innerHTML = "";
            document.getElementsByClassName('error_lesson_description')[0].style.display = 'none';
            e.target.classList.remove('error');
            this.setState({ disable_save_button: false })
        }

        this.setState({ description: e.target.value, saveButtonFlag: true });
    }

    handlecontent() {
        this.props.history.push('/edit_content', { 'lesson_id': this.state.lesson_id, lesson: this.state.lesson, lesson_name: this.state.lesson_name, course_id: this.state.course_id, course: this.state.course, course_name: this.state.course_name, unit: this.state.unit, unit_name: this.state.unit_name, section: this.state.section, section_name: this.state.section_name })
    }

    handlesection() {
        this.props.history.push('/SectionScreen', { unit_id: this.state.unit_id, section: this.state.section, unit_name: this.state.unit_name, course_name: this.state.course_name, course: this.state.course, unit: this.state.unit, course_id: this.state.course_id, })
    }

    handleUnit() {
        this.props.history.push('/UnitScreen', { unit_name: this.state.unit_name, course_name: this.state.course_name, unit: this.state.unit, course: this.state.course, course_id: this.state.course_id, })
    }

    handleCourse() {
        this.props.history.push('/Create_Course', { course_name: this.state.course_name, course: this.state.course, course_id: this.state.course_id, })
    }

    addDefaultSrc(e) {
        e.target.src = require('../assets/click_image.png');
        e.target.onerror = null;
    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            return (
                <div style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                    <Router />
                    <div style={{ backgroundColor: '#fff', minHeight: '80vh' }}>
                        <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                            <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingTop: 60 }}>
                                <div className="row" style={{ paddingTop: 10, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
                                    <div className="col-md-12 col-sm-12 block-v-sm" style={{ display: 'flex' }}>
                                        <div><a href="CoursesScreen" style={styles.navigation}>Courses</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a onClick={() => this.handleCourse()} style={styles.navigation}>{this.props.location.state.course.course_name}</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a onClick={() => this.handleUnit()} style={styles.navigation}>{this.props.location.state.unit.unit_name}</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a onClick={() => this.handlesection()} style={styles.navigation}>{this.props.location.state.section.section_name}</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a style={styles.navigation}>{this.state.lesson_name ? this.state.lesson_name : 'Untitled Lesson'}</a></div><Icon size={20} icon={smallDown} />
                                    </div>
                                </div>
                            </div>
                            <hr style={{ marginTop: -1 }} />
                            <div className="form-container">
                                <div style={{ width: 250, height: 150, backgroundColor: '#8f9294' }} >
                                    <input
                                        style={{ display: 'none' }}
                                        ref={fileInput => this.fileInput = fileInput}
                                        className="upload"
                                        width={250}
                                        onError={this.addDefaultSrc}
                                        height={150}
                                        className="mr-3"
                                        src={this.state.img}
                                        alt="Generic placeholder"
                                        onChange={(e) => this.handleChangeImage(e)}
                                        type="file"
                                        accept="image/*"
                                    />
                                    <img
                                        className="upload"
                                        onClick={() => this.fileInput.click()}
                                        width={250}
                                        height={150}
                                        onError={this.addDefaultSrc}
                                        className="mr-3"
                                        src={this.state.img}
                                        alt="Generic placeholder"
                                    />

                                    <Modal show={this.state.cropImagePopup} onHide={() => { this.setState({ cropImagePopup: false }) }}>
                                        <ReactCrop
                                            style={{ margin: 30 }}
                                            src={this.state.crop_image}
                                            onError={this.addDefaultSrc}
                                            onClick={() => this.fileInput.click()}
                                            className="upload"
                                            crop={this.state.crop}
                                            onImageLoaded={this.handleImageLoaded}
                                            onComplete={this.handleOnCropCompleted}
                                            onChange={this.handleOnCropChange}
                                            alt="Generic placeholder"
                                        />
                                        <Button style={{ marginLeft: 30, marginRight: 30, marginBottom: 30, backgroundColor: '#46b4e9', borderColor: '#2fabe6', fontWeight: 'bold' }} onClick={this.handleImageCrop}>Crop</Button>
                                    </Modal>
                                </div>
                                <div className="input-field col-sm-12 col-lg-5 col-md-5">
                                    <input className="form-control" placeholder="New lesson number*" id="lesson_title" autoComplete="off" autoCorrect="off" maxLength={100} minLength={2} value={this.state.lesson_name} onChange={this.handleChangeLesson} style={styles.textinput} required />
                                    <label className="input-error error_lesson_title text-left"></label>
                                    <textarea className="form-control" placeholder="New lesson title*" id="lesson_description" autoComplete="off" autoCorrect="off" maxLength={150} minLength={2} value={this.state.description} onChange={this.handleChangeDescription} rows={2} style={styles.textinput} ></textarea>
                                    <label className="input-error error_lesson_description text-left"></label>
                                    <div className="heading-editor-publishable" style={{ display: this.state.lesson_id == '' || !this.state.lesson_id ? 'none' : 'block' }}>
                                        <strong style={{ fontSize: '88%' }} className="heading-editor-publishable--label">This lesson is&nbsp;&nbsp;&nbsp;</strong>
                                        <select onChange={this.lessonStatus} value={this.state.status} style={{ height: 30, fontSize: '88%', fontWeight: '600', borderRadius: 3 }}>
                                            <option value="Published" style={{ color: '#000000', backgroundColor: '#fff', borderColor: '#00000' }} >Published</option>
                                            <option value="Unpublished" style={{ color: '#000000', backgroundColor: '#fff', borderColor: '#00000' }} >Unpublished</option>
                                        </select>
                                    </div>
                                    <div className="form-submit-button">
                                        <Button onClick={this.handleSubmit} disabled={this.state.disable_save_button || !this.state.saveButtonFlag} variant="primary" size="lg" style={{ fontSize: '88%', width: 200, fontWeight: '600', backgroundColor: '#46b4e9', borderColor: '#2fabe6' }} block> Save </Button>
                                        <div id="snackbar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            {!this.state.lesson_id ? null :
                                <div className="row" style={{ backgroundColor: '#EEF2F6', minHeight: 550, paddingLeft: 10, }}>
                                    <div className="col-lg-7 col-md-12 col-sm-12" style={{
                                        backgroundColor: 'rgba(0,0,0,0.30)',
                                        height: 500,
                                        margin: 30,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundImage: this.state.image == API ? `url(${BackgroundColor})` : `url(${this.state.image})`,
                                        paddingLeft: 0,
                                        paddingRight: 0
                                    }} >
                                        <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 500, paddingLeft: 20, paddingRight: 20 }}>
                                            <div className="row text-center" style={{ paddingTop: 10, zIndex: 10, position: 'relative' }}>
                                                <div style={{ width: '10%', paddingLeft: 5 }}>
                                                    <img src={require('../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                                </div>
                                                <div style={{ paddingTop: 15, width: '60%' }}>
                                                    <hr color='#fff' />
                                                </div>
                                                <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                                    <div style={styles.dot_btn}>
                                                        <label style={{ color: '#fff', fontSize: '88%' }}>{this.state.current_question_index + 1} / {this.state.allquestions.length}</label>
                                                        <img onClick={this.handlePreview} src={require('../assets/Dot.png')} style={{ width: 30, height: 30, marginRight: -15, marginTop: -5 }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <Component_Loader_Edit_Lesson nextQuestion={this.nextQuestion} current_question_index={this.state.current_question_index} component_type={this.state.current_question_type} component_data={this.state.allquestions[this.state.current_question_index]} que_count={this.state.allquestions.length} />
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-12 col-sm-12" style={{
                                        backgroundColor: '#34baeb',
                                        backgroundImage: `url(${this.state.image})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        zIndex: 15,
                                        height: 500,
                                        marginTop: 30,
                                        marginBottom: 30,
                                        marginLeft: 40,
                                        marginRight: 30,
                                        visibility: this.state.prev_review ? "hidden" : "visible",
                                        position: 'absolute',
                                        paddingRight: 0,
                                        paddingLeft: 0
                                    }}>
                                        <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 500, paddingTop: 35, paddingLeft: 20, paddingRight: 20 }}>
                                            <div>
                                                <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                                    <div className="text-center">
                                                        <button onClick={this.handleReviewLastSlide} disabled={this.state.prevbutton} className="btn" style={styles.previewbutton}>
                                                            Review last slide
                                                </button>
                                                    </div>
                                                    <div className="text-center">
                                                        <button onClick={this.handleRestartLesson} disabled={this.state.prevbutton} className="btn" style={styles.previewbutton}>
                                                            Restart lesson
                                                </button>
                                                    </div>
                                                    <div className="text-center">
                                                        <button className="btn" style={styles.previewbutton}>
                                                            Exit lesson
                                                </button>
                                                    </div>
                                                    <div className="text-center">
                                                        <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>
                                                            Cancel
                                                </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 ml-3 mr-3">
                                        <Button variant="default" disabled={!this.state.lesson_id || this.state.allquestions.length == '0'} onClick={() => this.handlecontent()} style={styles.edit_btn} block><Icon icon={pencil} size={18} style={{ paddingRight: 5 }} /> Edit lesson content</Button>
                                        <div block="true" style={styles.slider_card}>
                                            <h6 className="text-center">
                                                <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                    <div className="col-2">
                                                        <Button onClick={() => this.handlePrev()} disabled={this.state.prevbutton} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 20 }}><Icon icon={smallLeft} size={20} /></Button>
                                                    </div>
                                                    <div className="col-3" style={{ paddingTop: 10 }}>
                                                        <span >Slide {this.state.current_question_index + 1}</span>
                                                    </div>
                                                    <div className="col-2">
                                                        <Button onClick={() => this.handleNext()} disabled={this.state.nextbutton} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 20 }}><Icon icon={smallRight} size={20} /></Button>
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <Modal size="sm" show={this.state.errorPopup} onHide={() => this.setState({ errorPopup: false })}>
                        <Modal.Body>{this.state.error_msg}</Modal.Body>
                        <Modal.Footer>
                            <Button style={styles.confirmButton} block onClick={() => this.setState({ errorPopup: false })}>Ok</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal size="sm" show={this.state.image_error_popup} onHide={() => this.setState({ image_error_popup: false })}>
                        <Modal.Body>Only images are accepted.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => this.setState({ image_error_popup: false })} block>Ok</Button>
                        </Modal.Footer>
                    </Modal>
                    <Footer />
                </div>
            )
        }
    }
}