import React from 'react';
import { Modal, ListGroup } from 'react-bootstrap';
import { Accordion, Button } from 'react-bootstrap'
import API from './Components/Global_API'
import Router from './Router';
import { Icon } from 'react-icons-kit'
import { trashO, plus, gear, lock } from 'react-icons-kit/fa'
import { smallRight, smallLeft } from 'react-icons-kit/entypo/smallRight'
import Footer from './Components/Footer';
import Assessment_Loader from './Assessment_Loader';
import Create_assessment_question from './Create_assessment_question';
import TitleScreen_assessment from './Assessment_functionality/TitleScreen_assessnemt';
import Free_text_assessment from './Assessment_functionality/Free_text_assessment';
import MultipleChoiceAssessment from './Assessment_functionality/Multiple_choice_assessment';
import ExitScreen_assessment from './Assessment_functionality/ExitScreen_assessment';
import Expandable_assessment from './Assessment_functionality/Expandable_assessment';
import QuestionPool_assessment from './Assessment_functionality/QuestionPool';
import NoInternet from './Components/NoInternet';

const styles = {
    body: {
        backgroundColor: '#34baeb',
    },
    navigation: {
        color: '#8f9294',
        fontSize: 18,
        cursor: 'pointer',
        fontWeight: '600'
    },
    header: {
        maxWidth: 300,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    radio: {
        borderWidth: 1,
        backgroundColor: '#fff',
        margin: 5,
        paddingLeft: 20,
        borderRadius: 5,
        fontSize: 15,
        paddingTop: 5
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    upload_button_label: {
        backgroundColor: '#fff',
        marginBottom: 5,
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    text: {
        fontWeight: '700',
        fontSize: 13
    },
    textbox: {
        height: 30
    },
    previewbutton: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        borderWidth: 1,
        padding: 20,
        borderColor: '#fff',
        color: '#fff',
        width: "100%",
    },
    slide: {
        width: '40%',
        height: 96,
        float: 'left',
        textAlign: 'center',
        margin: 4,
        background: '#f5f7fa',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'inherit',
        position: 'relative',
        borderRadius: 3
    },
}

export default class Assessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allquestions: [],
            current_question_type: '',
            current_index: 0,
            show: false,
            unit: '',
            course: '',
            component_data: [],
            unit: [],
            course: [],
            course_name: '',
            course_id: '',
            question_pool_data: [],
            question_pool_current_index: 0,
            alert: false,



            title: '',
            prompt: '',
            answer: '',
            done_text: '',
            pool_multiple_data: [],
            multiple_choice_id: '',
            question_pool_id: '',
            done_button_state: true,
        }

        this.loadComponent = this.loadComponent.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        if (this.props.location.state) {
            this.state.unit = this.props.location.state.unit
            this.state.course = this.props.location.state.course;
            this.state.course_id = this.props.location.state.course_id;
            this.state.course_name = this.props.location.state.course_name
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.question_pool_current_index !== this.state.question_pool_current_index) {
            //   this.handleUpdateName();
            this.setState({
                title: this.state.question_pool_data[this.state.question_pool_current_index].pool_question_title,
                prompt: this.state.question_pool_data[this.state.question_pool_current_index].prompt,
                done_text: this.state.question_pool_data[this.state.question_pool_current_index].done_text,
                pool_multiple_data: this.state.question_pool_data[this.state.question_pool_current_index].pool_multiple_choice_options,
                multiple_choice_id: this.state.question_pool_data[this.state.question_pool_current_index].pool_question_id,
                question_pool_id: this.state.question_pool_data[this.state.question_pool_current_index].pool_id,
                answer: this.state.question_pool_data[this.state.question_pool_current_index].answer
            })

        }
    }



    handleQuestionPoolData = (question_pool_data) => {
        this.setState({
            question_pool_data: question_pool_data,
            title: question_pool_data[this.state.question_pool_current_index].pool_question_title,
            prompt: question_pool_data[this.state.question_pool_current_index].prompt,
            done_text: question_pool_data[this.state.question_pool_current_index].done_text,
            pool_multiple_data: question_pool_data[this.state.question_pool_current_index].pool_multiple_choice_options,
            multiple_choice_id: question_pool_data[this.state.question_pool_current_index].pool_question_id,
            question_pool_id: question_pool_data[this.state.question_pool_current_index].pool_id,
            answer: question_pool_data[this.state.question_pool_current_index].answer
        })
    }

    handlePoolMultipleQuestion = () => {
        this.handleUpdateQuetionData();
    }

    poolComponent(index) {
        this.setState({ question_pool_current_index: 0 })
        setTimeout(() => {
            this.setState({ question_pool_current_index: index })
        }, 500);
    }





    handleClose() {
        this.setState({ show: false });
    }
    handleShow() {
        this.setState({ show: true });
    }

    handleShowTemplate = () => {
        this.handleAssessmentQuestionDetails();
        this.setState({
            show: false,
            current_index: this.state.allquestions.length - 1
        })
    }

    handleShowPopup(qItem, qIndex) {

        this.setState({
            alert: true,
            Item_for_delete: qItem,
            Item_index_for_delete: qIndex
        });
    }

    handleClosePopup = () => {
        this.setState({alert: false})
    }

    handleDeleteQuestion() {
        var qItem = this.state.Item_for_delete;

        let question = JSON.stringify(
            {
                question_id: qItem.question_id,
                unit_id: this.state.unit.unit_id,
                question_type: qItem.question_type
            });
        fetch(API + 'delete_assessment_question.php',
            {
                method: 'POST',

                body: question
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    this.setState({ alert: false, current_index: 0 });
                    setTimeout(() => {
                    this.handleAssessmentQuestionDetails();
                    }, 500);
                    x.innerHTML = responseJsonFromServer.msg;
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }
            }).catch((error) => {
                console.log(error.message);
            });
    }

    loadComponent(pagename, index) {
        this.setState({ current_index: 0 })
        this.setState({ current_question_type: 'blank' })
        setTimeout(() => {
            this.setState({ current_index: index })
            this.setState({ current_question_type: pagename.question_type })
        }, 100);

    }

    handleDoneButton = () => {
        if (this.state.question_pool_data.length - 3 != this.state.question_pool_current_index) {
            this.setState({ done_button_state: true, question_pool_current_index: this.state.question_pool_current_index + 1 })
        }
    }

    nextQuestion = (current_index) => {

        if (this.state.allquestions[current_index]) {
            this.setState({ current_index: 0 })
            this.setState({ current_question_type: 'blank' })
            setTimeout(() => {
                this.setState({ current_index: current_index })
                this.setState({ current_question_type: this.state.allquestions[current_index].question_type })
                this.setState({ component_data: this.state.allquestions[current_index] })
            }, 100);
        }
    }

    handleUpdateQuetionData = () => {
        this.handleAssessmentQuestionDetails();
        this.setState({
            current_index: this.state.current_index
        })
    }

    handleAssessmentQuestionDetails() {

        let assessment_Question = JSON.stringify({
            unit_id: this.state.unit.unit_id
        })

        fetch(API + 'get_assessement_questions.php',
            {
                method: 'POST',

                body: assessment_Question
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                if (responseJsonFromServer.result == 'success') {

                    const question_data = responseJsonFromServer.question_data.map((item, index) => {
                        item.id = String(index + 1)
                        return item
                    })

                    this.setState({ 'allquestions': question_data, 'current_question_type': question_data[0].question_type });
                    this.nextQuestion(this.state.current_index)
                }
            }).catch((error) => {
                if (error.message == 'Failed to fetch') {
                    this.setState({ networkError: true })
                }
            });
    }

    componentDidMount() {
        this.handleAssessmentQuestionDetails();
    }

    handleUnit() {
        this.props.history.push('/UnitScreen', { unit_name: this.state.unit_name, unit: this.state.unit, course_name: this.state.course_name, course: this.state.course, course_id: this.state.course_id })
    }

    handleCourse() {
        this.props.history.push('/Create_Course', { course_name: this.state.course_name, course: this.state.course, course_id: this.state.course_id })
    }

    handleAssessmentLoader = (param) => {

        if (this.state.current_question_type == 'start_lesson') {
            return (<TitleScreen_assessment handleUpdateQuetionData={this.handleUpdateQuetionData} que_count={this.state.allquestions.length} nextQuestion={this.nextQuestion} current_index={this.state.current_index} data={this.state.allquestions[this.state.current_index]} component_type={this.state.current_question_type} unit={this.props.location.state.unit} />);
        } else if (this.state.current_question_type == 'free_text') {
            return (<Free_text_assessment handleUpdateQuetionData={this.handleUpdateQuetionData} que_count={this.state.allquestions.length} nextQuestion={this.nextQuestion} current_index={this.state.current_index} data={this.state.allquestions[this.state.current_index]} component_type={this.state.current_question_type} unit={this.props.location.state.unit} />);
        } else if (this.state.current_question_type == 'ass_multiple_choice') {
            return (<MultipleChoiceAssessment handleUpdateQuetionData={this.handleUpdateQuetionData} que_count={this.state.allquestions.length} nextQuestion={this.nextQuestion} current_index={this.state.current_index} data={this.state.allquestions[this.state.current_index]} component_type={this.state.current_question_type} unit={this.props.location.state.unit} />);
        } else if (this.state.current_question_type == 'exit_lesson') {
            return (<ExitScreen_assessment handleUpdateQuetionData={this.handleUpdateQuetionData} que_count={this.state.allquestions.length} nextQuestion={this.nextQuestion} current_index={this.state.current_index} data={this.state.allquestions[this.state.current_index]} component_type={this.state.current_question_type} unit={this.props.location.state.unit} />);
        } else if (this.state.current_question_type == 'ass_expandable') {
            return (<Expandable_assessment handleUpdateQuetionData={this.handleUpdateQuetionData} que_count={this.state.allquestions.length} nextQuestion={this.nextQuestion} current_index={this.state.current_index} data={this.state.allquestions[this.state.current_index]} component_type={this.state.current_question_type} unit={this.props.location.state.unit} />);
        } else if (this.state.current_question_type == 'question_pool') {
            return (<QuestionPool_assessment handleUpdateQuetionData={this.handleUpdateQuetionData} que_count={this.state.allquestions.length} nextQuestion={this.nextQuestion} current_index={this.state.current_index} data={this.state.allquestions[this.state.current_index]} component_type={this.state.current_question_type} unit={this.props.location.state.unit} handleQuestionPoolData={this.handleQuestionPoolData} question_pool_current_index={this.state.question_pool_current_index} />);
        } else {
            return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><img src={require('../assets/Loader.gif')} /></div>)
        }

    }




    titleValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_title')[0].innerHTML = "Title is required.";
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_title')[0].innerHTML = 'Title must be between 2 to 250 character.';
            document.getElementsByClassName('error_title')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_title')[0].innerHTML = "";
            document.getElementsByClassName('error_title')[0].style.display = 'none';
        }
    }

    contentValidation = (i, e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_content' + i)[0].innerHTML = "Content is required.";
            document.getElementsByClassName('error_content' + i)[0].style.display = 'block';
            this.setState({ contentValidation: true })
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 250) {
            document.getElementsByClassName('error_content' + i)[0].innerHTML = 'Content must be between 2 to 250 character.';
            document.getElementsByClassName('error_content' + i)[0].style.display = 'block';
            this.setState({ contentValidation: true })
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_content' + i)[0].innerHTML = "";
            this.setState({ contentValidation: false })
            document.getElementsByClassName('error_content' + i)[0].style.display = 'none';
        }
    }

    promptValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = "Prompt is required.";
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 50) {
            document.getElementsByClassName('error_prompt')[0].innerHTML = 'Prompt must be between 2 to 50 character.';
            document.getElementsByClassName('error_prompt')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_prompt')[0].innerHTML = "";
            document.getElementsByClassName('error_prompt')[0].style.display = 'none';
        }
    }

    done_textValidation = (e) => {
        let targetId = e.target.id;
        let value = e.target.value;
        if (value.trim().length == 0) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = "Done text is required.";
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else if (value.trim().length < 2 || value.trim().length > 30) {
            document.getElementsByClassName('error_done_text')[0].innerHTML = 'Done text must be between 2 to 30 character.';
            document.getElementsByClassName('error_done_text')[0].style.display = 'block';
            e.target.classList.add('error');
            document.getElementById(targetId).focus();
        } else {
            e.target.classList.remove('error');
            document.getElementsByClassName('error_done_text')[0].innerHTML = "";
            document.getElementsByClassName('error_done_text')[0].style.display = 'none';
        }
    }

    handleChangeTitle = (e) => {
        this.setState({ title: e.target.value })
    }

    handleChangeContent = async (i, e) => {

        let value = e.target.value;
        let users = this.state.pool_multiple_data;
        users.map((item, index) => {
            if (index == i) {
                item.options = value;

            }
        })
        await this.setState({ pool_multiple_data: users });
    }

    handleChangePrompt = (e) => {
        this.setState({ prompt: e.target.value });
    }

    handleChangeAnswer = (e) => {
        this.setState({ answer: e.target.value })
    }

    handleChangeDoneText = (e) => {
        this.setState({ done_text: e.target.value })
    }

    DisableButtonFunction = (e) => {
        this.setState({ done_button_state: false })
    }

    Add() {
        let add = this.state.pool_multiple_data;
        if (this.state.pool_multiple_data.length < 6) {
            let newu = { options: 'size' }

            add.push(newu)
            this.setState({
                pool_multiple_data: add,
            })
        }
    }

    handleSectionStatusPopupOpen(item) {
        this.setState({
            PublishedSectionPopup: true,
            changeSectionStatus: item
        });
    }

    handlePublishedSectionPopup = () => {
        this.setState({ PublishedSectionPopup: false });
    }

    Remove = async () => {
        var i = this.state.changeSectionStatus;
        let users = this.state.pool_multiple_data;
        if (this.state.pool_multiple_data.length > 2) {
            users.splice(i, 1);
            await this.setState({ pool_multiple_data: users, PublishedSectionPopup: false });
        }
    }

    updateQuestionPoolData = () => {
        let data = this.state.question_pool_data.filter(item => {
            if (item.pool_question_id == this.state.multiple_choice_id) {
                return (
                    item.answer = this.state.answer,
                    item.done_text = this.state.done_text,
                    item.pool_id = item.pool_id,
                    item.pool_multiple_choice_options = item.pool_multiple_choice_options,
                    item.pool_question_id = item.pool_question_id,
                    item.pool_question_title = this.state.title,
                    item.prompt = this.state.prompt
                )
            } else {
                return (
                    item.answer = item.answer,
                    item.done_text = item.done_text,
                    item.pool_id = item.pool_id,
                    item.pool_multiple_choice_options = item.pool_multiple_choice_options,
                    item.pool_question_id = item.pool_question_id,
                    item.pool_question_title = item.pool_question_title,
                    item.prompt = item.prompt
                )
            }
        })
        
        this.setState({ question_pool_data: data })
    }


    handleSubmit = () => {

        if (this.state.title.trim().length < 2 || this.state.title.trim().length > 250) {
            document.getElementById("title").focus();
        } else if (this.state.contentValidation) {

        } else if (this.state.prompt.trim().length < 2 || this.state.prompt.trim().length > 50) {
            document.getElementById("prompt").focus();
        } else if (this.state.done_text.trim().length < 2 || this.state.done_text.trim().length > 30) {
            document.getElementById("done_text").focus();
        } else {

            let multiple_data = []

            this.state.pool_multiple_data.map(item => {
                multiple_data.push({ options: item.options })
            })

            let data = JSON.stringify({
                title: this.state.title,
                done_text: this.state.done_text,
                prompt: this.state.prompt,
                multiple_choice_id: this.state.multiple_choice_id,
                unit_id: this.state.unit.unit_id,
                options_data: multiple_data,
                question_pool_id: this.state.question_pool_id,
                answer: this.state.answer
            })


            fetch(API + 'edit_question_pool.php',
                {
                    method: 'POST',

                    body: data
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    if (responseJsonFromServer.result === 'success') {
                        x.innerHTML = responseJsonFromServer.msg;
                        this.updateQuestionPoolData();
                    } else {
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }
    }


    handlePooldata = () => {
        return (<div className="row text-center" >
            <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="card card-sm">
                    <div className="card-content block-xl"  >
                        <div className="body-container" style={styles.body}>
                            <div className="container-fluid" style={{ height: 550, padding: 0, backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${this.state.course_image})` }}>
                                <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 550, paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
                                    <div className="row text-center">
                                        <div style={{ width: '10%', paddingLeft: 5 }}>
                                            <img src={require('../assets/pta-logo2.png')} style={{ width: 30, height: 30 }} />
                                        </div>
                                        <div style={{ paddingTop: 15, width: '60%' }}>
                                            <hr color="#fff" />
                                        </div>
                                        <div style={{ width: '30%', paddingLeft: 5, paddingRight: 5 }}>
                                            <div style={styles.dot_btn}>
                                                <label style={{ color: '#fff', fontSize: 15, padding: 4 }}>{this.state.question_pool_current_index + 1} / {this.state.question_pool_data.length}</label>
                                                <img onClick={this.handlePreview} src={require('../assets/Dot.png')} style={{ width: 25, height: 25, marginTop: -3, marginLeft: 10, cursor: 'pointer' }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ height: 380, paddingTop: 35, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

                                        <div>
                                            <h5 style={{ color: '#fff', paddingBottom: 10 }}> {this.state.question_pool_data[this.state.question_pool_current_index].pool_question_title}</h5>
                                            {this.state.question_pool_data[this.state.question_pool_current_index].pool_multiple_choice_options.map((item, i) => (
                                                <div style={styles.radio} className="text-left" key={item.id} >
                                                    <label style={{ width: '100%' }}>
                                                        <input type="radio" name="option" value={item.options} onClick={this.DisableButtonFunction} />&nbsp;
                                                        {item.options}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div>
                                        <hr color='#fff' />
                                        <div className="text-center">
                                            <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleDoneButton() }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                                                {this.state.done_button_state == true ? this.state.question_pool_data[this.state.question_pool_current_index].prompt : this.state.question_pool_data[this.state.question_pool_current_index].done_text}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* <div style={{ backgroundColor: '#34baeb', backgroundImage: `url(${this.state.course_image})`, backgroundPosition: 'center', backgroundSize: 'cover', zIndex: 15, height: 550, width: 363, top: -30, left: -30, margin: 30, visibility: this.state.prev_review ? "hidden" : "visible", position: 'absolute', paddingTop: 40 }}>
                            <div>
                                <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 65 }}>
                                    <div className="text-center">
                                        <button onClick={this.handleReviewLastSlide} className="btn" style={styles.previewbutton}>Review last slide</button>
                                    </div>
                                    <div className="text-center">
                                        <button onClick={this.handleRestartLesson} className="btn" style={styles.previewbutton}>Restart lesson</button>
                                    </div>
                                    <div className="text-center">
                                        <button onClick={this.handleCancel} className="btn" style={styles.previewbutton}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row col-12" style={{ paddingTop: 10, backgroundColor: '#fff', marginLeft: 0.1 }}>
                        {/* <div className="col-4">
                    <Button variant="default" onClick={() => { this.handlePrevButton(this.props.current_index - 1) }} className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallLeft} size={20} /></Button>
                </div>
                <div className="col-4 text-center" style={{ paddingTop: 10 }}>
                    <span >Slide {this.props.current_index + 1}</span>
                </div>
                <div className="col-4">
                    <Button onClick={() => { this.handleDoneButton(this.props.current_index + 1) }} variant="default" className="btn btn-light" inline-block="true" style={{ marginBottom: 10 }}><Icon icon={smallRight} size={20} /></Button>
                </div> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="card-sm" >
                    <div className="card-content block-xl" style={{ height: 550, overflowY: 'auto', overflowX: 'hidden' }}>
                        <h5 className="text-left" style={{ paddingBottom: 10 }}>Question Pool</h5>
                        <div className="text-left">
                            <div className="form-group">
                                <div className="input-group-prepend" style={styles.textbox}>
                                    <span className="input-group-text" style={styles.text}>Title*</span>
                                </div>
                                <input type="text" className="form-control" id="title" value={this.state.title} minLength={2} maxLength={250} onChange={this.handleChangeTitle} onBlur={this.titleValidation} />
                                <label className="input-error error_title"></label>
                            </div>
                            <hr />
                            <div>
                                <Accordion style={{ textAlign: 'left' }} defaultActiveKey="0">
                                    <div>
                                        <div eventKey="0">
                                            <h5>Answers</h5>
                                        </div>
                                        <Accordion.Collapse eventKey="0">
                                            <div>
                                                {this.state.pool_multiple_data.map((item, i) => (
                                                    <div key={i} style={{ padding: 10, marginBottom: 10, backgroundColor: '#fff' }}>
                                                        <div className="form-group">
                                                            <div className="input-group-prepend" style={styles.textbox}>
                                                                <span className="input-group-text" style={styles.text}>Content*</span>
                                                            </div>
                                                            <input type="text" className="form-control" id={"content" + i} minLength={2} maxLength={250} value={item.options} onChange={this.handleChangeContent.bind(this, i)} onBlur={this.contentValidation.bind(this, i)} />
                                                            <label className={"input-error error_content" + i}></label>
                                                        </div>
                                                        <div>
                                                            <Button variant="default" style={{ backgroundColor: '#E6EBF0' }} block onClick={this.handleSectionStatusPopupOpen.bind(this, i)}>
                                                                <Icon size={20} icon={trashO} style={{ paddingRight: 10 }} />
                                                                Remove this section
                                                            </Button>
                                                        </div>
                                                    </div>
                                                ))}

                                                <Modal size="sm" show={this.state.PublishedSectionPopup} onHide={this.handlePublishedSectionPopup}>
                                                    <Modal.Body>Are you sure you want to remove this?</Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={this.handlePublishedSectionPopup}>No</Button>
                                                        <Button style={styles.confirmButton} onClick={() => this.Remove()}>Yes</Button>
                                                    </Modal.Footer>
                                                </Modal>

                                                <div style={{ marginTop: 10, color: '#fff' }}>
                                                    <Button variant="default" onClick={this.Add.bind(this)} style={{ backgroundColor: '#223042', color: '#fff' }} block >
                                                        <Icon size={20} icon={plus} style={{ paddingRight: 10, color: '#fff' }} />
                                                        Add a section
                                                    </Button>
                                                </div>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                </Accordion>
                            </div>
                            <hr />
                            <div className="form-group">
                                <div className="input-group-prepend" style={styles.textbox}>
                                    <span className="input-group-text" style={styles.text}>Answer*</span>
                                </div>
                                {/* <input type="text" className="form-control" id="prompt" value={this.state.answer} minLength={2} maxLength={50} onChange={this.handleChangeAnswer} /> */}
                                <select className="form-control" id="answer" value={this.state.answer} onChange={this.handleChangeAnswer} >
                                    {this.state.pool_multiple_data.map((item, i) =>
                                        <option value={item.options}>{item.options}</option>
                                    )}
                                </select>
                                <label className="input-error error_answer"></label>
                            </div>
                            <div className="form-group">
                                <div className="input-group-prepend" style={styles.textbox}>
                                    <span className="input-group-text" style={styles.text}>Prompt*</span>
                                </div>
                                <input type="text" className="form-control" id="prompt" value={this.state.prompt} minLength={2} maxLength={50} onChange={this.handleChangePrompt} onBlur={this.promptValidation} />
                                <label className="input-error error_prompt"></label>
                            </div>
                            <div className="form-group">
                                <div className="input-group-prepend" style={styles.textbox}>
                                    <span className="input-group-text" style={styles.text}>Done text*</span>
                                </div>
                                <input type="text" className="form-control" id="done_text" value={this.state.done_text} minLength={2} maxLength={30} onChange={this.handleChangeDoneText} onBlur={this.done_textValidation} />
                                <label className="input-error error_done_text"></label>
                            </div>
                            <hr />
                        </div>
                    </div>
                    <div className="text-left " style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <button onClick={this.handleSubmit} className="btn btn-dark" style={{ width: 150 }}>Save</button>
                        
                    </div>
                </div>
            </div>

        </div>)
    }



    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            if (this.state.allquestions.length > 0) {
                return (
                    <div style={{ minHeight: '100vh', backgroundColor: '#EEF2F6', overflowX: 'hidden' }}>
                        <Router />
                        <div>
                            <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingTop: 60, minHeight: '10vh' }}>
                                <div className="row" style={{ paddingLeft: 20, paddingTop: 25, paddingBottom: 15 }}>
                                    <div className="col-lg-12 col-md-12 col-sm-12 block-v-sm" style={{ display: 'flex' }}>
                                        <div><a href="CoursesScreen" style={styles.navigation}>Courses</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a style={styles.navigation} onClick={() => this.handleCourse()}>{this.state.course.course_name}</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a onClick={() => this.handleUnit()} style={styles.navigation}>{this.state.unit.unit_name}</a></div>  <Icon size={20} icon={smallRight} /> <div style={styles.header}><a style={styles.navigation}> Assessment content </a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="body-container" style={{ paddingBottom: 20, minHeight: '67vh' }}>
                                <div className="container-fluid" >
                                    <div className="row text-center">
                                        <div className="col-lg-9 col-md-12 col-sm-12">
                                            <div className="card-sm" style={{ marginTop: 15 }}>
                                                <div className="card-content block-xl" style={{ minHeight: 600 }}>
                                                    {this.state.question_pool_data.length == 0 ?
                                                        this.handleAssessmentLoader(this.state.allquestions[this.state.current_index].question_id) :
                                                        this.handlePooldata()
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-5 col-sm-12" style={{ marginTop: 15 }}>
                                            <div className="card card-sm">
                                                <div className="card-content block-xl" style={{ height: 550, overflowY: 'auto', backgroundColor: '#223042' }}>
                                                    {this.state.question_pool_data.length == 0 ?

                                                        this.state.allquestions.map((qItem, qIndex) => (
                                                            <ListGroup key={qIndex} style={{ paddingBottom: 10 }}>
                                                                <ListGroup.Item style={{ backgroundColor: '#223042', marginBottom: -15, cursor: 'pointer' }}>
                                                                    <div className="row" onClick={this.loadComponent.bind(this, qItem, qIndex)}>
                                                                        <div className="col-10" >
                                                                            <p className="text-left" style={{ fontWeight: '600', marginBottom: -1, paddingBottom: 5, fontSize: 16, color: '#6f7d8e' }}>{qIndex + 1 + '. ' + (qItem.question_type == 'ass_multiple_choice' ? 'multiple_choice' : qItem.question_type).toUpperCase()}</p>
                                                                            <p className="text-left" style={{ fontSize: 16, lineHeight: 1.3, fontWeight: '500', color: this.state.current_index == qIndex ? '#fff' : '#6f7d8e' }} >{qItem.title}</p>
                                                                        </div>
                                                                        <div className="col-1" >
                                                                            {qItem.delete_flag == "true" ? <Icon size={20} icon={gear} onClick={this.handleShow} className="btn" style={{ color: '#c1c4c9', padding: 0, position: 'relative', zIndex: 4 }} /> : <Icon size={20} icon={lock} onClick={this.handleShow} className="btn" style={{ color: '#c1c4c9', padding: 0, position: 'relative', zIndex: 4 }} />}
                                                                            {qItem.delete_flag == "true" ? <Icon size={20} className="btn" onClick={() => this.handleShowPopup(qItem, qIndex)} icon={trashO} style={{ color: '#c1c4c9', padding: 0, position: 'relative', zIndex: 4 }} /> : null}
                                                                        </div>
                                                                    </div>
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                        ))
                                                        : <>
                                                            <ListGroup style={{ paddingBottom: 10 }}>
                                                                <ListGroup.Item style={{ backgroundColor: '#223042', marginBottom: -15, cursor: 'pointer' }}>
                                                                    <div className="row" onClick={() => { this.setState({ question_pool_data: [] }); this.handlePoolMultipleQuestion() }}>
                                                                        <div className="col-10 text-left" >
                                                                            <svg data-testid="icon" className="IconSvg-sc-1muoj69 ggegJJ Icon-sc-1v2yq3v eQdTco" height="16" width="16" viewBox="0 0 24 24"><path d="M12,2 C6.47715,2 2,6.47715 2,12 C2,17.52285 6.47715,22 12,22 C17.52285,22 22,17.52285 22,12 C22,6.47715 17.52285,2 12,2 M13.2,9.6 L10.8,9.6 C10.5791,9.6 10.4,9.4209 10.4,9.2 L10.4,6.8 C10.4,6.5791 10.5791,6.4 10.8,6.4 L13.2,6.4 C13.4209,6.4 13.6,6.5791 13.6,6.8 L13.6,9.2 C13.6,9.4209 13.4209,9.6 13.2,9.6 M13.2,17.6 L10.8,17.6 C10.5791,17.6 10.4,17.4209 10.4,17.2 L10.4,11.6 C10.4,11.3791 10.5791,11.2 10.8,11.2 L13.2,11.2 C13.4209,11.2 13.6,11.3791 13.6,11.6 L13.6,17.2 C13.6,17.4209 13.4209,17.6 13.2,17.6" color="navyMuted" fill="#8E96A1" className="IconPath-sc-tqke8q kJxHFv"></path></svg>
                                                                            <span style={{ color: "rgb(142, 150, 161)", fontSize: 13, margin: 0 }} >You are currently editing slides within a question pool. Click here once you’re ready to return to your lesson.</span>
                                                                        </div>
                                                                    </div>
                                                                </ListGroup.Item>
                                                            </ListGroup>
                                                            {this.state.question_pool_data.map((qItem, qIndex) => (
                                                                <ListGroup style={{ paddingBottom: 10 }}>
                                                                    <ListGroup.Item style={{ backgroundColor: '#223042', marginBottom: -15, cursor: 'pointer' }}>
                                                                        <div className="row" onClick={this.poolComponent.bind(this, qIndex)}>
                                                                            <div className="col-10" >
                                                                                <p className="text-left" style={{ fontWeight: '600', marginBottom: -1, paddingBottom: 5, fontSize: 16, color: '#6f7d8e' }}>{qIndex + 1 + '. ' + ('multiple choice').toUpperCase()}</p>
                                                                                <p className="text-left" style={{ fontSize: 16, lineHeight: 1.3, fontWeight: '500', color: this.state.question_pool_current_index == qIndex ? '#fff' : '#6f7d8e' }} >{qItem.pool_question_title}</p>
                                                                            </div>
                                                                        </div>
                                                                    </ListGroup.Item>
                                                                </ListGroup>
                                                            ))}
                                                        </>}
                                                </div>
                                            </div>
                                            <button className="btn btn-dark" style={{ width: '100%', marginTop: 10 }} onClick={this.handleShow}>+ Add a new slide</button>
                                            <Modal size="lg" style={{ height: '100%', borderRadius: 6 }} show={this.state.show} onHide={this.handleClose}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Choose a template</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body style={{ height: 700, overflowY: 'auto', backgroundColor: '#EEF2F6', margin: 5 }}>
                                                    <Create_assessment_question unit={this.state.unit} handleShowTemplate={this.handleShowTemplate} />
                                                </Modal.Body>
                                            </Modal>
                                            <Modal size="sm" show={this.state.alert} onHide={this.handleClosePopup}>
                                                <Modal.Body>Are you sure you want to delete this question?</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={this.handleClosePopup}>No</Button>
                                                    <Button variant="primary" onClick={() => this.handleDeleteQuestion()}>Yes</Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                            <div id="snackbar"></div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <Router />
                        <div style={{ backgroundColor: '#EEF2F6', minHeight: '100vh' }}>
                            <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingTop: 60 }}>
                                <div className="row" style={{ paddingLeft: 20, paddingTop: 25, paddingBottom: 15 }}>
                                    <div className="col-lg-12 col-md-12 col-sm-12 block-v-sm">
                                        <a href="CoursesScreen" style={styles.navigation}>Courses</a> <Icon size={20} icon={smallRight} /> <a style={styles.navigation} onClick={() => this.handleCourse()}>{this.state.course.course_name}</a> <Icon size={20} icon={smallRight} /> <a onClick={() => this.handleUnit()} style={styles.navigation}>{this.state.unit.unit_name}</a>  <Icon size={20} icon={smallRight} /> <a style={styles.navigation}> Assessment content </a>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }} >
                                <img src={require('../assets/Loader.gif')} />
                            </div>
                            <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
                                <Footer />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
}