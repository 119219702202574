import React from 'react';
import Router from './Router';
import { Icon } from 'react-icons-kit'
import { smallDown } from 'react-icons-kit/entypo/smallDown'
import { smallRight } from 'react-icons-kit/entypo/smallRight'
import { trashO, plus } from 'react-icons-kit/fa'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import { Button, InputGroup, Modal } from 'react-bootstrap'
import Footer from './Components/Footer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import API from './Components/Global_API'
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import NoInternet from './Components/NoInternet';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    borderRadius: 3,
    display: 'flex',
    flexWrap: 'wrap'
});

const styles = {
    title: {
        textAlign: 'center'
    },
    textinput: {
        resize: "none",
        // resize: "auto",
        marginBottom: 5,
        fontSize: 20,
        overflow: 'auto'
        // border: 'none'
    },
    textinput_course: {
        resize: "none",
        marginBottom: 5,
        fontSize: 25,
        // border: 'none'
    },
    saveButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 20
    },
    navigation: {
        color: '#8f9294',
        fontSize: 18,
        cursor: 'pointer',
        fontWeight: '600'
    },
    confirmButton: {
        backgroundColor: 'rgb(70, 180, 233)',
        borderColor: 'rgb(70, 180, 233)'
    },
    header: {
        maxWidth: 300,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}

export default class Create_Course extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            course_name: '',
            course_description: '',
            img: '',
            course_id: '',
            Unit: [],
            course: '',
            noUnitsFound: false,
            from_date: new Date(),
            to_date: new Date(),
            due_date: new Date(),
            from_date_toSend: '',
            to_date_toSend: '',
            due_date_toSend: '',
            status: 'Unpublished',
            shownicon: false,
            published: false,
            Item_for_delete: '',
            message: '',
            disable_save_button: true,
            image_path: false,
            saveButtonFlag: false,
            fromDateValidation: true,
            toDateValidation: true,
            changeUnitStatus: '',
            PublishedUnitPopup: false,
            cropImagePopup: false,
            crop_image: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1 / 1,
            },
            course_image: '',
            error_msg: '',
            errorPopup: false,
            image_error_popup: false
        };

        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            window.location.href = '/';
        } else {
            if (this.props.location.state) {
                let cid = this.props.location.state.course ? this.props.location.state.course.course_id :'';
                let cname = this.props.location.state.course && this.props.location.state.course.course_name;
                let cdesc = this.props.location.state.course && this.props.location.state.course.description;
                let cimg = this.props.location.state.course ? this.props.location.state.course.image :'';
                this.state.course = this.props.location.state.course && this.props.location.state.course;
                this.state.status = this.props.location.state.course && this.props.location.state.course.status;
                this.state.course_id = cid;
                this.state.course_name = cname;
                this.state.course_description = cdesc;
                this.state.img = cimg;
            }
            if (this.state.course_name == "") {
                this.state.disable_save_button = true
            } else {
                this.state.disable_save_button = false
            }
        }
    }

    unitDetail() {
        if (this.state.course_id) {

            let course_id = JSON.stringify(
                {
                    course_id: this.state.course_id
                });

            fetch(API + 'get_course_details.php',
                {
                    method: 'POST',
                    body: course_id
                }).then((response) => response.json()).then((responseJsonFromServer) => {

                    if (responseJsonFromServer.result == "success") {
                        this.setState({
                            status: responseJsonFromServer.course_status,
                            course_name: responseJsonFromServer.course_name,
                            description: responseJsonFromServer.description,
                            img: responseJsonFromServer.image
                        })

                        if (responseJsonFromServer.unit_data) {

                            let unit_data = responseJsonFromServer.unit_data
                            this.setState({ Unit: unit_data, from_date: moment(responseJsonFromServer.fromdate).toDate(), to_date: moment(responseJsonFromServer.todate).toDate(), due_date: moment(responseJsonFromServer.duedate).toDate() })
                        } else {
                            this.setState({ Unit: [] })
                            this.setState({ noUnitsFound: true })
                        }
                    } else {
                        alert(responseJsonFromServer.msg);
                    }
                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }
    }

    componentDidMount() {
        this.unitDetail()
    }

    handleSubmit = () => {
        let errorCount = 0;
        if (this.state.course_name.trim().length < 2 || this.state.course_name.trim().length > 100) {
            if (this.state.course_name.trim().length == 0) {
                document.getElementsByClassName('error_course_title')[0].innerHTML = 'New qualification level is required.';
            }
            document.getElementsByClassName('error_course_title')[0].style.display = 'block';
            document.getElementById('course_title').classList.add('error')
            errorCount++;
        }
        if (this.state.course_description.trim().length < 2 || this.state.course_description.trim().length > 150) {
            if (this.state.course_description.trim().length == 0) {
                document.getElementsByClassName('error_course_description')[0].innerHTML = 'New qualification title is required.';
            }
            document.getElementsByClassName('error_course_description')[0].style.display = 'block';
            document.getElementById('course_description').classList.add('error')
            errorCount++;
        }
        if (this.state.from_date == '' || this.state.from_date == null) {
            document.getElementsByClassName('error_from_date')[0].innerHTML = 'From date is required.';
            document.getElementsByClassName('error_from_date')[0].style.display = 'block';
            this.setState({ fromDateValidation: false })
        }

        if (this.state.to_date == '' || this.state.to_date == null) {
            document.getElementsByClassName('error_to_date')[0].innerHTML = 'To date is required.';
            document.getElementsByClassName('error_to_date')[0].style.display = 'block';
            this.setState({ fromDateValidation: false })
        }

        if (errorCount == 0) {

            let course_error = false;

            if (this.state.course_id != '') {
                let status = JSON.stringify(
                    {
                        course_id: this.state.course_id,
                        type: "course",
                        text: this.state.status
                    });

                fetch(API + 'published_data.php',
                    {
                        method: 'POST',

                        body: status

                    }).then((response) => response.json()).then((responseJsonFromServer) => {

                        if (responseJsonFromServer.result == "success") {
                            this.SectionDetails()
                            // var x = document.getElementById("snackbar");
                            // x.className = "show";
                            // setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                            // x.innerHTML = responseJsonFromServer.msg;

                        } else {
                            this.setState({ errorPopup: true, error_msg: responseJsonFromServer.msg })
                            this.SectionDetails()
                            course_error = true;
                        }

                    }).catch((error) => {
                        if (error.message == 'Failed to fetch') {
                            this.setState({ networkError: true })
                        }
                    });
            }



            let flag = 'create';
            let course_id = '';
            let course_images = this.state.course_image;
            if (this.state.course_id != '') {
                flag = 'edit';
                course_id = this.state.course_id;
                if (this.state.image_path) {
                    course_images = this.state.course_image;
                } else {
                    course_images = ''
                }
            }

            let course = new FormData()
            course.append('course_name', this.state.course_name);
            course.append('course_description', this.state.course_description);
            course.append('course_images', course_images);
            course.append('flag', flag);
            course.append('course_id', course_id);
            course.append('status', this.state.status);
            course.append('fromdate', this.state.from_date.toLocaleDateString("en-US"));
            course.append('todate', this.state.to_date.toLocaleDateString("en-US"));
            course.append('duedate', this.state.due_date.toLocaleDateString("en-US"));

            fetch(API + 'create_course.php',
                {
                    method: 'POST',

                    body: course
                }).then((response) => response.json()).then((responseJsonFromServer) => {

                    if (responseJsonFromServer.result === 'success') {
                        if (this.state.errorPopup == false && course_error == false) {
                            var x = document.getElementById("snackbar");
                            x.className = "show";
                            setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                            x.innerHTML = responseJsonFromServer.msg;
                        }
                        this.setState({ course_name: responseJsonFromServer.course_name, course_description: responseJsonFromServer.description, course_id: responseJsonFromServer.course_id, course: responseJsonFromServer, saveButtonFlag: false });
                        this.unitDetail();
                    } else {
                        var x = document.getElementById("snackbar");
                        x.className = "show";
                        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                        x.innerHTML = responseJsonFromServer.msg;
                    }
                }).catch((error) => {
                    console.log(error.message);
                });
        }
    }

    handleChangeCourse = (e) => {
        this.setState({ course_name: e.target.value, saveButtonFlag: true });
        if (e.target.value.trim() == "") {
            this.setState({ disable_save_button: true })
            document.getElementsByClassName('error_course_title')[0].innerHTML = 'New qulification level is required.';
            document.getElementsByClassName('error_course_title')[0].style.display = 'block';
            e.target.classList.add('error');
        } else if (e.target.value.trim().length < 2 || e.target.value.trim().length > 100) {
            this.setState({ disable_save_button: true })
            document.getElementsByClassName('error_course_title')[0].innerHTML = 'New qulification level must be between 2 to 100 character.';
            document.getElementsByClassName('error_course_title')[0].style.display = 'block';
            e.target.classList.add('error');
        } else {
            document.getElementsByClassName('error_course_title')[0].innerHTML = "";
            document.getElementsByClassName('error_course_title')[0].style.display = 'none';
            e.target.classList.remove('error');
            this.setState({ disable_save_button: false })
        }
    }

    handleChangeDescription = (e) => {
        if (e.target.value.trim() == '') {
            this.setState({ disable_save_button: true })
            document.getElementsByClassName('error_course_description')[0].innerHTML = 'New qulification title must be between 2 to 150 character.';
            document.getElementsByClassName('error_course_description')[0].style.display = 'block';
            e.target.classList.add('error');
        } else if (e.target.value.trim().length < 2 || e.target.value.trim().length > 150) {
            this.setState({ disable_save_button: true })
            document.getElementsByClassName('error_course_description')[0].innerHTML = 'New qulification title must be between 2 to 150 character.';
            document.getElementsByClassName('error_course_description')[0].style.display = 'block';
            e.target.classList.add('error');
        } else {
            document.getElementsByClassName('error_course_description')[0].innerHTML = "";
            document.getElementsByClassName('error_course_description')[0].style.display = 'none';
            e.target.classList.remove('error');
            this.setState({ disable_save_button: false })
        }
        this.setState({ course_description: e.target.value, saveButtonFlag: true });
    }

    handleFromDate = (date) => {
        if (date == null || date == '') {
            document.getElementsByClassName('error_from_date')[0].innerHTML = 'From date is required.';
            document.getElementsByClassName('error_from_date')[0].style.display = 'block';
            this.setState({ fromDateValidation: false })
        } else {
            document.getElementsByClassName('error_from_date')[0].innerHTML = "";
            document.getElementsByClassName('error_from_date')[0].style.display = 'none';
            this.setState({ fromDateValidation: true })
        }
        this.setState({ from_date: date, saveButtonFlag: true });
    }

    handleToDate = (date) => {
        if (date == null || date == '') {
            document.getElementsByClassName('error_to_date')[0].innerHTML = 'To date is required.';
            document.getElementsByClassName('error_to_date')[0].style.display = 'block';
            this.setState({ toDateValidation: false })
        } else {
            document.getElementsByClassName('error_to_date')[0].innerHTML = "";
            document.getElementsByClassName('error_to_date')[0].style.display = 'none';
            this.setState({ toDateValidation: true })
        }
        this.setState({ to_date: date, saveButtonFlag: true });
    }

    handleChangeImage(e) {
        if (e.target.files[0]) {
            var fileName = e.target.files[0].name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                if (e.target.files && e.target.files.length > 0) {
                    const reader = new FileReader();
                    reader.addEventListener('load', () =>
                        this.setState({ crop_image: reader.result, cropImagePopup: true, image_path: true, saveButtonFlag: true })
                    );
                    reader.readAsDataURL(e.target.files[0]);
                }
            } else {
                this.setState({ image_error_popup: true })
            }
        }

    }

    handleImageLoaded = (image) => {
        this.imageRef = image;
    }

    handleOnCropChange = (crop) => {
        this.setState({ crop });
    }

    handleOnCropCompleted = (crop, pixelCrop) => {
        this.makeClientCrop(crop);
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImageUrl = new File([u8arr], filename, { type: mime });
        this.setState({ croppedImageUrl })

    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                this.dataURLtoFile(reader.result, 'cropped.jpg')
            }
        })

        canvas.toBlob(blob => {
            if (!blob) {
                console.error('Canvas is empty');
                return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            this.setState({ set_image_path: this.fileUrl })
        });
    }

    handleImageCrop = (e) => {
        this.setState({ course_image: this.state.croppedImageUrl, cropImagePopup: false, img: this.state.set_image_path })
    }

    handleUnit(unit) {
        this.props.history.push('/UnitScreen', { unit: unit, course: this.state.course, course_id: this.state.course_id, course_name: this.state.course_name })
    }

    handleCreateUnit() {
        this.props.history.push('/UnitScreen', { course: this.state.course, course_id: this.state.course_id, course_name: this.state.course_name })
    }

    addDefaultSrc(e) {
        e.target.src = require('../assets/click_image.png');
        e.target.onerror = null;
    }

    addDefaultSrcUnit(e) {
        e.target.src = require('../assets/pta-logo2.png');
        e.target.onerror = null;
    }

    courseStatus = (e) => {
        this.setState({ status: e.target.value, saveButtonFlag: true })
    }

    handleUnitStatusPopupOpen(item) {
        this.setState({
            PublishedUnitPopup: true,
            changeUnitStatus: item
        });
    }

    handlePublishedUnitPopupClose = () => {
        this.setState({ PublishedUnitPopup: false });
    }

    handleUnitStatus(item) {
        var item = this.state.changeUnitStatus;
        if (item.status == 'Published') {
            item.status = "Unpublished"
        } else {
            item.status = "Published"
        }

        let data = JSON.stringify(
            {
                unit_id: item.unit_id,
                type: "unit",
                text: item.status
            });
        fetch(API + 'published_data.php',
            {
                method: 'POST',
                body: data

            }).then((response) => response.json()).then((responseJsonFromServer) => {

                if (responseJsonFromServer.result == "success") {
                    this.state.Unit.map((u) => {
                        if (u.unit_id == item.unit_id) {
                            u.status = item.status;
                        }
                    })
                    this.setState({ shownicon: true, PublishedUnitPopup: false })

                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    x.innerHTML = responseJsonFromServer.msg;
                    this.unitDetail()
                } else {

                    this.setState({ PublishedUnitPopup: false, errorPopup: true, error_msg: responseJsonFromServer.msg, })
                    this.unitDetail()
                }
            }).catch((error) => {
                if (error.message == 'Failed to fetch') {
                    this.setState({ networkError: true })
                }
            });
    }

    handleShow(item) {
        this.setState({
            show: true,
            Item_for_delete: item
        });
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    handleDeleteUnit() {
        var item = this.state.Item_for_delete;

        let unit = JSON.stringify(
            {
                unit_id: item.unit_id
            });

        fetch(API + 'delete_unit.php',
            {
                method: 'POST',
                body: unit
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                    this.setState({ show: false });
                    this.unitDetail();
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }
            }).catch((error) => {
                console.log(error.message);
            });
    }


    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = result => {

        if (!result.destination) {
            return;
        }

        const Unit = this.reorder(
            this.state.Unit,
            result.source.index,
            result.destination.index
        );

        this.setState({
            Unit
        });

        let unit_sequence = []

        Unit.map((item, index) =>
            unit_sequence.push({ unit_id: item.unit_id, sequence_id: index })
        )

        let unit_data = JSON.stringify({
            course_id: this.state.course_id,
            sequence_data: unit_sequence
        })

        fetch(API + 'drag_units.php',
            {
                method: 'POST',

                body: unit_data
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                    this.props.handleUpdateQuetionData();

                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }

            }).catch((error) => {
                if (error.message == 'Failed to fetch') {
                    this.setState({ networkError: true })
                }
            });
    };


    componentWillMount() {
        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            window.location.href = '/';
        }
    }

    renderUnit() {
        if (this.state.Unit.length) {
            return (
                <div className="row" style={{ display: 'flex' }}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(droppableProvided, droppableSnapshot) => (
                                <div
                                    className="col-12"
                                    ref={droppableProvided.innerRef}
                                    style={getListStyle(droppableSnapshot.isDraggingOver)}
                                >
                                    {this.state.Unit.map((item, index) => (

                                        <div className="col-lg-4 col-md-6 col-xs-12" key={item.unit_id}>
                                            <Draggable key={item.unit_id} draggableId={item.unit_id} index={index}>
                                                {(draggableProvided, draggableSnapshot) => (
                                                    <div
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}
                                                        style={getItemStyle(
                                                            draggableSnapshot.isDragging,
                                                            draggableProvided.draggableProps.style
                                                        )}
                                                    >
                                                        <div className="course-card-item" key={item.unit_id}>
                                                            <div className="react-card">
                                                                <a onClick={() => this.handleUnit(item)} style={{ color: '#737373', textDecoration: 'none' }}>
                                                                    <div className="card-content">
                                                                        <div className="card-thumb">
                                                                            <img src={item.unit_image} onError={this.addDefaultSrcUnit} className="course-image" />
                                                                        </div>
                                                                        <div className="card-text">
                                                                            <h5 className="card-text--title" style={{ fontWeight: '600', fontSize: 17, color: '#000000', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.unit_name}</h5>
                                                                            <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', overflowWrap: 'anywhere' }}>{item.unit_description}</p>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="card-actions">
                                                                    <div className="CourseCardActionsContainer-sc-xm0i4x hHdVrU">
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div className="btn icon-btn" onClick={() => this.handleShow(item)}>
                                                                                <Icon size={20} icon={trashO} />
                                                                            </div>
                                                                            <div className="btn" onClick={() => this.handleUnitStatusPopupOpen(item)}>
                                                                                <label className="switch">
                                                                                    <input type="checkbox" checked={item.status === "Published" ? true : false} onClick={() => this.handleUnitStatusPopupOpen(item)} />
                                                                                    <span className="slider round"></span>
                                                                                </label>
                                                                                &nbsp; <span style={{ fontWeight: '600' }}>{item.status}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="btn" style={{ fontWeight: '600' }} onClick={() => this.handleUnit(item)} >{item.section_count} Section</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        </div>
                                    ))}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Modal size="sm" show={this.state.show} onHide={this.handleClose}>
                        <Modal.Body>Are you sure you want to delete this unit?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>No</Button>
                            <Button variant="primary" onClick={() => this.handleDeleteUnit()}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="sm" show={this.state.PublishedUnitPopup} onHide={this.handlePublishedUnitPopupClose}>
                        <Modal.Body>Are you sure you want to {this.state.changeUnitStatus.status == 'Published' ? 'Unpublished' : 'Published'} this unit?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handlePublishedUnitPopupClose}>No</Button>
                            <Button style={styles.confirmButton} onClick={() => this.handleUnitStatus()}>Yes</Button>
                        </Modal.Footer>
                    </Modal>


                </div>
            )
        } else if (this.state.noUnitsFound || !this.state.course_id) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', minHeight: 150, justifyContent: 'center', paddingTop: 10, overflow: 'auto' }} >
                    <h3>No Units found</h3>
                </div>
            )
        } else {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', minHeight: 150, justifyContent: 'center', paddingTop: 10 }} >
                    <img src={require('../assets/Loader.gif')} />
                </div>
            );
        }
    }


    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            return (
                <div style={{ minHeight: '100vh', overflowX: 'hidden' }}>
                    <Router />
                    <div>
                        <div>
                            <div style={{ backgroundColor: '#fff', paddingLeft: 30, paddingRight: 30, minHeight: '45vh' }}>
                                <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingTop: 60 }}>
                                    <div className="row" style={{ paddingTop: 10, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
                                        <div className="col-md-12 col-sm-12 block-v-sm" style={{ display: 'flex' }}>
                                            <div><a href="CoursesScreen" style={styles.navigation}>Courses</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a style={styles.navigation}>{this.state.course_name ? this.state.course_name : 'Untitled Course'}</a></div><Icon size={20} icon={smallDown} />
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ marginTop: -1 }} />
                                <div className="form-container">
                                    <div style={{ width: 250, height: 150, backgroundColor: '#8f9294' }} >
                                        <input
                                            style={{ display: 'none' }}
                                            ref={fileInput => this.fileInput = fileInput}
                                            className="upload"
                                            width={250}
                                            height={150}
                                            className="mr-3"
                                            onError={this.addDefaultSrc}
                                            src={this.state.img}
                                            alt="Generic placeholder"
                                            onChange={(e) => this.handleChangeImage(e)}
                                            accept="image/jpeg,image/png"
                                            type="file"
                                        />
                                        <img
                                            className="upload"
                                            onError={this.addDefaultSrc}
                                            onClick={() => this.fileInput.click()}
                                            width={250}
                                            height={150}
                                            className="mr-3"
                                            src={this.state.img}
                                            alt="Generic placeholder"
                                        />

                                        <Modal size="md" show={this.state.cropImagePopup} onHide={() => { this.setState({ cropImagePopup: false }) }}>
                                            <ReactCrop
                                                style={{ margin: 30 }}
                                                src={this.state.crop_image}
                                                onError={this.addDefaultSrc}
                                                onClick={() => this.fileInput.click()}
                                                className="upload"
                                                crop={this.state.crop}
                                                onImageLoaded={this.handleImageLoaded}
                                                onComplete={this.handleOnCropCompleted}
                                                onChange={this.handleOnCropChange}
                                                alt="Generic placeholder"
                                            />
                                            <Button style={{ marginLeft: 30, marginRight: 30, marginBottom: 30, backgroundColor: '#46b4e9', borderColor: '#2fabe6', fontWeight: 'bold' }} onClick={this.handleImageCrop}>Crop</Button>
                                        </Modal>

                                    </div>
                                    <div className="input-field col-sm-12 col-lg-10 col-md-10">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <input className="form-control" placeholder="New qualification level*" id="course_title" autoComplete="off" autoCorrect="off" minLength={2} maxLength={100} value={this.state.course_name} onChange={this.handleChangeCourse} style={styles.textinput_course} required={true} />
                                                <label className="input-error error_course_title text-left"></label>
                                                <textarea className="form-control" placeholder="New qualification title*" id="course_description" autoComplete="off" autoCorrect="off" maxLength={150} rows="2" value={this.state.course_description} onChange={this.handleChangeDescription} style={styles.textinput}></textarea>
                                                <label className="input-error error_course_description text-left"></label>
                                                <div className="heading-editor-publishable" style={{ display: this.state.course_id == '' ? 'none' : 'block' }}>
                                                    <strong style={{ fontSize: '88%' }} className="heading-editor-publishable--label">This course is&nbsp;&nbsp;&nbsp;</strong>
                                                    <select onChange={this.courseStatus} style={{ height: 30, fontSize: '88%', fontWeight: '600', borderRadius: 3 }}>
                                                        <option value="Published" selected={this.state.status === 'Published'} style={{ color: '#000000', backgroundColor: '#fff', borderColor: '#00000' }} >Published</option>
                                                        <option value="Unpublished" selected={this.state.status === 'Unpublished'} style={{ color: '#000000', backgroundColor: '#fff', borderColor: '#00000' }} >Unpublished</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <InputGroup className="mb-2">
                                                    From date*
                                                <DatePicker
                                                        className={!this.state.fromDateValidation ? "error form-control" : "form-control"}
                                                        placeholderText="From date"
                                                        minDate={new Date()}
                                                        maxDate={this.state.to_date}
                                                        selected={this.state.from_date}
                                                        onChange={this.handleFromDate}
                                                        showTimeSelect={false}
                                                        dateFormat="dd/MM/yyyy"
                                                        locale={false}
                                                        showTimeSelect={false}
                                                        showTimeInput={false}

                                                    />
                                                    <label className="input-error error_from_date text-left"></label>
                                                </InputGroup>
                                                <InputGroup className="mb-2">
                                                    To date*
                                                <DatePicker
                                                        className={!this.state.toDateValidation ? "error form-control" : "form-control"}
                                                        placeholderText="To date"
                                                        minDate={this.state.from_date}
                                                        selected={this.state.to_date}
                                                        onChange={this.handleToDate}
                                                        showTimeSelect={false}
                                                        dateFormat="dd/MM/yyyy"
                                                        locale={false}
                                                        showTimeSelect={false}
                                                        showTimeInput={false}
                                                    />
                                                    <label className="input-error error_to_date text-left"></label>
                                                </InputGroup>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div style={styles.saveButton}>
                                    <Button type="submit" disabled={this.state.disable_save_button || !this.state.saveButtonFlag} onClick={this.handleSubmit} variant="primary" size="lg" style={{ fontSize: '88%', width: 200, fontWeight: '600', marginLeft: 15, backgroundColor: '#46b4e9', borderColor: '#2fabe6' }}>
                                        Save
                                </Button>
                                    <div id="snackbar"></div>
                                </div>
                            </div>
                            <div className="block-v-md" style={{ backgroundColor: '#ebf1f7', minHeight: '35vh', paddingRight: 30, paddingLeft: 30 }}>
                                <div className="row" style={{ padding: 10 }}>
                                    <div className="col-md-7 col-sm-5 block-v-sm">
                                        <h4 style={{ color: '#8f9294', fontSize: 20 }}>UNIT</h4>
                                    </div>
                                    <div className="col-md-5 col-sm-7 text-right block-v-sm" >
                                        <button disabled={!this.state.course_id} onClick={() => this.handleCreateUnit()} className="btn btn-light" style={{ backgroundColor: '#46b4e9', borderColor: '#2fabe6', color: '#fff' }}><Icon size={20} icon={plus} className="icon icon-md icon-chevron-down" style={{ marginLeft: 10 }} />&nbsp;&nbsp;<strong>Create a new Unit</strong></button>
                                    </div>
                                    <div className="col col-12" style={{ paddingTop: 30, minHeight: '700' }}>
                                        {this.renderUnit()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal size="sm" show={this.state.errorPopup} onHide={() => this.setState({ errorPopup: false })}>
                        <Modal.Body>{this.state.error_msg}</Modal.Body>
                        <Modal.Footer>
                            {/* <Button variant="secondary" onClick={()=>this.setState({ errorPopup: false })}>No</Button> */}
                            <Button style={styles.confirmButton} block onClick={() => this.setState({ errorPopup: false })}>Ok</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="sm" show={this.state.image_error_popup} onHide={() => this.setState({ image_error_popup: false })}>
                        <Modal.Body>Only images are accepted.</Modal.Body>
                        <Modal.Footer>
                            {/* <Button variant="secondary" onClick={this.handleClose}>No</Button> */}
                            <Button variant="primary" onClick={() => this.setState({ image_error_popup: false })} block>Ok</Button>
                        </Modal.Footer>
                    </Modal>
                    <Footer />
                </div>
            )
        }
    }
}