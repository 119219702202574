import React from 'react'
import { Button } from 'react-bootstrap'

export default class Question_poolDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // done_button_name: this.props.data.prompt,
            // done_text: this.props.data.done_text,
            // title: this.props.data.title,
            // done_button_state: true,
            answer_popup: true,
            selected_value:'',
            answer_reinforcement:'',

            title: '',
            done_text: '',
            done_button_name: '',
            done_button_state: true,
            // current_index: this.props.current_index,
            // prev_review: true,
            question_data: this.props.data.pool_questions_data,
            question_pool: '',
            multiple_data: false,
            // question_pool_current_index: 0
        }
        let data = [];

        this.state.question_data.map(item => {
            return data.push(item)
        })

        function shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        }
        data = shuffle(data)
        this.state.question_pool = data[0];
        this.state.title = data[0].pool_question_title;
        this.state.done_text = data[0].prompt;
        this.state.done_button_name = data[0].prompt;

        console.log("data",data[0]);
    }

    DisableButtonFunction = (e) => {
        this.setState({
            done_button_state: false,
            done_button_name: this.state.question_pool.done_text,
            selected_value: e.target.value
        })
    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    handleAnswerpopup = () => {
        this.setState({ answer_popup: false })
        if(this.state.selected_value == this.state.question_pool.answer){
            this.setState({answer_reinforcement: this.state.question_pool.correct_reinforcement})
            console.log("this.state.question_pool.correct_reinforcement",this.state.question_pool.correct_reinforcement);
        }else{
            this.setState({answer_reinforcement: this.state.question_pool.incorrect_reinforcement})
            console.log("this.state.question_pool.incorrect_reinforcement",this.state.question_pool.incorrect_reinforcement);
        }
    }


    render() {
        return (
            <div>
                <div style={{ height: 340, overflowY: 'auto' }}>
                    <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 30 }}>
                        <h5 className="text-center" style={{ color: '#fff', paddingBottom: 10, overflowWrap: 'anywhere' }}> {this.state.title}</h5>
                        {this.state.question_pool.pool_multiple_choice_options.map((item, i) => (
                            <div style={styles.radio} className="text-left" key={item.multiple_choice_id}>
                                <label style={{ width: '100%' }} >
                                    <input type="radio" name="option" value={item.options} onClick={this.DisableButtonFunction} disabled={!this.state.answer_popup} />&nbsp;
                                    {item.options}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <hr color='#fff' />
                    <div className="text-center">
                        {/* <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}> */}
                        <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleAnswerpopup() }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                            {this.state.done_button_name}
                        </button>
                    </div>
                    <div style={{ backgroundColor: '#fff', visibility: this.state.answer_popup ? "hidden" : "visible", marginTop: -200, position: 'relative', marginLeft: -15, marginRight: -16, padding: 15, borderTop: 'solid', borderTopColor: 'rgba(0,0,0,0.4)', borderTopWidth: 5 }}>
                        <div style={{height: 85, overflowY: 'auto'}}>
                            <p><strong>{this.state.answer_reinforcement}</strong></p>
                            <div>
                                <p>{this.state.question_pool.answer_text}</p>
                            </div>
                        </div>
                        <hr />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <div className="col-6">
                                <Button style={styles.continue_button} onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} block>Continue</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    question_content: {
        backgroundColor: '#34baeb',
        height: 500,
        margin: 30
    },
    edit_btn: {
        backgroundColor: '#223042',
        color: '#fff',
        marginTop: 30
    },
    slider_card: {
        backgroundColor: '#fff',
        paddingBottom: 20,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10
    },
    dot_btn: {
        borderWidth: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    },
    radio: {
        borderWidth: 1,
        backgroundColor: '#fff',
        margin: 5,
        paddingLeft: 20,
        borderRadius: 5,
        fontSize: 15,
        paddingTop: 5
    },
    continue_button: {
        borderWidth: 2,
        borderColor: 'rgba(0,0,0,0.2)',
        padding: 10,
        backgroundColor: '#fff',
        color: '#000000'
    }
}