import React from 'react'
import { Nav, NavDropdown, Navbar } from 'react-bootstrap'

const Styles = {
    navText: { color: '#ffff' }
}

export default class Router extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginUser: JSON.parse(localStorage.getItem('loginUser')),
            name: '',
            isExpanded: false
        }
        if (localStorage.getItem('loginUser')) {
            this.state.name = this.state.loginUser.first_name + " " + this.state.loginUser.last_name;
        }
    }
    componentWillMount() {
        
        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            window.location.href = '/';
        }
    }

    handleToggle(e) {
        e.preventDefault();
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    }

    render() {
        return (

            <Navbar bg="#223042" expand="lg">
                <Navbar.Brand href="CoursesScreen" style={{ paddingLeft: 15 }}><img src={require('../assets/pta-logo2.png')} alt="PTA CMS" style={{ width: 55, height: 55, position: 'absolute', top: 15 }} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto nav">
                        <Nav.Link href="CoursesScreen">Courses</Nav.Link>
                        <NavDropdown title="Dashboard" id="basic-nav-dropdown">
                            <NavDropdown.Item href="course_type" >Package Type</NavDropdown.Item>
                            <NavDropdown.Item href="add_iqa">Add IQA</NavDropdown.Item>
                            <NavDropdown.Item href="add_assessor">Add Assessor</NavDropdown.Item>
                            <NavDropdown.Item href="add_learner">Add Learner</NavDropdown.Item>
                            <NavDropdown.Item href="all_iqa">All IQA</NavDropdown.Item>
                            <NavDropdown.Item href="all_assessor">All Assessor</NavDropdown.Item>
                            <NavDropdown.Item href="all_learner">All Learner</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="report">Reports</Nav.Link>
                        <Nav.Link href="messages">Messages</Nav.Link>
                        <Nav.Link href="calendar">Calendar</Nav.Link>
                    </Nav>
                    <Nav>
                        <NavDropdown title={this.state.name} id="basic-nav-dropdown" style={{ paddingRight: 30 }}>
                            <NavDropdown.Item href="Account_details">Account Details</NavDropdown.Item>
                            <NavDropdown.Item href="Logout" >Sign out</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>

                </Navbar.Collapse>
            </Navbar>

        );
    }
}