import React from 'react'

export default class Footer extends React.Component {
    render() {
        return (
            <div className="block-xl container-fluid" style={{ backgroundColor: '#E6EBF0' }}>
                <footer id="footer" >
                    <div className="container">
                        <div className="row block-v-xl">
                            <div className="col-md-4 col-sm-6">
                                <h6 className="footer-list-title block-bb block-v-md text-bold text-uppercase">Quick Links</h6>
                                <ul className="footer-list list-unstyled">
                                    {/* <li className="block-v-sm"><a href="dashboard" style={{ color: '#3b3b3b' }}>Dashboard</a></li> */}
                                    <li className="block-v-sm"><a href="CoursesScreen" style={{ color: '#3b3b3b' }}>Courses</a></li>
                                    <li className="block-v-sm"><a href="report" style={{ color: '#3b3b3b' }}>Reports</a></li>
                                    <li className="block-v-sm"><a href="messages" style={{ color: '#3b3b3b' }}>Messages</a></li>
                                    <li className="block-v-sm"><a href="calendar" style={{ color: '#3b3b3b' }}>Calendar</a></li>
                                </ul>
                            </div>
                            <div className="col-md-4 col-sm-6" >
                                <h6 className="footer-list-title block-bb block-v-md text-bold text-uppercase">Dashboard</h6>
                                <ul className="footer-list list-unstyled">
                                    <li className="block-v-sm"><a href="course_type" style={{ color: '#3b3b3b' }}>Package type</a></li>
                                    <li className="block-v-sm"><a href="all_iqa" style={{ color: '#3b3b3b' }}>IQA</a></li>
                                    <li className="block-v-sm"><a href="all_assessor" style={{ color: '#3b3b3b' }}>Assessor</a></li>
                                    <li className="block-v-sm"><a href="all_learner" style={{ color: '#3b3b3b' }}>Learner</a></li>
                                </ul>
                            </div>
                            {/* <div className="col-md-3 col-sm-6">
                                <h6 className="footer-list-title block-bb block-v-md text-bold text-uppercase">Resources</h6>
                                <ul className="footer-list list-unstyled">
                                    <li className="block-v-sm"><a href="#" style={{ color: '#3b3b3b' }} target="_blank">Academy</a></li>
                                    <li className="block-v-sm"><a href="#" style={{ color: '#3b3b3b' }} target="_blank">Blog</a></li>
                                    <li className="block-v-sm"><a href="#" style={{ color: '#3b3b3b' }} target="_blank">Contact Us</a></li>
                                </ul>
                            </div> */}
                            <div className="col-md-4 col-sm-6">
                                <h6 className="footer-list-title block-bb block-v-md text-bold text-uppercase">PTA</h6>
                                <ul className="footer-list list-unstyled">
                                    {/* <li className="block-v-sm"><a style={{ color: '#3b3b3b' }} target="_blank"><img src={require('../../assets/pta-logo2.png')} height="24" alt="Access PTA Online" className="icon icon-space" /> &nbsp;web.PTAapp.com</a></li> */}
                                    <li className="block-v-sm"><a style={{ color: '#3b3b3b' }} target="_blank"><img src={require('../../assets/ios.png')} height="24" alt="Download on the App Store" className="icon icon-space" /> &nbsp;PTA for iOS</a></li>
                                    <li className="block-v-sm"><a style={{ color: '#3b3b3b' }} target="_blank"><img src={require('../../assets/play_store.png')} height="24" alt="Get it on Google Play" className="icon icon-space" />&nbsp;&nbsp;PTA for Android</a></li>
                                </ul>
                                <div className="block-v-lg"><small className="text-muted">© PTA 2019</small></div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}