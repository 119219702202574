import React from 'react';
import API from './Components/Global_API'
import NoInternet from './Components/NoInternet';


class Logout extends React.Component {
    constructor(props) {
        super(props);
        var date = new Date().getDate();
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();
        var hours = new Date().getHours();
        var min = new Date().getMinutes();
        var sec = new Date().getSeconds();
        this.state = {
            user: '',
            current_date: year + '-' + month + '-' + date + ' ' + hours + ':' + min + ':' + sec,
        }
        if (localStorage.getItem("loginUser")) {
            this.state.user = JSON.parse(localStorage.getItem("loginUser"))
        }
    }

    componentWillMount() {
        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            window.location.href = '/';
        }
    }

    componentDidMount() {
        this.Logout();
    }
    Logout = () => {
        let LogOut = JSON.stringify(
            {
                user_id: this.state.user.userId,
                date: this.state.current_date
            });

        fetch(API + 'admin_logout.php',
            {
                method: 'POST',

                body: LogOut
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                if (responseJsonFromServer.result === 'success') {
                    localStorage.removeItem("loginUser");

                    this.props.history.push("/");
                }
            }).catch((error) => {
                // console.log(error.message);
                if (error.message == 'Failed to fetch') {
                    this.setState({ networkError: true })
                }
            });
    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 10 }} >
                    <img src={require('../assets/Loader.gif')} />
                </div>
            );
        }
    }

}

export default Logout;