import React from 'react';
import Scrollable_detail from './Scrollable_detail';
import Expandable_detail from './Expandable_detail';
import True_false_detail from './True_false_detail';
import Multiple_choice_detail from './Multiple_choice_detail';
import Video_detail from './Video_detail';
import Slider_detail from './Slider_detail';
import Crossword_detail from './Crossword_Detail';
import Memory_detail from './Memory_detail';
import Exit_detail from './Exit_detail';
import Start_lesson from './Start_lesson_detail';
import Circle_the_answer from './Circle_the_answer';
import Free_text_detail from './Free_text_detail';
import Peer_authoring_detail from './Peer_authoring_detail';
import Reorder_detail from './Reorder_detail';
import ListSlide_detail from './ListSlide_detail';
import Vimeo_video_detail from './Vimeo_video_detail';
import Multiple_choice_image_detail from './Multiple_choice_image_detail';
import Drag_to_match_detail from './Drag_to_match_detail';
import Question_poolDetails from './Question_poolDetails';


export default class Component_Loader_Edit_Lesson extends React.Component {
    constructor() {
        super();
        this.state = {
            cmpType: ''
        }
    }
    componentDidMount() {
        this.setState({ 'cmpType': this.props.component_type });
    }
    render() {
        if (this.props.component_type == 'expandable') {
            return (<div><Expandable_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data}  /></div>)
        } else if (this.props.component_type == 'scrollable') {
            return (<Scrollable_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'video_slide') {
            return (<Video_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'multiple_choice') {
            return (<Multiple_choice_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'slider') {
            return (<Slider_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'true_false') {
            return (<True_false_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} que_count={this.props.que_count} />)
        } else if (this.props.component_type == 'crossword') {
            return (<Crossword_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'memory') {
            return (<Memory_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'circle_the_answer') {
            return (<Circle_the_answer nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'exit_lesson') {
            return (<Exit_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'start_lesson') {
            return (<Start_lesson nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'free_text') {
            return (<Free_text_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'peer_authoring') {
            return (<Peer_authoring_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'reorder') {
            return (<Reorder_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'list_slide') {
            return (<ListSlide_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'vimeo_video') {
            return (<Vimeo_video_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'multiple_choice_image') {
            return (<Multiple_choice_image_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'drag_to_match') {
            return (<Drag_to_match_detail nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else if (this.props.component_type == 'lesson_question_pool') {
            return (<Question_poolDetails nextQuestion={this.props.nextQuestion} current_question_index={this.props.current_question_index} data={this.props.component_data} />)
        } else {
            return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><img src={require('../../assets/Loader.gif')} /></div>)
        }
    }
}