import React from 'react'
import { Icon } from 'react-icons-kit'
import { smallDown, smallRight, smallLeft } from 'react-icons-kit/entypo'
import { pencil } from 'react-icons-kit/fa/pencil'
import { Accordion, Card, Button, Media } from 'react-bootstrap'

export default class Scrollable_detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            done_button_name: this.props.data.prompt,
            done_text: this.props.data.done_text,
            done_button_state: true,
        }
    }

    DisableButtonFunction = () => {
        this.setState({
            done_button_state: false,
            done_button_name: this.state.done_text
        })
    }

    handleDoneButton = (current_question_index) => {
        this.props.nextQuestion(current_question_index)
    }

    render() {
        return (

            <div>
                <div style={{ height: 340, overflowY: 'auto' }} onScroll={this.DisableButtonFunction}>
                    <div style={{ paddingRight: 50, paddingLeft: 50, paddingTop: 30 }}>
                        <h5 className="text-center" style={{ color: "#fff", overflowWrap: 'anywhere' }}>{this.props.data.title}</h5>
                        <div style={{minHeight: 340}}>
                            <p className="text-left" style={{ color: "#fff", whiteSpace: 'pre-line', paddingTop: 20, fontSize:16 }}>
                                {this.props.data.content}
                            </p>
                        </div>

                    </div>
                </div>
                <div>
                    <hr color='#fff' />
                    <div className="text-center">
                        <button className="btn" disabled={this.state.done_button_state} onClick={() => { this.handleDoneButton(this.props.current_question_index + 1) }} style={this.state.done_button_state ? styles.disable_done_btn : styles.done_btn}>
                            {this.state.done_button_name}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#fff',
        borderWidth: 1,
        borderColor: '#fff',
        fontWeight: 'bold'
    },
    disable_done_btn: {
        marginBottom: 30,
        color: '#fff',
        fontWeight: 'bold'
    }

}