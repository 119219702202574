import React from 'react';
import Router from './Router';
import { Icon } from 'react-icons-kit'
import { smallDown, smallRight } from 'react-icons-kit/entypo'
import { trashO, plus } from 'react-icons-kit/fa'
import { Button, Modal } from 'react-bootstrap'
import Footer from './Components/Footer';
import API from './Components/Global_API'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import NoInternet from './Components/NoInternet';


const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    borderRadius: 3,
    display: 'flex',
    flexWrap: 'wrap'
});

const styles = {
    title: {
        textAlign: 'center'
    },
    textinput: {
        resize: "none",
        marginBottom: 5,
        fontSize: 20,
        overflow: 'auto'
    },
    navigation: {
        color: '#8f9294',
        fontSize: 18,
        cursor: 'pointer',
        fontWeight: '600'
    },
    confirmButton: {
        backgroundColor: 'rgb(70, 180, 233)',
        borderColor: 'rgb(70, 180, 233)'
    },
    header: {
        maxWidth: 300,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}

export default class SectionScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            section_name: '',
            description: '',
            img: '',
            sectionid: '',
            Lesson: [],
            unit_id: '',
            unit_name: '',
            course_name: '',
            course: '',
            unit: '',
            noLessonFound: false,
            section: '',
            lesson_id: '',
            shownicon: false,
            status: 'Unpublished',
            published: false,
            show: false,
            Item_for_delete: '',
            course_id: '',
            disable_save_button: true,
            saveButtonFlag: false,
            changeLessonStatus: '',
            PublishedLessonPopup: false,
            cropImagePopup: false,
            crop_image: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 2 / 1,
            },
            course_image: '',
            error_msg: '',
            errorPopup: false,
            image_error_popup: false
        };

        let loginUser = localStorage.getItem("loginUser")
        if (loginUser === null) {
            window.location.href = '/';
        } else {
            if (this.props.location.state) {
                this.state.course_name = this.props.location.state.course_name;
                this.state.unit_name = this.props.location.state.unit_name;
                this.state.section_name = this.props.location.state.section_name;
                this.state.course = this.props.location.state.course;
                this.state.unit = this.props.location.state.unit;
                this.state.section = this.props.location.state.section;
                this.state.course_id = this.props.location.state.course_id;
                this.state.unit_id = this.props.location.state.unit_id
                // }

                // if (this.props.location.state.section) {
                let sid = this.props.location.state.section && this.props.location.state.section.section_id;
                // let sname = this.props.location.state.section.section_name;
                let sdesc = this.props.location.state.section && this.props.location.state.section.section_description;
                let cimg = this.props.location.state.section ? this.props.location.state.section.section_image : '';
                // let course_name = this.props.location.state.course_name;
                // let unit_name = this.props.location.state.unit_name;
                this.state.status = this.props.location.state.section && this.props.location.state.section.status;

                this.state.sectionid = sid;
                // this.state.section_name = sname;
                this.state.description = sdesc;
                this.state.img = cimg;
                // this.state.course_name = course_name;
                // this.state.unit_name = unit_name;
            }

            if (this.state.section_name === "" || this.state.section_name === null) {
                this.state.disable_save_button = true
            } else {
                this.state.disable_save_button = false
            }
            this.handleClose = this.handleClose.bind(this);
        }
    }

    handleShow(item) {
        this.setState({
            show: true,
            Item_for_delete: item
        });
    }

    handleClose() {
        this.setState({ show: false });
    }


    lesson_details() {

        if (this.state.sectionid) {
            let section_id = JSON.stringify({
                section_id: this.state.sectionid
            });
            console.log("section_id", section_id);
            fetch(API + 'get_section_details.php',
                {
                    method: 'POST',
                    body: section_id
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    if (responseJsonFromServer.result === "success") {

                        this.setState({
                            status: responseJsonFromServer.section_status,
                            section_name: responseJsonFromServer.section_name,
                            description: responseJsonFromServer.section_description
                        })
                        if (responseJsonFromServer.lession_data) {
                            this.setState({ Lesson: responseJsonFromServer.lession_data, lesson_id: this.state.lesson_id })
                        } else {
                            this.setState({ Lesson: [] })
                            this.setState({ noLessonFound: true })
                        }
                    } else {
                        alert(responseJsonFromServer.msg);
                    }
                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });
        }
    }

    componentDidMount() {
        this.lesson_details();
    }


    handleSubmit = (e) => {
        e.preventDefault();

        let errorCount = 0;
        if (this.state.section_name.trim().length < 2 || this.state.section_name.trim().length > 100) {
            if (this.state.section_name.trim().length === 0) {
                document.getElementsByClassName('error_section_title')[0].innerHTML = 'New section number is required.';
            }
            document.getElementsByClassName('error_section_title')[0].style.display = 'block';
            document.getElementById('section_title').classList.add('error')
            errorCount++;
        }
        if (this.state.description.trim().length < 2 || this.state.description.trim().length > 150) {
            if (this.state.description.trim().length === 0) {
                document.getElementsByClassName('error_section_description')[0].innerHTML = 'New section title is required.';
            }
            document.getElementsByClassName('error_section_description')[0].style.display = 'block';
            document.getElementById('section_description').classList.add('error')
            errorCount++;
        }

        if (errorCount == 0) {

            let section_error = false;
            if (this.state.sectionid !== '') {
                let status = JSON.stringify(
                    {
                        section_id: this.state.sectionid,
                        type: "section",
                        text: this.state.status
                    });

                fetch(API + 'published_data.php',
                    {
                        method: 'POST',

                        body: status

                    }).then((response) => response.json()).then((responseJsonFromServer) => {

                        if (responseJsonFromServer.result === "success") {
                            this.lesson_details();
                        } else {
                            this.setState({ errorPopup: true, error_msg: responseJsonFromServer.msg })
                            this.lesson_details();
                            section_error = true;
                        }

                    }).catch((error) => {
                        if (error.message == 'Failed to fetch') {
                            this.setState({ networkError: true })
                        }
                    });
            }

            let flag = 'create';
            let sectionid = '';
            let unit_id = this.state.unit_id;
            if (this.state.sectionid !== '') {
                flag = 'edit';
                sectionid = this.state.sectionid;
                unit_id = unit_id;
            }
            let section = new FormData()
            section.append('section_name', this.state.section_name);
            section.append('section_description', this.state.description);
            section.append('section_images', this.state.course_image);
            section.append('flag', flag);
            section.append('status', this.state.status);
            section.append('sectionid', sectionid);
            section.append('unit_id', unit_id);

            fetch(API + 'create_section.php',
                {
                    method: 'POST',

                    body: section
                }).then((response) => response.json()).then((responseJsonFromServer) => {


                    if (responseJsonFromServer.result === 'success') {

                        if (this.state.errorPopup === false && section_error == false) {

                            var x = document.getElementById("snackbar");
                            x.className = "show";
                            setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                            x.innerHTML = responseJsonFromServer.msg;
                        }

                        this.setState({ sectionid: responseJsonFromServer.section_id, section: responseJsonFromServer, saveButtonFlag: false })
                        this.lesson_details();
                    } else {
                        var x = document.getElementById("snackbar");
                        x.className = "show";
                        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                        x.innerHTML = responseJsonFromServer.msg;
                    }

                }).catch((error) => {
                    if (error.message == 'Failed to fetch') {
                        this.setState({ networkError: true })
                    }
                });

        }

    }

    handleDeleteLesson() {
        var item = this.state.Item_for_delete;

        let lesson = JSON.stringify(
            {
                lesson_id: item.lesson_id
            });

        fetch(API + 'delete_lesson.php',
            {
                method: 'POST',
                body: lesson
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                    this.setState({ show: false });
                    this.lesson_details();
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }
            }).catch((error) => {
                if (error.message == 'Failed to fetch') {
                    this.setState({ networkError: true })
                }
            });
    }

    handleChangeSection = (e) => {
        this.setState({ section_name: e.target.value, saveButtonFlag: true });
        if (e.target.value.trim() === "") {
            document.getElementsByClassName('error_section_title')[0].innerHTML = 'New section number is required.';
            document.getElementsByClassName('error_section_title')[0].style.display = 'block';
            e.target.classList.add('error');
            this.setState({ disable_save_button: true })
        } else if (e.target.value.trim().length < 2 || e.target.value.trim().length > 100) {
            this.setState({ disable_save_button: true })
            document.getElementsByClassName('error_section_title')[0].innerHTML = 'New section number must be between 2 to 100 character.';
            document.getElementsByClassName('error_section_title')[0].style.display = 'block';
            e.target.classList.add('error');
        } else {
            document.getElementsByClassName('error_section_title')[0].innerHTML = "";
            document.getElementsByClassName('error_section_title')[0].style.display = 'none';
            e.target.classList.remove('error');
            this.setState({ disable_save_button: false })
        }
    }

    handleChangeDescription = (e) => {
        if (e.target.value.trim() === "") {
            document.getElementsByClassName('error_section_description')[0].innerHTML = 'New section title is required.';
            document.getElementsByClassName('error_section_description')[0].style.display = 'block';
            e.target.classList.add('error');
            this.setState({ disable_save_button: true })
        } else if (e.target.value.trim().length < 2 || e.target.value.trim().length > 150) {
            this.setState({ disable_save_button: true })
            document.getElementsByClassName('error_section_description')[0].innerHTML = 'New section title must be between 2 to 150 character.';
            document.getElementsByClassName('error_section_description')[0].style.display = 'block';
            e.target.classList.add('error');
        } else {
            this.setState({ disable_save_button: false })
            document.getElementsByClassName('error_section_description')[0].innerHTML = "";
            document.getElementsByClassName('error_section_description')[0].style.display = 'none';
            e.target.classList.remove('error');
        }
        this.setState({ description: e.target.value, saveButtonFlag: true });
    }

    handleChangeImage(e) {
        if (e.target.files[0]) {
            var fileName = e.target.files[0].name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                if (e.target.files && e.target.files.length > 0) {
                    const reader = new FileReader();
                    reader.addEventListener('load', () =>
                        this.setState({ crop_image: reader.result, cropImagePopup: true, image_path: true, saveButtonFlag: true })
                    );
                    reader.readAsDataURL(e.target.files[0]);
                }
            } else {
                this.setState({ image_error_popup: true })
            }
        }
    }

    handleImageLoaded = (image) => {
        this.imageRef = image;
    }

    handleOnCropChange = (crop) => {
        this.setState({ crop });
    }

    handleOnCropCompleted = (crop, pixelCrop) => {
        this.makeClientCrop(crop);
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImageUrl = new File([u8arr], filename, { type: mime });
        this.setState({ croppedImageUrl })

    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                this.dataURLtoFile(reader.result, 'cropped.jpg')
            }
        })

        canvas.toBlob(blob => {
            if (!blob) {
                console.error('Canvas is empty');
                return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            this.setState({ set_image_path: this.fileUrl })
        });
    }

    handleImageCrop = (e) => {
        this.setState({ course_image: this.state.croppedImageUrl, cropImagePopup: false, img: this.state.set_image_path })
    }

    handleSection(lesson) {
        this.props.history.push('/Create_lesson', { lesson: lesson, course: this.state.course, unit: this.state.unit, section: this.state.section, section_id: this.props.location.state.section.section_id, course_name: this.state.course_name, unit_id: this.state.unit_id, unit_name: this.state.unit_name, section_name: this.state.section_name, unit: this.state.unit, course_id: this.state.course_id })
    }

    handlecontent(lesson) {
        this.props.history.push('/edit_content', { lesson: lesson, lesson_id: lesson.lesson_id, lesson_name: lesson.lesson_name, course: this.state.course, course_name: this.state.course_name, unit: this.state.unit, unit_name: this.state.unit_name, section: this.state.section, section_name: this.state.section_name, course_id: this.state.course_id })
    }

    handleUnit() {
        this.props.history.push('/UnitScreen', { unit_name: this.state.unit_name, unit: this.state.unit, course_name: this.state.course_name, course: this.state.course, course_id: this.state.course_id })
    }

    handleCourse() {
        this.props.history.push('/Create_Course', { course_name: this.state.course_name, course: this.state.course, course_id: this.state.course_id })
    }

    addDefaultSrc(e) {
        e.target.src = require('../assets/click_image.png');
        e.target.onerror = null;
    }

    handleCreatelesson() {
        this.props.history.push('/Create_lesson', { section_id: this.state.sectionid, course: this.state.course, unit: this.state.unit, section: this.state.section, course_name: this.state.course_name, unit_id: this.state.unit_id, unit_name: this.state.unit_name, section_name: this.state.section_name, course_id: this.state.course_id })
    }

    sectionStatus = (e) => {
        this.setState({ status: e.target.value, saveButtonFlag: true })
    }
    addDefaultSrcLesson(e) {
        e.target.src = require('../assets/pta-logo2.png');
        e.target.onerror = null;
    }

    handleLessonStatusPopupOpen(item) {
        this.setState({
            PublishedLessonPopup: true,
            changeLessonStatus: item
        });
    }

    handlePublishedLessonPopupClose = () => {
        this.setState({ PublishedLessonPopup: false });
    }

    handleLessonStatus(item) {
        var item = this.state.changeLessonStatus;
        if (item.status === 'Published') {
            item.status = "Unpublished"
        } else {
            item.status = "Published"
        }


        let data = JSON.stringify(
            {
                lesson_id: item.lesson_id,
                type: "lesson",
                text: item.status
            });
        console.log("data", data);
        fetch(API + 'published_data.php',
            {
                method: 'POST',

                body: data

            }).then((response) => response.json()).then((responseJsonFromServer) => {

                if (responseJsonFromServer.result === "success") {
                    this.state.Lesson.map((l) => {
                        if (l.lesson_id === item.lesson_id) {
                            l.status = item.status;
                        }
                    })
                    this.setState({ shownicon: true, PublishedLessonPopup: false })
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                    x.innerHTML = responseJsonFromServer.msg;
                    this.lesson_details();
                } else {
                    this.setState({ PublishedLessonPopup: false, errorPopup: true, error_msg: responseJsonFromServer.msg })
                    // x.innerHTML = responseJsonFromServer.msg;
                    this.lesson_details();
                }

            }).catch((error) => {
                if (error.message == 'Failed to fetch') {
                    this.setState({ networkError: true })
                }
            });
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const Lesson = this.reorder(
            this.state.Lesson,
            result.source.index,
            result.destination.index
        );

        this.setState({
            Lesson
        });

        let lesson_sequence = []

        Lesson.map((item, index) =>
            lesson_sequence.push({ lesson_id: item.lesson_id, sequence_id: index })
        )

        let lesson_data = JSON.stringify({
            section_id: this.state.sectionid,
            sequence_data: lesson_sequence
        })


        fetch(API + 'drag_lessons.php',
            {
                method: 'POST',

                body: lesson_data
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }

            }).catch((error) => {
                if (error.message == 'Failed to fetch') {
                    this.setState({ networkError: true })
                }
            });
    };

    componentWillMount() {
        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            window.location.href = '/';
        }
    }

    renderLesson() {
        if (this.state.Lesson.length) {
            return (
                <div className="row" style={{ display: 'flex' }}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(droppableProvided, droppableSnapshot) => (
                                <div
                                    className="col-12"
                                    ref={droppableProvided.innerRef}
                                    style={getListStyle(droppableSnapshot.isDraggingOver)}
                                >
                                    {this.state.Lesson.map((item, index) => (
                                        <div className="col-lg-4 col-md-6 col-xs-12" key={item.lesson_id}>
                                            <Draggable key={item.lesson_id} draggableId={item.lesson_id} index={index}>
                                                {(draggableProvided, draggableSnapshot) => (
                                                    <div
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.draggableProps}
                                                        {...draggableProvided.dragHandleProps}
                                                        style={getItemStyle(
                                                            draggableSnapshot.isDragging,
                                                            draggableProvided.draggableProps.style
                                                        )}
                                                    >
                                                        <div className="course-card-item" key={item.lesson_id}>
                                                            <div className="react-card">
                                                                <a onClick={() => this.handleSection(item)} style={{ color: '#737373', textDecoration: 'none' }}>
                                                                    <div className="card-content">
                                                                        <div className="card-thumb">
                                                                            <img src={item.lesson_image} onError={this.addDefaultSrcLesson} className="course-image" />
                                                                        </div>
                                                                        <div className="card-text">
                                                                            <h5 style={{ fontWeight: '600', fontSize: 17, color: '#000000', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} className="card-text--title">{item.lesson_name}</h5>
                                                                            <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', overflowWrap: 'anywhere' }}>{item.description}</p>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="card-actions">
                                                                    <div className="CourseCardActionsContainer-sc-xm0i4x hHdVrU">
                                                                        <div style={{ display: 'flex' }}>
                                                                            <div className="btn icon-btn" onClick={() => this.handleShow(item)}>
                                                                                <Icon size={20} icon={trashO} />
                                                                            </div>
                                                                            <div className="btn" onClick={() => this.handleLessonStatusPopupOpen(item)}>
                                                                                <label className="switch">
                                                                                    <input type="checkbox" checked={item.status === "Published" ? true : false} onClick={() => this.handleLessonStatusPopupOpen(item)} />
                                                                                    <span className="slider round"></span>
                                                                                </label>
                                                                                &nbsp;<span style={{ fontWeight: '600' }}>{item.status}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="btn" style={{ fontWeight: '600', paddingLeft: 0, whiteSpace: 'normal', textOverflow: 'ellipsis', overflow: 'hidden' }} onClick={() => this.handlecontent(item)}>Edit content</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        </div>
                                    ))}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Modal size="sm" show={this.state.show} onHide={this.handleClose}>
                        <Modal.Body>Are you sure you want to delete this lesson?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>No</Button>
                            <Button variant="primary" onClick={() => this.handleDeleteLesson()}>Yes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal size="sm" show={this.state.PublishedLessonPopup} onHide={this.handlePublishedLessonPopupClose}>
                        <Modal.Body>Are you sure you want to {this.state.changeLessonStatus.status === 'Published' ? 'Unpublished' : 'Published'} this lesson?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handlePublishedLessonPopupClose}>No</Button>
                            <Button style={styles.confirmButton} onClick={() => this.handleLessonStatus()}>Yes</Button>
                        </Modal.Footer>
                    </Modal>


                </div>
            )
        } else if (this.state.noLessonFound || !this.state.sectionid) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', minHeight: 160, justifyContent: 'center', paddingTop: 10 }} >
                    <h3>No Lessons found</h3>
                </div>
            )
        } else {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', minHeight: 160, justifyContent: 'center', paddingTop: 10 }}>
                    <img src={require('../assets/Loader.gif')} />
                </div>
            )
        }
    }

    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            return (
                <div style={{ minHeight: '100vh' }}>
                    <Router />
                    <div style={{ backgroundColor: '#fff', paddingLeft: 30, paddingRight: 30, minHeight: '45vh' }}>
                        <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingTop: 60 }}>
                            <div className="row" style={{ paddingTop: 10, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
                                <div className="col-md-12 col-sm-12 block-v-sm" style={{ display: 'flex' }}>
                                    <div><a href="CoursesScreen" style={styles.navigation}>Courses</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a onClick={() => this.handleCourse()} style={styles.navigation}>{this.props.location.state.course.course_name}</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a onClick={() => this.handleUnit()} style={styles.navigation}>{this.props.location.state.unit.unit_name}</a></div> <Icon size={20} icon={smallRight} /> <div style={styles.header}><a style={styles.navigation}>{this.state.section_name ? this.state.section_name : 'Untitled Section'}</a></div><Icon size={20} icon={smallDown} />
                                </div>
                            </div>
                        </div>
                        <hr style={{ marginTop: -1 }} />
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-container">
                                <div style={{ width: 250, height: 150, backgroundColor: '#8f9294' }} >
                                    <input
                                        style={{ display: 'none' }}
                                        ref={fileInput => this.fileInput = fileInput}
                                        className="upload"
                                        width={250}
                                        height={150}
                                        className="mr-3"
                                        onError={this.addDefaultSrc}
                                        src={this.state.img}
                                        alt="Generic placeholder"
                                        onChange={(e) => this.handleChangeImage(e)}
                                        type="file"
                                        accept="image/*"
                                    />
                                    <img
                                        className="upload"
                                        onClick={() => this.fileInput.click()}
                                        width={250}
                                        height={150}
                                        onError={this.addDefaultSrc}
                                        className="mr-3"
                                        src={this.state.img}
                                        alt="Generic placeholder"
                                    />

                                    <Modal show={this.state.cropImagePopup} onHide={() => { this.setState({ cropImagePopup: false }) }}>
                                        <ReactCrop
                                            style={{ margin: 30 }}
                                            src={this.state.crop_image}
                                            onError={this.addDefaultSrc}
                                            onClick={() => this.fileInput.click()}
                                            className="upload"
                                            crop={this.state.crop}
                                            onImageLoaded={this.handleImageLoaded}
                                            onComplete={this.handleOnCropCompleted}
                                            onChange={this.handleOnCropChange}
                                            alt="Generic placeholder"
                                        />
                                        {/* <div className="row">
                                            
                                        </div> */}
                                        <Button style={{ marginLeft: 30, marginRight: 30, marginBottom: 30, backgroundColor: '#46b4e9', borderColor: '#2fabe6', fontWeight: 'bold' }} onClick={this.handleImageCrop}>Crop</Button>
                                    </Modal>

                                </div>
                                <div className="input-field col-sm-12 col-lg-5 col-md-5">
                                    <input className="form-control" placeholder="New section number*" id="section_title" autoComplete="off" autoCorrect="off" maxLength={100} minLength={2} value={this.state.section_name} onChange={this.handleChangeSection} style={styles.textinput} required />
                                    <label className="input-error error_section_title text-left"></label>
                                    <textarea className="form-control" placeholder="New section title*" id="section_description" autoComplete="off" autoCorrect="off" maxLength={150} minLength={2} value={this.state.description} onChange={this.handleChangeDescription} rows="2" style={styles.textinput}></textarea>
                                    <label className="input-error error_section_description text-left"></label>
                                    <div className="heading-editor-publishable" style={{ display: this.state.sectionid === '' ? 'none' : 'block', }}>

                                        <div id="snackbar"></div>
                                        <strong style={{ fontSize: '88%' }} className="heading-editor-publishable--label">This section is&nbsp;&nbsp;&nbsp;</strong>
                                        <select onChange={this.sectionStatus} style={{ height: 30, fontSize: '88%', fontWeight: '600', borderRadius: 3 }}>
                                            <option value="Published" selected={this.state.status === 'Published'} style={{ color: '#000000', backgroundColor: '#fff', borderColor: '#00000' }} >Published</option>
                                            <option value="Unpublished" selected={this.state.status === 'Unpublished'} style={{ color: '#000000', backgroundColor: '#fff', borderColor: '#00000' }} >Unpublished</option>
                                        </select>
                                    </div>
                                    <div className="form-submit-button">
                                        <Button type="submit" variant="primary" disabled={this.state.disable_save_button || !this.state.saveButtonFlag} size="lg" style={{ fontSize: '88%', width: 200, fontWeight: '600', backgroundColor: '#46b4e9', borderColor: '#2fabe6' }}>Save</Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="block-v-md" style={{ backgroundColor: '#ebf1f7', minHeight: '35vh', paddingRight: 30, paddingLeft: 30 }}>
                        <div className="row" style={{ padding: 10 }}>
                            <div className="col-md-7 col-sm-5 block-v-sm">
                                <h4 style={{ color: '#8f9294', fontSize: 20 }}>LESSON</h4>
                            </div>
                            <div className="col-md-5 col-sm-7 text-right block-v-sm" >
                                <button disabled={!this.state.sectionid} onClick={() => this.handleCreatelesson()} className="btn btn-light" style={{ backgroundColor: '#46b4e9', borderColor: '#2fabe6', color: '#fff' }}><Icon size={20} icon={plus} className="icon icon-md icon-chevron-down" style={{ marginLeft: 10 }} />&nbsp;&nbsp;<strong>Create a new lesson</strong></button>
                            </div>
                            <div className="col" style={{ paddingTop: 20 }}>

                                {this.renderLesson()}
                            </div>
                        </div>
                    </div>
                    <Modal size="sm" show={this.state.errorPopup} onHide={() => this.setState({ errorPopup: false })}>
                        <Modal.Body>{this.state.error_msg}</Modal.Body>
                        <Modal.Footer>
                            {/* <Button variant="secondary" onClick={()=>this.setState({ errorPopup: false })}>No</Button> */}
                            <Button style={styles.confirmButton} block onClick={() => this.setState({ errorPopup: false })}>Ok</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal size="sm" show={this.state.image_error_popup} onHide={() => this.setState({ image_error_popup: false })}>
                        <Modal.Body>Only images are accepted.</Modal.Body>
                        <Modal.Footer>
                            {/* <Button variant="secondary" onClick={this.handleClose}>No</Button> */}
                            <Button variant="primary" onClick={() => this.setState({ image_error_popup: false })} block>Ok</Button>
                        </Modal.Footer>
                    </Modal>
                    <Footer />
                </div>
            )
        }
    }
}