import React from 'react'

export default class NoInternet extends React.Component {
    render() {
        return (
            <div style={styles.container}>
                <img src={require('../../assets/internet.png')} width={150} height={150} />
                <h1 style={{fontWeight: 'bold', color:"#363636"}}>Ooops!</h1>
                <h5 style={{margin: 0, color: '#999'}}>Slow or no internet connection.</h5>
                <h5 style={{margin: 0, color: '#999'}}>Please check your internet settings.</h5>
            </div>
        )
    }
}

const styles = {
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        position: 'fixed',
    }
}