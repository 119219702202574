import React from 'react'

export default class Exit_detail extends React.Component {
    render() {
        return (

            <div>
                <div style={{ height: 340,display: 'flex', flexDirection: 'column',  justifyContent:'center', alignItems:'center', overflowY: 'auto' }}>
                    <div style={{ display: 'flex', flexDirection: 'column',  justifyContent:'center', alignItems:'center'}}>
                        <h5 className="text-center" style={{ color: "#fff", overflowWrap: 'anywhere' }}>{this.props.data.title}</h5>
                        <p className="text-center" style={{ color: "#fff", overflowWrap: 'anywhere' }}>{this.props.data.sub_title}</p>
                        <div className="text-center">
                            <button className="btn" style={styles.done_btn}>
                                {this.props.data.button_text}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <hr color='#fff' />
                </div>
            </div>
        )
    }
}

const styles = {
    done_btn: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        marginBottom: 30,
        color: '#000000',
        backgroundColor: '#fff',
        fontWeight: 'bold'
    },

}