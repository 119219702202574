import React from 'react'
import { Link } from 'react-router-dom'

export default class PageNotFound extends React.Component {
    render() {
        return (
            <div style={styles.container}>
                <img src={require('../../assets/not_found_error.png')} style={{ width: 350, height: 350, display: 'block', margin: 'auto', position: 'relative' }} />
                <center><Link to="/CoursesScreen" style={styles.link}>Return to Home Page</Link></center>
            </div>
        )
    }
}

const styles = {
    container: {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
        position: 'fixed',
        paddingTop: '10%'
    },
    link: {
        fontSize: 20
    }
}