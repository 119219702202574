import React from 'react'
import { Accordion, Button, Card, Table } from 'react-bootstrap'
import { Icon } from 'react-icons-kit'
import { cross } from 'react-icons-kit/icomoon/'
import API from '../Components/Global_API'
import NoInternet from '../Components/NoInternet'
import Router from '../Router';

const styles = {
    inputDiv: {
        paddingBottom: 10
    },
    buttons: {
        fontWeight: '600'
    },
    clearFilterIcon: {
        padding: 5,
        color: 'rgb(138, 141, 150)',
    },
    inputField: {
        height: 40
    }
}

export default class All_IQA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            start_date: '',
            end_date: '',
            data: [],
            region_data: [],
            search_region: '',
            iqa_data: [],
            all_iqa: [],
            region_filter_data: [],
            search_data: [],
            dataFound: false
        }

        let loginUser = localStorage.getItem("loginUser")
        if (loginUser == null) {
            window.location.href = '/';
        }
    }

    get_iqa() {
        let role = JSON.stringify({
            role: "iqa"
        })
        fetch(API + 'get_all_users.php', {
            method: 'POST',
            body: role
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            this.setState({ dataFound: true })
            if (responseJsonFromServer.result == "success") {
                this.setState({
                    iqa_data: responseJsonFromServer.users_data,
                    all_iqa: responseJsonFromServer.users_data,
                    region_filter_data: responseJsonFromServer.users_data,
                    search_data: responseJsonFromServer.users_data
                })
            }

        }).catch((error) => {
            if (error.message == 'Failed to fetch') {
                this.setState({ networkError: true })
            }
        });
    }

    componentDidMount() {
        this.get_iqa();

        fetch(API + 'get_all_data.php').then((response) => response.json()).then((responseJsonFromServer) => {

            if (responseJsonFromServer.result == "success") {
                this.setState({
                    region_data: responseJsonFromServer.regions_data,
                })
            } else {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                x.innerHTML = responseJsonFromServer.msg;
            }

        }).catch((error) => {
            if (error.message == 'Failed to fetch') {
                this.setState({ networkError: true })
            }
        });
    }

    handleIndividualIQA = (item) => {
        this.props.history.push('/edit_iqa', { iqa: item })
    }

    handleCreateIQA = () => {
        this.props.history.push('/add_iqa')
    }

    handleClearRegion = (e) => {
        this.setState({ search_region: '' })
    }

    handleSearchRegion = (e) => {
        this.setState({ search_region: e.target.value })
        // if (this.state.search_data.length == 0) {
        let region = [];
        if (e.target.value.length > 1) {
            let iqa = this.state.all_iqa
            let filter_iqa = iqa.filter((item) => {
                return item.regions == e.target.value;
            });
            region = filter_iqa
        } else {
            region = this.state.all_iqa
        }

        this.setState({ region_filter_data: region })

        Array.prototype.intersection = function (arr) {
            return this.filter(function (e) {
                return arr.indexOf(e) > -1;
            });
        };

        let filter_data = this.state.search_data.intersection(region)
        this.setState({ iqa_data: filter_data })
    }

    handleClearSearch = (e) => {
        this.setState({ search: '', search_data: this.state.all_iqa })


        Array.prototype.intersection = function (arr) {
            return this.filter(function (e) {
                return arr.indexOf(e) > -1;
            });
        };

        let filter_data = this.state.all_iqa.intersection(this.state.region_filter_data)
        this.setState({ iqa_data: filter_data })
    }

    handleSearch = (e) => {
        this.setState({
            search: e.target.value
        })

        // if (this.state.search_region.length == 0) {
        let iqa_search = []
        if (e.target.value.length == 0) {
            // this.setState({ iqa_data: this.state.all_iqa });
            iqa_search = this.state.all_iqa
        } else {
            let iqa = this.state.all_iqa
            let filterdIqa = iqa.filter((item) => {
                return (item.middle_name ? item.first_name.toLowerCase() + ' ' + item.middle_name.toLowerCase() + ' ' + item.last_name.toLowerCase() : item.first_name.toLowerCase() + ' ' + item.last_name.toLowerCase()).includes(e.target.value.toLowerCase()) || item.first_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.middle_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.last_name.toLowerCase().includes(e.target.value.toLowerCase()) || item.email.toLowerCase().includes(e.target.value.toLowerCase()) || item.contact.includes(e.target.value);
            });
            iqa_search = filterdIqa
        }
        this.setState({ search_data: iqa_search });

        Array.prototype.intersection = function (arr) {
            return this.filter(function (e) {
                return arr.indexOf(e) > -1;
            });
        };

        let filter_data = this.state.region_filter_data.intersection(iqa_search)
        this.setState({ iqa_data: filter_data })


    }
    render() {
        if (this.state.networkError) {
            return <NoInternet />
        } else {
            if (this.state.dataFound) {
                return (
                    <div>
                        <Router />
                        <div style={{ backgroundColor: '#EEF2F6', minHeight: '100vh', overflowY: 'auto', overflowX: 'hidden' }}>
                            <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingTop: 80, paddingBottom: 20, paddingRight: '2%', paddingLeft: '2%' }}>
                                <div className="row" style={{ marginTop: -10, marginBottom: -10 }}>
                                    <Accordion style={{ width: '100%' }} defaultActiveKey="0">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-4 pl-2 pt-2"></div>
                                            <div className="col-md-4 col-sm-6 text-center pt-2">
                                                <Accordion.Toggle as={Button} style={{ backgroundColor: '#ebeced' }} variant="default" eventKey="0">
                                                    Filter by <span style={{ fontWeight: '700' }}>IQA</span>
                                                </Accordion.Toggle>
                                            </div>
                                            <div className="col-md-4 col-sm-6 text-right pt-2" style={{ paddingRight: 35 }}>
                                                <Button variant="primary" onClick={this.handleCreateIQA} style={styles.buttons}>Create new IQA</Button>
                                            </div>
                                        </div>

                                        <div eventKey="0">
                                            <Card.Body >
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12 pr-lg-4 pr-md-4 pr-sm-3">
                                                                <div className="row">
                                                                    <div className="col-12 p-0 pb-3">
                                                                        <h6>Region</h6>
                                                                        <select className="form-control" value={this.state.search_region} onChange={this.handleSearchRegion} placeholder="search by region" style={styles.inputField}>
                                                                            <option value='' >Any</option>
                                                                            {this.state.region_data.map(item =>
                                                                                <option value={item.regions}>{item.regions}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-12 pl-lg-4 pl-md-4 pl-sm-3 pt-sm-3 pt-xs-3 pt-lg-0 pt-md-0 pl-xs-0">
                                                                <div className="row">
                                                                    <div className="col-11 p-0" style={styles.inputDiv}>
                                                                        <h6>Search</h6>
                                                                        <input type="text" placeholder="Search by name, email and contact number" value={this.state.search} onChange={this.handleSearch} className="form-control" style={styles.inputField} />
                                                                    </div>
                                                                    <div className="col-1 p-0 text-center" style={{ border: '1px solid rgb(206, 212, 218)', borderRadius: 3, cursor: 'pointer', height: 40, marginTop: 27 }} onClick={this.handleClearSearch}>
                                                                        <Icon icon={cross} size="20" style={styles.clearFilterIcon} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </div>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="body-container" style={{ paddingTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingRight: '2%', paddingLeft: '2%' }}>
                                <div className="col-12 row p-1">
                                    {this.state.iqa_data.length == 0 ?
                                        <div className="col-12 text-center">
                                            <h4>No user found</h4>
                                        </div> :
                                        <Table responsive >
                                            <thead>
                                                <tr>
                                                    <th>No.</th>
                                                    <th>IQA Name</th>
                                                    <th>Region</th>
                                                    <th>Email</th>
                                                    <th>Contact number</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.iqa_data.map(item =>
                                                    <tr>
                                                        <td>{item.id}</td>
                                                        <td>{item.first_name + ' ' + item.middle_name + ' ' + item.last_name}</td>
                                                        <td>{item.regions}</td>
                                                        <td>{item.email}</td>
                                                        <td style={{ whiteSpace: 'nowrap' }}>{item.contact}</td>
                                                        <td><Button variant="primary" style={styles.buttons} block onClick={() => this.handleIndividualIQA(item)} >View</Button></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div style={{ minHeight: '100vh', backgroundColor: '#EEF2F6' }}>
                        <Router />
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 80 }}>
                            <img src={require('../../assets/Loader.gif')} style={{ width: 100, height: 100 }} />
                        </div>
                    </div>

                )
            }
        }
    }
}